import React, { useEffect, useState } from "react";
import MainLayout from "../../layouts/mainLayout/mainLayout";
import Button from "../../components/button/button";
import Input from "../../components/input/input";
import styled from "styled-components";
import { Colors } from "../../utilities/colors";
import InternIcon from "../../assets/icons/intern.png";
import Salery from "../../assets/icons/salary.png";
import View from "../../assets/icons/view.png";
import Applied from "../../assets/Saly.png";
import Saved from "../../assets/Saved.png";
import Offered from "../../assets/Offered.png";
import JobConfirmationModal from "../jobApply/jobConfirmationModal";
import { useTranslation } from "react-i18next";
import HamburgerLay from "../../components/hamburger/hamburger";
import Menus from "../../components/hamburger/menus";
import {
  getJobs,
  getTalentUserData,
  applyJob,
  getjobdocument,
  getjobscountapi,
  getfavouritesjobs,
  savefavouritesjobs,
} from "../../services/apiCalls";
import { useDispatch, useSelector } from "react-redux";
import Loader from "../../components/loader/loader";
import { Pagination } from "@material-ui/lab";
import { useNavigate } from "react-router-dom";
import { setId } from "../../reducers/jobReducer";
import ErrorField from "../../components/errorField/errorField";
import { ReactComponent as FavouriteIcon } from "../../assets/icons/svg/startPink.svg";
import { ReactComponent as UnfavouriteIcon } from "../../assets/icons/svg/starGray.svg";
import { Row, Col } from "react-bootstrap";
import InternLogoDefault from "../../assets/icons/svg/favicon.svg";
import { toast } from "react-toastify";

const PageLayoutWrapper = styled.div`
  min-height: 80vh;
  padding: 0rem 3rem;
  @media (max-width: 767px) {
    padding: 0rem 0.8rem;
  }
`;

const Greetings = styled.div`
  font-family: "General Sans";
  font-style: normal;
  font-weight: 500;
  font-size: 28px;
  line-height: 38px;
  color: #000000;
`;

const Container = styled.div`
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  margin: 1.5rem 0px;
  @media (max-width: 480px) {
    flex-direction: column;
  }
`;
const HeaderText = styled.div`
  font-family: "General Sans";
  font-style: normal;
  font-weight: 500;
  font-size: 24px;
  line-height: 32px;
  color: #000000;
  @media (min-width: 320px) and (max-width: 480px) {
    font-family: "General Sans";
    font-style: normal;
    font-weight: 500;
    font-size: 24px;
    line-height: 32px;
    color: #000000;
    // margin: 0px 0 0px 20px;
    width: auto;
    position: absolute;
  }
`;

const NavigatorText = styled.div`
  font-family: "General Sans";
  font-style: normal;
  font-weight: 500;
  font-size: 18px;
  line-height: 24px;
  text-decoration-line: underline;
  color: #d04c8d;
  @media (max-width: 480px) {
    margin-top: 32px;
  }
`;

const InputBox = styled.div`
  margin-top: 20px;

  height: 54px;
  @media (max-width: 500px) {
    width: 100%;
  }
`;

const FlexDiv = styled.div`
  display: flex;
`;
const PageWrapper = styled.div`
  background: ${Colors.light};
  border-radius: 10px;
  padding: 2rem 2rem 0rem 2rem;
  margin-bottom: 1rem;
  height: max-content;

  @media (max-width: 599px) {
    padding: 1rem 1rem 0rem 1rem;
  }

  // @media (min-width: 320px) and (max-width: 480px) {
  //   padding: 0.5rem;
  //   margin: 4px 10px 10px 10px;
  //   height: auto;
  // }
  @media (min-width: 600px) and (max-width: 768px) {
    border-radius: 10px;
    padding: 2rem 2rem 0rem 2rem;

    //height: 24vh;
  }
  @media (min-width: 769px) and (max-width: 1024px) {
    border-radius: 10px;
    padding: 2rem 2rem 0rem 2rem;
    // margin: 1rem 50px;
    // height: 29vh;
  }
  // @media (min-width: 1025px) and (max-width: 1200px) {
  //   border-radius: 32px;
  //   padding: 2rem 2rem 0rem 2rem;
  //   // margin: 1rem 50px;
  //   height: 29vh;
  // }
`;

const JobTitle = styled.div`
  font-family: "General Sans";
  font-style: normal;
  font-weight: 500;
  font-size: 20px;
  line-height: 27px;
  color: #000000;
  display: flex;
`;
const HrLine = styled.hr`
  border: 0.6px solid #c4c4c4;
  position: relative;
  // bottom: 30px;
  @media (max-width: 599px) {
    position: relative;
    top: 30px;
  }
`;

const PostedDate = styled.div`
  font-family: "General Sans";
  font-style: italic;
  font-weight: 500;
  font-size: 18px;
  line-height: 24px;
  letter-spacing: 1px;
  color: #d04c8d;
`;
const SmallText = styled.p`
  font-weight: 500;
  font-size: 14px;
  line-height: 19px;
  margin: 0;
`;
const AppliedButton = styled.p`
  text-transform: capitalize;
  font-weight: 700;
  font-size: 20px;
  line-height: 19px;
  color: #d04c8d;
`;
const VacancyText = styled(SmallText)`
  font-family: "General Sans";
  font-style: normal;
  font-weight: 500;
  font-size: 18px;
  line-height: 24px;
  color: #525252;
  // margin-top:20px;

  > img {
    margin: 0 5px 0 30px;
    align-items: left;
  }
  @media (max-width: 599px) {
    > img {
      margin: 0 5px 0 0;
    }
  }
  @media (min-width: 599px) and (max-width: 768px) {
    > img {
      margin: 0 5px 0 0;
    }
  }
  @media (min-width: 768px) and (max-width: 1024px) {
    > img {
      margin: 0 5px 0 0;
    }
  }
  @media (min-width: 1025px) and (max-width: 1200px) {
    > img {
      margin: 0 5px 0 0;
    }
  }
  @media (min-width: 1201px) and (max-width: 1440px) {
    > img {
      margin: 0 5px 0 0;
    }
  }
`;

const ContentWrapper = styled.div`
  padding: 5px;
  @media (min-width: 320px) and (max-width: 480px) {
    padding: 10px;
  }
`;

const PaginationWrapper = styled.div`
  display: flex;
  justify-content: space-around;
`;

const DataWrapper = styled.div`
  display: flex;
  gap: 30px;
  padding: 5px;
  position: relative;
  // bottom: 35px;
  @media (max-width: 599px) {
    display: flex;
    gap: unset;
    padding: 5px;
    position: relative;
    top: 20px;
  }
`;
const ViewText = styled.div`
  font-family: "General Sans";
  font-style: normal;
  font-weight: 500;
  font-size: 14px;
  line-height: 19px;
  color: #b3b3b3;
  > img {
    margin-right: 5px;
  }
`;
const AppText = styled.p`
  font-family: "General Sans";
  font-style: italic;
  font-weight: 400;
  font-size: 14px;
  line-height: 19px;
  color: #d04c8d;
  margin-left: 5px;
  margin-bottom: unset;
  cursor: pointer;
`;
const P = styled.div`
  font-family: "General Sans";
  font-style: normal;
  font-weight: 500;
  font-size: 14px;
  line-height: 19px;
  text-align: right;
  color: #212121;
  position: relative;
  bottom: 30px;
  text-transform: capitalize;
  @media (max-width: 500px) {
    margin-top: 80px;
  }
`;
const FlexWrapper = styled(FlexDiv)`
  left: 22%;
  gap: 150px;
  top: 40px;

  @media (min-width: 481px) and (max-width: 768px) {
    position: relative;
    left: unset;
    margin-top: 10px;
    gap: unset;
    justify-content: space-between;
  }
  @media (min-width: 768px) and (max-width: 1024px) {
    position: relative;
    left: unset;
    margin-top: 10px;
    gap: unset;
    justify-content: space-between;
  }
  @media (min-width: 1025px) and (max-width: 1200px) {
    left: unset;
    gap: unset;
    justify-content: space-between;
  }
  @media (min-width: 1201px) and (max-width: 1440px) {
   left: unset;
    gap: unset;
    justify-content: space-between;
  }
  }

  @media (max-width: 599px) {
    position: relative;
    left: unset;
    gap: unset;
    justify-content: space-between;
  }
`;
const Content = styled.div`
  font-weight: 500;
  font-size: 24px;
  margin: 25px 0;
  justify-content: center;
  display: flex;
`;
const ActivateWrapper = styled.div`
  display: flex;
  justify-content: end;
  position: relative;
  bottom: 25px;
  left: auto;
  @media (min-width: 320px) and (max-width: 480px) {
    position: relative;
    top: 40px;
    left: auto;
    display: flex;
    justify-content: center;
    margin: 10px;
    button {
      width: 192px;
    }
  }
  @media (min-width: 481px) and (max-width: 768px) {
    left: auto;
    bottom: 7rem;
  }
  @media (min-width: 769px) and (max-width: 1024px) {
    margin-left: 70px;
    left: auto;
    bottom: 7rem;
  }
  @media (min-width: 1025px) and (max-width: 1200px) {
    left: auto;
    margin-left: 75px;
    bottom: 7rem;
  }
  @media (min-width: 1201px) and (max-width: 1440px) {
    bottom: 7rem;
  }
`;
const ButtonDiv = styled.div`
  @media (min-width: 320px) and (max-width: 480px) {
    position: absolute;
    left: 92px;
    top: 164px;
    button {
      width: 192px;
    }
  }
`;
const DashboardWrapper = styled.div`
  @media (max-width: 1024px) {
    display: flex;
  }
`;

const HamburgerLayout = styled.div`
  @media (min-width: 1025px) {
    display: none;
  }
`;
const Title = styled.div`
  cursor: pointer !important;
`;

const IconWrapper = styled.div`
  margin-left: 1rem;
  margin-left: 1rem;
  display: flex;
  position: relative;
  top: 3px;
  &.pointer {
    cursor: pointer;
  }
`;

const CategoriesContainer = styled.div`
  margin: 15px 0px;
  display: grid;
  grid-template-columns: repeat(3, 1fr);
  grid-gap: 20px;
  @media (max-width: 599px) {
    margin-top: 70px;
    grid-template-columns: unset !important;
  }
  @media (max-width: 1199px) {
    grid-template-columns: repeat(2, 1fr);
  }
`;

const Cards = styled.div`
  background-color: white;
  width: 100%;
  border-radius: 10px;
  padding: 25px;
  cursor: pointer;
  display: flex;
  justify-content: space-between;
  &:hover {
    box-shadow: 0px 5px 5px #a39c9c;
  }
`;

const CategoryCount = styled.div`
  font-size: 52px;
  color: #bab6b6;
  font-family: "General Sans";
  font-weight: 500;
`;

const JobsCard = styled.div`
  background-color: white;
  margin-bottom: 30px;
  border-radius: 10px;
  padding: 1.25rem 1.25rem 0rem;
  display: flex;
  @media (max-width: 700px) {
    display: block;
    padding: 6px;
  }
`;
const GridCard = styled.div`
  display: grid;
  grid-template-columns: 1fr 20fr;
  @media (max-width: 599px) {
    display: block;
  }
`;
const ImgContainer = styled.div`
  width: 100px;
  height: 100px;
  cursor: pointer;
  @media (max-width: 599px) {
    width: initial;
    margin: 10px auto;
  }
`;
const TextContainer = styled.div``;
const CardWrapper = styled.div`
  border-bottom: 0.6px solid #bcb8b8;
  margin: 0.5rem 1rem 0.25rem;
  // cursor: pointer;
`;
const FlexDivJobCard = styled.div`
  display: flex;
  justify-content: space-between;
  align-items: center;
`;
const StyledCardText = styled.div`
  color: #d04c8d;
  font-family: "General Sans";
  font-weight: 500;
  margin-top: 5px;
  font-size: 20px;
  @media (max-width: 700px) {
    font-size: 18px;
  }
`;
const StyledCountry = styled.div`
  color: rgba(82, 82, 82, 1);
  font-weight: 500;
  font-size: 18px;
  margin-top: 5px;
  @media (max-width: 1200px) {
    font-size: 16px;
  }
`;
const Employment_img = styled.img`
  width: 30px;
  height: 25px;
`;
const EmployeementType = styled.p`
  align-self: center;
  font-size: 18px;
  font-weight: 500;
  padding-left: 10px;
  padding-right: 15px;
  margin-bottom: 1rem;
  color: rgba(82, 82, 82, 1);
  @media (max-width: 1200px) {
    margin-bottom: unset;
  }
`;
const StyledImage = styled.img`
  width: 100%;
  height: 100%;
  border-radius: 50%;
  @media (max-width: 599px) {
    width: 100%;
    height: 100%;
    border-radius: 10px;
    object-fit: contain;
  }
`;
const BottomWrapper = styled.div`
  padding: 0px 1rem 0.5rem;
`;
const EmployeeDiv = styled.div`
  display: flex;
  justify-content: flex-start;
  padding: 1rem 0 0rem;
  @media (max-width: 1200px) {
    padding: 0.5rem 0 0rem;
  }
`;
const JobPage = () => {
  const navigate = useNavigate();
  const { t } = useTranslation();
  const [loading, setLoader] = useState(true);
  const [showConfirmation, setShowConfirmation] = useState(false);
  const [user, setUser] = useState("");
  const [companyCount, setCompanyCount] = useState(0);
  const [currentPage, setCurrentPage] = useState(1);
  const [data, setData] = useState([]);
  const [status, setStatus] = useState("all");
  const [dataCopy, setDataCopy] = useState([]);
  const [selectedJob, setSelectedJob] = useState({});
  const [jobDocs, setJobDocs] = useState(undefined);
  const [error, setError] = useState("");
  const [counts, setCounts] = useState({
    applied: 0,
    offered: 0,
    favourites: 0,
  });
  const [favlist, setFavlist] = useState([]);
  const showConfirm = (job) => {
    setSelectedJob(job);
    setShowConfirmation(true);
    getJobDocsDetails(job.id);
  };
  const [searchval, setSearchFilter] = useState("");
  const [isOpenham, setOpenHam] = useState(false);
  const onSearch = (e) => {
    setSearchFilter(e.target.value);
    //debounceChange(e.target.value, 1, status);
  };

  const percent = useSelector((state) => {
    return state.profile.profilePercentage;
  });
  const minCondition = 100; //minimum profile completion required to enable job/internship application
  const InternshipsMessage =
    "Please complete About Me, Personality & Motivation, Work Interests, and English Proficiency Assessments to use this feature";

  const getTalentUser = async () => {
    const { data } = await getTalentUserData();
    if (data && data.status === 1) {
      setUser(data.data);
    }
  };

  const getfavourites = async () => {
    const { data } = await getfavouritesjobs();
    if (data && data.status === 1) {
      setFavlist(data.data);
    }
  };

  const onPageChange = (e, v) => {
    setLoader(true);
    setCurrentPage(v);
    if (searchval && searchval.length > 0) {
      setCurrentPage(1);
      let p = `?search=${searchval}&page=${v}&page_size=5`;
      getJobsData(p);
    } else {
      let p = `?page=${v}`;
      getJobsData(p);
    }
  };

  const getJobDocsDetails = async (id) => {
    const { data } = await getjobdocument(id);
    if (data && data.status === 1) {
      setJobDocs(data.data);
      setError("");
    } else {
      setError("");
    }
  };

  const formValues = useSelector((state) => state.selfIntroOne.value);

  useEffect(() => {
    getTalentUser();
  }, []);
  const dispatch = useDispatch();
  const getJobsData = async (param) => {
    setLoader(true);
    await getjobscount();
    const { data } = await getJobs(param, status);
    if (data) {
      setData(data.results);
      setLoader(false);
      setCompanyCount(data.count);
    } else {
      setLoader(false);
    }
  };

  const getJobsDatastatus = async (param, status) => {
    await getjobscount();
    const { data } = await getJobs(param, status);
    if (data) {
      setData(data.results);
      setLoader(false);
      setCompanyCount(data.count);
    } else {
      setLoader(false);
    }
  };

  const getjobscount = async () => {
    const { data } = await getjobscountapi();
    if (data?.status === 1) {
      setCounts(data?.data);
    }
    await getfavourites();
    console.log(data, "++++");
  };

  const viewCompany = (id) => {
    dispatch(setId(id));
    navigate(`/jobs/view-jobs/${id}`);
  };

  const onAccept = async (id) => {
    const { data } = await applyJob(id);
    if (data.status === 1) {
      setShowConfirmation(false);
      setLoader(true);
      setError("");
      getJobsData("?page=1");
    } else {
      setError("");
    }
  };

  const filtervalues = () => {
    setLoader(true);
    setCompanyCount(0);

    if (searchval && searchval.length > 0) {
      setCurrentPage(1);
      let p = `?search=${searchval}&page=1&page_size=5`;
      getJobsData(p);
    } else {
      let p = `?page=1&page_size=5`;
      // let p = `?page=${v}`;
      getJobsData(p);
    }
  };
  const saveForLater = async (id) => {
    if (!loading) {
      let a = "";
      if (favlist.indexOf(id) == -1) {
        a = "add";
      } else {
        a = "remove";
      }
      setLoader(true);
      await savefavouritesjobs(a, id);
      await getjobscount();
      setLoader(false);
    }
  };

  const changestatus = async (val) => {
    setLoader(true);
    setSearchFilter("");
    setStatus(val);
    await getJobsDatastatus("?page=1", val);
  };
  useEffect(() => {
    getJobsData("?page=1");
  }, []);
  console.log(data, "testdata");

  // redirect to dashboard if has not completed assessment
  const completion = useSelector((state) => {
    return state.profile.profilePercentage;
  });

  // if (completion != null & completion < 100) {
  //   navigate('/dashboard');
  // }

  return (
    <div>
      <MainLayout>
        <DashboardWrapper>
          <HamburgerLayout>
            <HamburgerLay onToggle={() => setOpenHam(!isOpenham)} />
          </HamburgerLayout>
          {isOpenham && <Menus />}
        </DashboardWrapper>
        <PageLayoutWrapper>
          <Greetings>Good Morning, {user && user.display_name}</Greetings>
          <Row>
            <Col lg="10" xs="12">
              <InputBox>
                <Input
                  color="#fff"
                  //width="70%"
                  placeholder="Search Keywords for Job Title"
                  value={searchval}
                  onChange={onSearch}
                />
              </InputBox>
            </Col>
            {/* <Col lg="1" xs="2">
              <FilterIcon>
                <img src={Union} alt="imgUnion" style={{}} />
              </FilterIcon>
            </Col> */}
            <Col lg="2" xs="12">
              <ButtonDiv>
                <Button
                  style={{ cursor: "pointer" }}
                  name="Search"
                  onClick={() => filtervalues()}
                  mobWidth="100%"
                />
              </ButtonDiv>
            </Col>
          </Row>

          <CategoriesContainer>
            <Cards
              onClick={() => {
                changestatus("applied");
              }}
            >
              <div>
                <StyledCardText>{t("Applied")}</StyledCardText>
                <CategoryCount>{counts?.applied}</CategoryCount>
              </div>
              <div>
                <img src={Applied} alt="" />
              </div>
            </Cards>
            <Cards
              onClick={() => {
                changestatus("favourites");
              }}
            >
              <div>
                <StyledCardText>{t("Favourites")}</StyledCardText>
                <CategoryCount>{counts?.favourites}</CategoryCount>
              </div>
              <div>
                <img src={Saved} alt="" />
              </div>
            </Cards>
            <Cards
              onClick={() => {
                changestatus("offered");
              }}
            >
              <div>
                <StyledCardText>{t("Offered")}</StyledCardText>
                <CategoryCount>{counts.offered}</CategoryCount>
              </div>
              <div>
                <img src={Offered} alt="" />
              </div>
            </Cards>
          </CategoriesContainer>
          <Container>
            {status == "all" ? <HeaderText>Recommended Jobs</HeaderText> : ""}
            {status == "applied" ? <HeaderText>Applied Jobs</HeaderText> : ""}
            {status == "favourites" ? (
              <HeaderText>Favourites Jobs</HeaderText>
            ) : (
              ""
            )}
            {status == "offered" ? <HeaderText>Offered Jobs</HeaderText> : ""}

            <NavigatorText
              style={{ cursor: "pointer" }}
              onClick={() => {
                changestatus("all");
              }}
            >
              View all
            </NavigatorText>
          </Container>
          {/* <AddNewJobs /> */}
          {loading ? <Loader /> : null}
          {!loading && data?.length === 0 ? (
            <Content>No Data Found</Content>
          ) : null}
          {data?.length > 0
            ? data?.map((list, index) => (
                <JobsCard key={index}>
                  <GridCard>
                    <ImgContainer onClick={() => viewCompany(list.id)}>
                      <StyledImage
                        src={
                          list.organization.logo_path
                            ? list.organization.logo_path
                            : InternLogoDefault
                        }
                        alt="company-logo"
                      />
                    </ImgContainer>
                    <TextContainer>
                      <CardWrapper>
                        <FlexDivJobCard className="star-icon">
                          <JobTitle>
                            <Title onClick={() => viewCompany(list.id)}>
                              {list.title}
                            </Title>
                            <IconWrapper
                              onClick={() => saveForLater(list.id)}
                              className={"pointer"}
                            >
                              {favlist.indexOf(list.id) != -1 ? (
                                <FavouriteIcon />
                              ) : (
                                <UnfavouriteIcon />
                              )}
                            </IconWrapper>
                          </JobTitle>
                        </FlexDivJobCard>
                        <div>
                          <StyledCardText>
                            <FlexDivJobCard>
                              <div> {list.organization.name}</div>
                              <div>
                                {list.status == 1 && (
                                  <div style={{ textTranform: "uppercase" }}>
                                    APPLIED
                                  </div>
                                )}
                              </div>
                            </FlexDivJobCard>
                          </StyledCardText>
                          <StyledCountry>
                            <FlexDivJobCard>
                              <div>
                                {list.job_locations.length > 0
                                  ? list.job_locations[0].city_abbr
                                  : ""}
                                {", "}
                                {list.job_locations.length > 0
                                  ? list.job_locations[0].state_abbr
                                  : ""}
                              </div>
                            </FlexDivJobCard>
                          </StyledCountry>
                          {/* {(deviceStore.checkBrowser) ? 
                        <StyledCountry>
                          <FlexDivJobCard>
                            <div>
                              {list.job_locations.length > 0 ? list.job_locations[0].city_abbr : ""},
                              {list.job_locations.lenth > 0 ? list.job_locations[0].state_abbr : ""}
                            </div>
                          </FlexDivJobCard>
                        </StyledCountry>
                        :
                        <StyledCountry>
                          <div className="row">
                            <div className="col-12 p-0">
                              <div>
                              {list.job_locations.length > 0 ? list.job_locations[0].city_abbr : ""},
                              {list.job_locations.lenth > 0 ? list.job_locations[0].state_abbr : ""}
                              </div>
                            </div>
                          </div>
                        </StyledCountry>
                        } */}
                        </div>
                      </CardWrapper>
                      <BottomWrapper>
                        <Row>
                          <Col xl="4">
                            <EmployeeDiv>
                              <Employment_img src={InternIcon} />
                              <EmployeementType>
                                {list.employment_type}
                              </EmployeementType>
                            </EmployeeDiv>
                          </Col>
                          <Col xl="4">
                            <EmployeeDiv>
                              <Employment_img src={Salery} />
                              <EmployeementType>
                                {list?.salary_from + "MYR"} -{" "}
                                {list?.salary_to + "MYR"}
                              </EmployeementType>
                            </EmployeeDiv>
                          </Col>
                          <Col xl="4">
                            {+list?.status === 0 && (
                              <Button
                                style={{ margin: "0px 0px 13px 0px" }}
                                mobWidth="100px"
                                name="Apply"
                                cursor="Pointer"
                                onClick={() =>
                                  completion >= minCondition
                                    ? showConfirm(list)
                                    : toast.error(t(InternshipsMessage), {
                                        position: toast.POSITION.TOP_RIGHT,
                                      })
                                }
                              ></Button>
                            )}
                          </Col>
                        </Row>
                      </BottomWrapper>
                    </TextContainer>
                  </GridCard>
                </JobsCard>
              ))
            : null}
          <PaginationWrapper>
            <Pagination
              count={Math.ceil(companyCount / 5)}
              page={currentPage}
              onChange={(e, v) => onPageChange(e, v)}
              shape="circular"
            />
          </PaginationWrapper>
        </PageLayoutWrapper>
      </MainLayout>
      {error && <ErrorField err={error} />}
      <JobConfirmationModal
        job={selectedJob}
        show={showConfirmation}
        title={t("Review Your Application")}
        heading={t(
          `You’re about to apply as an ${selectedJob?.title} at ${selectedJob?.organization?.name} Company.`
        )}
        para={t(
          "Your CV will be shared to the company for the hiring process. Please make sure you have updated it before submitting your application."
        )}
        head={t("Upload the below documents together with your application")}
        closeModal={() => setShowConfirmation(false)}
        onAccept={onAccept}
        jobDocs={jobDocs}
      />
    </div>
  );
};
export default JobPage;

import { ReactComponent as Home } from "./assets/icons/svg/home.svg";
import { ReactComponent as Internships } from "./assets/icons/svg/Internships.svg";
import { ReactComponent as Settings } from "./assets/icons/svg/Settings.svg";
import { ReactComponent as CV } from "./assets/icons/svg/MyCV.svg";
import {ReactComponent as Jobs} from "./assets/icons/svg/Job.svg";
import {ReactComponent as MyCompany} from "./assets/icons/svg/MyCompany.svg";
import {ReactComponent as MyCalender} from "./assets/icons/svg/MyCalender.svg";

export const menus = [
  {
    name: "Dashboard",
    path: "/dashboard",
    icon: <Home />,
    type: "talent",
    condition: 0
  },
  {
    name: "Internships",
    path: "/internships",
    icon: <Internships />,
    type: "talent",
    condition: 0
  },
  {
    name: "My CV",
    path: "/cv",
    icon: <CV />,
    type: "talent",
    condition: 0
  },
  {
    name: "MyCompany",
    path: "/my-company",
    icon: <MyCompany />,
    type: "talent",
    condition: 0
  },
  {
    name: "Jobs",
    path: "/jobs",
    icon: <Jobs />,
    type: "talent",
    condition: 0
  },
  {
    name: "My Calendar",
    path: "/my-calendar",
    icon: <MyCalender />,
    type: "talent",
    condition: 0
  },
  {
    name: "Settings",
    path: "/settings",
    icon: <Settings />,
    type: "talent",
    condition: 0
  },
  
];


import { useState, useEffect } from "react";

import styled from "styled-components";
import { useForm, Controller } from "react-hook-form";
import { yupResolver } from "@hookform/resolvers/yup";
import { useNavigate } from "react-router-dom";
import "react-phone-number-input/style.css";
import PhoneInput from "react-phone-number-input";
// import DatePicker from "react-datepicker";
import { ReactComponent as CalenderIcon } from "../../assets/icons/svg/calendarIcon.svg";

import { useSelector, useDispatch } from "react-redux";

import PaginationDivider from "../../components/paginationDivider/paginationDivider";
import UserCreationLayout from "../../layouts/userCreation/userCreationLayout";
import IntroSection from "../../components/introSection/introSection";
import MaskGroup from "../../assets/MaskGroup.png";
import RadioInput from "../../components/radioinput/radioinput";
import { Colors } from "../../utilities/colors";
import Button from "../../components/button/button";
import Input from "../../components/input/input";
import Dropdown from "../../components/dropdown/dropdown";
import {
  selfIntoStaticContent,
  errorMessages,
  schema,
  schemaIos,
} from "./selfIntroFormConstant";
import { mynextTalent } from "../mynextConstant";
import ErrorField from "../../components/errorField/errorField";
import {
  // getCountries,
  getAllCountries,
  getStates,
  getCities,
  getEthinicities,
  // onBoardStepOneForm,
  // onBoardGetDataStudying,
  // onAboutCountryandStateLIst,
  // getCurrentCountries,
  validateObf,
} from "../../services/apiCalls";
import Loader from "../../components/loader/loader";
import { updateValues } from "./reducer";
import { formatDate } from "../../utilities/commonFunctions";
import { Col, Row } from "react-bootstrap";
import DateFnsUtils from "@date-io/date-fns";
import { MuiPickersUtilsProvider, DatePicker } from "@material-ui/pickers";
import { createTheme, IconButton } from "@material-ui/core";
import { ThemeProvider } from "@material-ui/styles";
import moment from "moment";
import CancelIcon from "../../assets/icons/skillCancel.png";
import { useTranslation } from "react-i18next";
import UAParser from 'ua-parser-js';
import { deviceStore } from "../../utilities/devices";


const materialTheme = createTheme({
  palette: {
    primary: {
      main: "#D44B9C",
    },
  },
  overrides: {
    MuiPickersCalendarHeader: {
      switchHeader: {
        color: "#D44B9C",
      },
    },
  },
});

const FlexWrapper = styled.div`
  display: flex;
  align-items: center;
`;

const DatesWrapper = styled(FlexWrapper)`
  label {
    position: relative;
    width: -webkit-fill-available;
  }
`;
const CalenderIconWrapper = styled.div`
  position: absolute;
  right: 1rem;
  top: 0.5rem;
  cursor: pointer;
`;
const FormWrapper = styled.div`
  margin: 30px 0 0 0;
  max-width: 100%;
  @media (max-width: 1024px) {
    max-width: 100%;
  }
`;
const LabelText = styled.label`
  font-style: normal;
  font-weight: 500;
  font-size: 18px;
  line-height: 24px;
  color: ${Colors.labelText};
  margin-bottom: 10px;
  display: block;
`;
const FormGroup = styled.div`
  margin-bottom: 25px;
`;
const IdentificationWrapper = styled.div`
  display: -webkit-box;
  align-items: center;
  @media (max-width: 599px) {
    // justify-content: space-between;
    margin-bottom: 20px;
  }
`;
const NationalityWrapper = styled.div`
  margin-bottom: 10px;
`;
const DividerLine = styled.hr`
  border: 0.5px solid #ebebeb;
  margin: 30px 0;
`;

const RadioOptionWrapper = styled.div`
  display: flex;
  justify-content: space-between;
  align-items: center;

  @media (max-width: 599px) {
    justify-content: space-between;
  }
  @media (max-width: 990px) {
    justify-content: space-between;
  }
`;

export default function AboutmeIntro() {
  const formValues = useSelector((state) => state.selfIntroOne.value);
  const [options, setOptions] = useState({});
  const [disabledSatus, setDisableStatus] = useState(true);

  const [mblNumber, setMblNumber] = useState("");
  const [countries, setCountry] = useState([]);
  const [currCountries, setCurrCountry] = useState([]);
  const [countryListExcludeDefault, setNMcountry] = useState([]);
  const [states, setStates] = useState([]);
  const [cities, setCities] = useState([]);
  const [races, setRaces] = useState([]);
  const [scrollData, setScrollDatas] = useState([]);
  const [loading, setLoading] = useState(false);
  const [formErrors, setFormErrors] = useState("");
  const [selectCity, setSelectCity] = useState({});
  const [selectState, setSelectState] = useState({});
  const [aboutFormErrors, setAboutFormErrors] = useState(false);

  const [selectedCountry, setSelectedCountry] = useState({});
  const [selectedState, setSelectedState] = useState({});
  const [selectedCity, setSelectedCity] = useState({});
  const [selectedRace, setSelectedRace] = useState({});
  const [selectedNationality, setSelectedNationality] = useState({});
  const [statesDisable, setStatesDisable] = useState(false);
  const [citiesDisable, setCitiesDisable] = useState(false);

  const {
    control,
    handleSubmit,
    setValue,
    watch,
    resetField,
    setError,
    clearErrors,
    formState: { errors, values },
  } = useForm({
    defaultValues: { ...formValues },
    resolver: deviceStore.platform ==='ios'?yupResolver(schemaIos): yupResolver(schema),
    context: { statesDisable, citiesDisable },
  });

  const formVal = watch();
  // if (formVal.curr_country == "") {
  //   formVal.curr_country = 132;
  // }
  const currentDate = formatDate(new Date());
  const dispatch = useDispatch();
  const navigate = useNavigate();

  useEffect(() => {
    fetchCountry();
    fetchEthinicities();
  }, []);

  const fetchCountry = async () => {
    //const { data, status } = await getCountries();
    const { data, status } = await getAllCountries();
    if (status === 200) {
      const location = data.data;
      const country = location.map((x) => {
        return { ...x, label: x.Name, value: x.Id };
      });
      setCountry(country);

      const countryListExcludeDefault = country.filter(
        (x) => x.value !== mynextTalent.defaultCountryId
      );
      setNMcountry(countryListExcludeDefault);

      // Edit mode
      if (formVal.curr_country) {
        let selCountry = country.filter((x) => x.value == formVal.curr_country);
        // let selCountry = country.filter(x => x.value == mynextTalent.defaultCountryId);
        if (selCountry.length > 0) {
          selCountry = selCountry[0];
          setValue("curr_country", selCountry.value);
        }
        setSelectedCountry(selCountry);
        fetchStates(selCountry.value);
      }
      // Nationality
      if (formVal.country) {
        let selNationality = country.filter((x) => x.value == formVal.country);
        if (selNationality.length > 0) {
          selNationality = selNationality[0];
          setValue("country", selNationality.value);
        }
        setSelectedNationality(selNationality);
      }
    }
  };

  const fetchStates = async (countryId) => {
    const { data, status } = await getStates(countryId);
    if (status === 200) {
      const location = data.data;
      const states = location.map((x) => {
        return { ...x, label: x.Name, value: x.Id };
      });
      if (states.length == 0) {
        setStatesDisable(true);
        setCitiesDisable(true);
        setValue("city", "");
        setValue("city_abbr", "");
        setValue("state", "");
        setValue("state_abbr", "");
      } else {
        setStatesDisable(false);
        setCitiesDisable(false);
      }
      setStates(states);
      if (formVal.state) {
        let selState = states.filter((x) => x.value == formVal.state);
        // let selState = states.filter(x => x.value == mynextTalent.defaultStateId);
        if (selState.length > 0) {
          selState = selState[0];
          setValue("state", selState.value);
          setSelectedState(selState);
          fetchCities(countryId, selState.value);
        }
      }
    }
  };

  const fetchCities = async (countryId, stateId) => {
    const { data, status } = await getCities(countryId, stateId);
    if (status === 200) {
      const location = data.data;
      const cities = location.map((x) => {
        return { ...x, label: x.Name, value: x.Id };
      });
      if (cities.length == 0) {
        setCitiesDisable(true);
        setValue("city", "");
        setValue("city_abbr", "");
      } else {
        setCitiesDisable(false);
      }
      setCities(cities);
      if (formVal.city) {
        let selCity = cities.filter((x) => x.value == formVal.city);
        // let selCity = cities.filter(x => x.value == mynextTalent.defaultCityId);
        if (selCity.length > 0) {
          selCity = selCity[0];
          setValue("city", selCity.value);
        }
        setSelectedCity(selCity);
      }
    }
  };

  const fetchEthinicities = async () => {
    const { data, status } = await getEthinicities();
    if (status === 200) {
      const race = data.data;
      const newRace = race.map((x) => {
        return { ...x, label: x.Name, value: x.Id };
      });
      setRaces(newRace);
      if (formVal.race) {
        let selRace = newRace.filter((x) => x.value == formVal.race);
        // let selRace = newRace.filter(x => x.value == mynextTalent.defaultRaceId);
        if (selRace.length > 0) {
          selRace = selRace[0];
          setValue("race", selRace.value);
        }
        setSelectedRace(selRace);
      }
    }
  };

  const dropdownChange = (name, val) => {
    setValue(name, val.value);
    switch (name) {
      case "country":
        setSelectedNationality(val);
        setValue("country_abbr", val.label);
        setSelectedRace("");
        setValue("race_abbr", "");
        break;
      case "curr_country":
        setValue("city", "");
        setValue("state", "");
        setValue("postalcode", "");
        setValue("state_abbr", "");
        setValue("city_abbr", "");
        setValue("curr_country_abbr", val.label);
        setDisableStatus(false);
        setSelectedCountry(val);
        setSelectedState(null);
        setSelectedCity(null);
        setCities(null);
        dispatch(
          updateValues({
            ...formValues,
            ...formVal,
            state: "",
            city: "",
          })
        );
        formVal.state = "";
        formVal.city = "";
        fetchStates(val.value);
        break;
      case "state":
        setValue("city", "");
        setValue("postalcode", "");
        setValue("city_abbr", "");
        setValue("state_abbr", val.label);
        setSelectedState(val);
        setSelectedCity(null);
        dispatch(
          updateValues({
            ...formValues,
            ...formVal,
            city: "",
          })
        );
        formVal.city = "";
        fetchCities(formVal.curr_country, val.value);
        break;
      case "city":
        setSelectedCity(val);
        setValue("city_abbr", val.label);
        setValue("postalcode", "");
        formVal.postalcode = "";
        dispatch(
          updateValues({
            ...formValues,
            ...formVal,
            postalcode: "",
          })
        );
        break;
      case "race":
        setSelectedRace(val);
        setValue("race_abbr", val.label);
        setSelectedNationality("");
        setValue("country", "");
        setValue("country_abbr", "");
        dispatch(
          updateValues({
            ...formValues,
            ...formVal,
            race: "",
          })
        );
        break;
      default:
    }
  };

  const onErrorSubmit = () => {
    setAboutFormErrors(true);
  };

  const onSubmit = async (formData) => {

    if (Object.keys(errors).length === 0) {

      if(deviceStore.platform==='ios'){
        // const updatedFormValues = {
        //   ...formValues,
        //   is_ios_registration: 1
        // };

        // // Update the value of "is_ios_registration" in formVal
        // const updatedFormVal = {
        //   ...formVal,
        //   is_ios_registration: 1
        // };
        // dispatch(updateValues({ ...updatedFormValues, ...updatedFormVal }));
        formData.is_ios_registeration = 1;
      }
      dispatch(updateValues({ ...formValues, ...formVal,...formData  }));
      setAboutFormErrors(false);
      window.allow_user_continue = true;
      navigate("/aboutme");
    }
  };

  const onMblNumberChange = (val) => {
    if (val) {
      setMblNumber(val);
      setValue("mob_number", val);
    }
  };

  const radioChange = (value, name) => {
    setValue(name, value);
    switch (name) {
      case "disability_status":
        if (value === 0) {
          setValue("disability_code", "");
        }
        break;
      case "id_type":
        resetField("id_number");
        setValue("nationality", 1);
        setValue("country", "");
        setValue("race", "");
        setValue("country_abbr", "");
        setValue("race_abbr", "");
        setSelectedRace("");
        setSelectedNationality("");
        break;
      case "nationality":
        setValue("race", "");
        setValue("race_abbr", "");
        setSelectedRace("");
        setValue("country", "");
        setValue("country_abbr", "");
        setSelectedNationality("");
        break;
      default:
    }
  };

  const handleRegex = (value, name) => {
    let res = /^[a-zA-Z]+$/.test(value);
    if (!value) {
      if (name === "full_name") {
        setValue("full_name", "");
      } else {
        setValue("display_name", "");
      }
    } else {
      if (res) {
        setValue(name, value);
      }
    }
  };

  const inputChange = (e) => {
    const { name } = e.target;
    let value = e.target.value;

    if (value) {
      switch (name) {
        case "id_number":
          if (formVal.id_type === 1) {
            var oldValue = ("" + value).replace(/\D/g, "");

            var isNum = true;
            if (oldValue.length > 0) {
              let lastDigit = oldValue.charAt(oldValue.length - 1);
              isNum = !isNaN(lastDigit);
            }

            let newValue = `${oldValue.substring(0, 6)}${
              oldValue.length > 6 ? "-" : ""
            }${oldValue.substring(6, 8)}${
              oldValue.length > 8 ? "-" : ""
            }${oldValue.substring(8, 12)}`;

            if (/^[a-zA-Z\d-]+$/.test(oldValue) && isNum) {
              setValue("id_number", newValue);
            }
          } else {
            formVal.id_number = formVal?.id_number?.toUpperCase();
            setValue(
              "id_number",
              /^[a-zA-Z\d]+$/.test(value?.toUpperCase())
                ? value?.toUpperCase()
                : formVal.id_number
            );
          }
          break;
        case "disability_code":
          setValue(
            name,
            /^[0-9]+$/.test(value) ? value : formVal.disability_code
          );
          break;
        default:
          break;
      }
    } else {
      setValue(name, value);
    }
  };

  const onInputChange = (name, e) => {
    const { value } = e.target;
    const re = /^[a-zA-Z\s]*$/;
    if (value === "" || re.test(value)) {
      setValue(name, value);
    }
  };

  const clearDate = (e, name) => {
    e.preventDefault();
    setValue(name, "");
  };

  const dateChange = (name, val) => {
    setValue(name, formatDate(val));
  };
  const onValidate = async () => {
    if (formVal.id_number) {
      let code = formVal.id_type === 1 ? "1" : "0";
      let req = {
        data: formVal.id_number,
      };
      const { data, status } = await validateObf(req, code);
      if (data && data.status === 1) {
        clearErrors("id_number");
      } else {
        setError("id_number", { message: data && data.message });
      }
    }
  };
  const { t } = useTranslation();

  return (
    <UserCreationLayout sidebarImg={MaskGroup}>
      <IntroSection
        name={t(selfIntoStaticContent.title)}
        description={t(selfIntoStaticContent.description)}
      />
      <PaginationDivider step={1} />
      <FormWrapper>
        <Row>
          <FormGroup>
            <LabelText>{t(deviceStore.platform==='ios'? selfIntoStaticContent.identificationIos:selfIntoStaticContent.identification)}</LabelText>
            <Row>
              <Col lg="12" md="12" style={{ padding: "0px" }}>
                <IdentificationWrapper>
                  <Controller
                    name="id_type"
                    control={control}
                    render={({ field }) =>
                      selfIntoStaticContent.identifications.map(
                        (identity, index) => (
                          <RadioInput
                            key={identity.label + index}
                            {...field}
                            checked={identity.value === formVal.id_type}
                            onChange={() =>
                              radioChange(identity.value, "id_type")
                            }
                            label={t(identity.label)}
                            margin="20px"
                            name="id_type"
                          />
                        )
                      )
                    }
                  />
                </IdentificationWrapper>
              </Col>
            </Row>
            <Row>
              <Col xl="4" lg="6" md="6" style={{ padding: "0px" }}>
                <Controller
                  name="id_number"
                  control={control}
                  render={({ field }) => (
                    <Input
                      placeholder={
                        formVal.id_type === 1
                          ? t(selfIntoStaticContent.identificationNum)
                          : t("Passport Number")
                      }
                      {...field}
                      maxLength={formVal.id_type === 1 ? 14 : 16}
                      onChange={inputChange}
                      onBlur={onValidate}
                    />
                  )}
                />
                {errors.id_type && <ErrorField err={t(errorMessages.id_type)} />}
                {errors.id_number && (
                  <ErrorField err={t(errors.id_number.message)} />
                )}
              </Col>
            </Row>
          </FormGroup>
        </Row>
        <Row>
          <Col>
            <FormGroup style={{ paddingLeft: "0px" }}>
              <LabelText>{t(selfIntoStaticContent.fullName)}</LabelText>
              <Controller
                name="full_name"
                control={control}
                render={({ field }) => (
                  <Input
                    type="text"
                    onChange={(e) => onInputChange("full_name", e)}
                    placeholder={t("Full Name")}
                    {...field}
                    value={formVal.full_name}
                    checked={1 === formVal.nationality}
                    label={selfIntoStaticContent.malaysian}
                    maxLength="100"
                  />
                )}
              />
              {errors.full_name && (
                <ErrorField err={t(errors.full_name.message)} />
              )}
            </FormGroup>
          </Col>
        </Row>
        <Row>
          <Col>
            <FormGroup style={{ paddingLeft: "0px" }}>
              <LabelText>{t(selfIntoStaticContent.displayName)}</LabelText>
              <Controller
                name="display_name"
                control={control}
                render={({ field }) => (
                  <Input
                    placeholder={t("Your display name")}
                    {...field}
                    maxLength="100"
                  />
                )}
              />
              {errors.display_name && (
                <ErrorField err={t(errors.display_name.message)} />
              )}
            </FormGroup>
          </Col>
        </Row>
        <Row>
          <Col>
            <FormGroup style={{ paddingLeft: "0px" }}>
              <LabelText>{t(selfIntoStaticContent.genderTitle)}</LabelText>
              <IdentificationWrapper>
                <Controller
                  name="gender"
                  control={control}
                  render={({ field }) =>
                    selfIntoStaticContent.genders.map((gender, index) => (
                      <RadioInput
                        checked={gender === formVal.gender}
                        onChange={() => radioChange(gender, "gender")}
                        key={gender + index}
                        label={t(gender)}
                        name="gender"
                      />
                    ))
                  }
                />
              </IdentificationWrapper>
              {errors.gender && <ErrorField err={t(errors.gender.message)} />}
            </FormGroup>
          </Col>
        </Row>
        <Row>
          <Col xl="4" lg="6" md="6">
            <FormGroup style={{ paddingLeft: "0px" }}>
              <LabelText>{t(selfIntoStaticContent.dob)}</LabelText>
              <DatesWrapper>
                <Controller
                  name="dob"
                  control={control}
                  render={({ field }) => (
                    <>
                      <label>
                        <MuiPickersUtilsProvider utils={DateFnsUtils}>
                          <ThemeProvider theme={materialTheme}>
                            <DatePicker
                              // {...field}
                              autoOk
                              disableFuture
                              variant="inline"
                              format="dd/MM/yyyy"
                              placeholder={t("dd/mm/yyyy")}
                              views={["year", "month", "date"]}
                              value={formVal.dob ? new Date(formVal.dob) : null}
                              onChange={(val) => dateChange("dob", val)}
                              fullWidth
                              maxDate={new Date(moment().subtract(16, "years"))}
                            />
                          </ThemeProvider>
                        </MuiPickersUtilsProvider>
                        <CalenderIconWrapper>
                          <CalenderIcon />
                          {formVal.dob && (
                            <IconButton onClick={(e) => clearDate(e, "dob")}>
                              <img src={CancelIcon} alt="" />
                            </IconButton>
                          )}
                        </CalenderIconWrapper>
                      </label>
                    </>
                  )}
                />
              </DatesWrapper>
              {errors.dob && <ErrorField err={t(errors.dob.message)} />}
            </FormGroup>
          </Col>
        </Row>
        <Row>
          <Col xl="4" lg="6" md="6">
            <FormGroup>
              <LabelText>{t(selfIntoStaticContent.mblTitle)}</LabelText>
              <Controller
                name="mob_number"
                control={control}
                render={({ field }) => (
                  <PhoneInput
                    international
                    withCountryCallingCode={true}
                    countryCallingCodeEditable={false}
                    defaultCountry="MY"
                    value={formVal.mob_number}
                    onChange={onMblNumberChange}
                    maxLength={16}
                  />
                )}
              />
              {(errors.mob_number && formVal.mob_number === "") ||
                (errors.mob_number && (
                  <ErrorField err={t(errors.mob_number.message)} />
                ))}
            </FormGroup>
          </Col>
        </Row>

        <FormGroup>
          <Row>
            <LabelText>{t(deviceStore.platform==='ios'?selfIntoStaticContent.nationalityIos:selfIntoStaticContent.nationality)}</LabelText>
            <Col lg="12" sm="12" md="6" xl="4">
              <NationalityWrapper>
                <Controller
                  name="nationality"
                  control={control}
                  render={({ field }) => (
                    <RadioInput
                      {...field}
                      checked={1 === formVal.nationality}
                      onChange={() => radioChange(1, "nationality")}
                      label={t(selfIntoStaticContent.malaysian)}
                      margin="20px"
                      name="nationality"
                    />
                  )}
                />
                <Controller
                  name="race"
                  control={control}
                  render={({ field }) => (
                    <Dropdown
                      search={true}
                      options={races}
                      placeholder={t("Select the Race")}
                      //val={formVal.race}
                      val={formVal.nationality == 0 ? "" : selectedRace?.value}
                      label={selectedRace?.label}
                      disable={formVal.nationality == 1 ? false : true}
                      change={(val) => dropdownChange("race", val)}
                      {...field}
                      refCallback={field?.ref}
                      ref={null}
                    />
                  )}
                />
                {deviceStore.platform==='ios'? errors.race && <ErrorField err={""} /> : errors.race && <ErrorField err={t(errors.race.message)} />}
                {/* {errors.race && <ErrorField err={t(errors.race.message)} />} */}
              </NationalityWrapper>
            </Col>
            <Col lg="12" sm="12" md="6" xl="4">
              <NationalityWrapper>
                <Controller
                  name="nationality"
                  control={control}
                  render={({ field }) => (
                    <RadioInput
                      checked={0 == formVal.nationality}
                      onChange={
                        formVal.id_type == 1
                          ? ""
                          : () => radioChange(0, "nationality")
                      }
                      label={t(selfIntoStaticContent.nonMalaysian)}
                      disable={
                        formVal.id_type == 1
                          ? true
                          : formVal.nationality == 1
                            ? true
                            : false
                      }
                      margin="20px"
                      name="nationality"
                      data_disabled={
                        formVal.id_type == 1
                          ? true
                          : formVal.nationality == 1
                            ? true
                            : false
                      }
                    />
                  )}
                />
                <Controller
                  name="country"
                  control={control}
                  render={({ field }) => (
                    <Dropdown
                      search={true}
                      options={countryListExcludeDefault}
                      placeholder={t("Select the Country")}
                      disable={
                        formVal.id_type == 1
                          ? true
                          : formVal.nationality == 1
                            ? true
                            : false
                      }
                      val={
                        formVal.id_type == 1 || formVal.nationality == 1
                          ? ""
                          : selectedNationality?.value
                      }
                      label={selectedNationality?.label}
                      change={(val) => dropdownChange("country", val)}
                      {...field}
                      refCallback={field?.ref}
                      ref={null}
                    />
                  )}
                />
                {errors.country && <ErrorField err={t(errors.country.message)} />}
              </NationalityWrapper>
            </Col>
            {errors.nationality && (
              <ErrorField err={t(errorMessages.nationality)} />
            )}
          </Row>
        </FormGroup>

        <DividerLine />
        <Row>
          <Col lg="6">
            <FormGroup>
              <LabelText>{t(selfIntoStaticContent.currentlyLive)}</LabelText>
              <Controller
                name="curr_country"
                control={control}
                render={({ field }) => (
                  <Dropdown
                    search={true}
                    options={countries}
                    change={(val) => dropdownChange("curr_country", val)}
                    val={selectedCountry?.value}
                    label={selectedCountry?.label}
                    // onFocus={() => {getOptions('SL')}}
                    placeholder={t("Enter your Country")}
                    {...field}
                    refCallback={field?.ref}
                    ref={null}
                  />
                )}
              />

              {errors.curr_country && (
                <ErrorField err={t(errors.curr_country.message)} />
              )}
            </FormGroup>
          </Col>
        </Row>

        <Row>
          <Col lg="12" sm="12" md="12" xl="4">
            <FormGroup>
              <LabelText>
                {errors.state ? selfIntoStaticContent.state : t("State*")}
              </LabelText>
              <Controller
                name="state"
                control={control}
                render={({ field }) => (
                  <Dropdown
                    search={true}
                    // options={selectState && selectState.curr_country}
                    options={states}
                    placeholder={t("Enter your State")}
                    change={(val) => dropdownChange("state", val)}
                    //val={formVal.state}
                    val={selectedState?.value}
                    label={selectedState?.label}
                    disable={statesDisable}
                    // onFocus={() => {getOptions('SL')}}
                    {...field}
                    refCallback={field?.ref}
                    ref={null}
                  />
                )}
              />
              {errors.state && <ErrorField err={t(errors.state.message)} />}
            </FormGroup>
          </Col>

          <Col lg="12" sm="12" md="6" xl="4">
            <FormGroup>
              <LabelText>
                {errors.city ? selfIntoStaticContent.city : t("City*")}
              </LabelText>
              <Controller
                name="city"
                control={control}
                render={({ field }) => (
                  <Dropdown
                    search={true}
                    placeholder={t("Enter your City")}
                    change={(val) => dropdownChange("city", val)}
                    //val={formVal.city}
                    val={selectedCity?.value}
                    label={selectedCity?.label}
                    disable={citiesDisable}
                    // onFocus={() => {getOptions('SL')}}
                    // options={selectCity && selectCity.state}
                    options={cities}
                    {...field}
                    refCallback={field?.ref}
                    ref={null}
                  />
                )}
              />
              {errors.city && <ErrorField err={t(errors.city.message)} />}
            </FormGroup>
          </Col>

          <Col lg="12" md="6" sm="12" xl="4">
            <FormGroup>
              <LabelText>{t(selfIntoStaticContent.postcode)}</LabelText>
              <Controller
                name="postalcode"
                control={control}
                render={({ field }) => (
                  <Input
                    placeholder={t("Postcode")}
                    type="text"
                    onKeyPress={(event) => {
                      if (!/[a-zA-Z0-9]/.test(event.key)) {
                        event.preventDefault();
                      }
                      if (event.target.value.length > 9) {
                        event.preventDefault();
                      }
                    }}
                    // value={postalcode}
                    // onKeyPress={validatePostCode}
                    disabled={formVal.curr_country ? false : true}
                    {...field}
                  />
                )}
              />
              {errors.postalcode && (
                <ErrorField err={t(errors.postalcode.message)} />
              )}
            </FormGroup>
          </Col>
        </Row>

        <DividerLine />
        <Row>
          <FormGroup>
            <LabelText>{t(deviceStore.platform==='ios'?selfIntoStaticContent.pkuIos:selfIntoStaticContent.pku)}</LabelText>
            <Row>
              <Col lg="6" md="6">
                <RadioOptionWrapper>
                  <Controller
                    name="disability_status"
                    control={control}
                    rules={{ required: true }}
                    render={({ field }) =>
                      selfIntoStaticContent.isDisability.map(
                        (option, index) => (
                          <RadioInput
                            checked={option.value == formVal.disability_status}
                            onChange={() =>
                              radioChange(option.value, "disability_status")
                            }
                            key={option.label + index}
                            label={t(option.label)}
                            margin="10px"
                            name="disability_status"
                          />
                        )
                      )
                    }
                  />
                </RadioOptionWrapper>
              </Col>

              <Col lg="6" md="6">
                <Controller
                  name="disability_code"
                  control={control}
                  rules={{ required: true }}
                  render={({ field }) => (
                    <Input
                      placeholder={t("If Yes, OKU Registration Number")}
                      disabled={formVal.disability_status == 0}
                      maxLength={12}
                      {...field}
                      onChange={inputChange}
                    />
                  )}
                />
              </Col>

              {errors.disability_code && (
                <ErrorField err={t(errors.disability_code.message)} />
              )}
            </Row>
          </FormGroup>
        </Row>

        <Row>
          <Col lg="4">
            <FormGroup>
              {formErrors && <ErrorField err={t(formErrors)} />}
              {loading ? (
                <Loader />
              ) : (
                <Button
                  onClick={handleSubmit(onSubmit, onErrorSubmit)}
                  name={t("Continue")}
                  width="198px"
                />
              )}
            </FormGroup>
            {aboutFormErrors && (
              <ErrorField
                err={
                  t("Please make sure all the mandatory fields have been inserted")
                }
              />
            )}
          </Col>
        </Row>
      </FormWrapper>
    </UserCreationLayout>
  );
}

// import { useState, useEffect } from "react";

// import styled from "styled-components";
// import { useForm, Controller } from "react-hook-form";
// import { yupResolver } from "@hookform/resolvers/yup";
// import { useNavigate } from "react-router-dom";
// import "react-phone-number-input/style.css";
// import PhoneInput from "react-phone-number-input";
// // import DatePicker from "react-datepicker";
// import { ReactComponent as CalenderIcon } from "../../assets/icons/svg/calendarIcon.svg";

// import { useSelector, useDispatch } from "react-redux";

// import PaginationDivider from "../../components/paginationDivider/paginationDivider";
// import UserCreationLayout from "../../layouts/userCreation/userCreationLayout";
// import IntroSection from "../../components/introSection/introSection";
// import MaskGroup from "../../assets/MaskGroup.png";
// import RadioInput from "../../components/radioinput/radioinput";
// import { Colors } from "../../utilities/colors";
// import Button from "../../components/button/button";
// import Input from "../../components/input/input";
// import Dropdown from "../../components/dropdown/dropdown";
// import {
//   selfIntoStaticContent,
//   errorMessages,
//   schema,
// } from "./selfIntroFormConstant";
// import { mynextTalent } from "../mynextConstant";
// import ErrorField from "../../components/errorField/errorField";
// import {
//   // getCountries,
//   getAllCountries,
//   getStates,
//   getCities,
//   getEthinicities,
//   // onBoardStepOneForm,
//   // onBoardGetDataStudying,
//   // onAboutCountryandStateLIst,
//   // getCurrentCountries,
//   validateObf,
// } from "../../services/apiCalls";
// import Loader from "../../components/loader/loader";
// import { updateValues } from "./reducer";
// import { formatDate } from "../../utilities/commonFunctions";
// import { Col, Row } from "react-bootstrap";
// import DateFnsUtils from "@date-io/date-fns";
// import { MuiPickersUtilsProvider, DatePicker } from "@material-ui/pickers";
// import { createTheme, IconButton } from "@material-ui/core";
// import { ThemeProvider } from "@material-ui/styles";
// import moment from "moment";
// import CancelIcon from "../../assets/icons/skillCancel.png";
// import { useTranslation } from "react-i18next";

// const materialTheme = createTheme({
//   palette: {
//     primary: {
//       main: "#D44B9C",
//     },
//   },
//   overrides: {
//     MuiPickersCalendarHeader: {
//       switchHeader: {
//         color: "#D44B9C",
//       },
//     },
//   },
// });

// const FlexWrapper = styled.div`
//   display: flex;
//   align-items: center;
// `;

// const DatesWrapper = styled(FlexWrapper)`
//   label {
//     position: relative;
//     width: -webkit-fill-available;
//   }
// `;
// const CalenderIconWrapper = styled.div`
//   position: absolute;
//   right: 1rem;
//   top: 0.5rem;
//   cursor: pointer;
// `;
// const FormWrapper = styled.div`
//   margin: 30px 0 0 0;
//   max-width: 100%;
//   @media (max-width: 1024px) {
//     max-width: 100%;
//   }
// `;
// const LabelText = styled.label`
//   font-style: normal;
//   font-weight: 500;
//   font-size: 18px;
//   line-height: 24px;
//   color: ${Colors.labelText};
//   margin-bottom: 10px;
//   display: block;
// `;
// const FormGroup = styled.div`
//   margin-bottom: 25px;
// `;
// const IdentificationWrapper = styled.div`
//   display: -webkit-box;
//   align-items: center;
//   @media (max-width: 599px) {
//     // justify-content: space-between;
//     margin-bottom: 20px;
//   }
// `;
// const NationalityWrapper = styled.div`
//   margin-bottom: 10px;
// `;
// const DividerLine = styled.hr`
//   border: 0.5px solid #ebebeb;
//   margin: 30px 0;
// `;

// const RadioOptionWrapper = styled.div`
//   display: flex;
//   justify-content: space-between;
//   align-items: center;

//   @media (max-width: 599px) {
//     justify-content: space-between;
//   }
//   @media (max-width: 990px) {
//     justify-content: space-between;
//   }
// `;

// export default function AboutmeIntro() {
//   const formValues = useSelector((state) => state.selfIntroOne.value);
//   const [options, setOptions] = useState({});
//   const [disabledSatus, setDisableStatus] = useState(true);

//   const [mblNumber, setMblNumber] = useState("");
//   const [countries, setCountry] = useState([]);
//   const [currCountries, setCurrCountry] = useState([]);
//   const [countryListExcludeDefault, setNMcountry] = useState([]);
//   const [states, setStates] = useState([]);
//   const [cities, setCities] = useState([]);
//   const [races, setRaces] = useState([]);
//   const [scrollData, setScrollDatas] = useState([]);
//   const [loading, setLoading] = useState(false);
//   const [formErrors, setFormErrors] = useState("");
//   const [selectCity, setSelectCity] = useState({});
//   const [selectState, setSelectState] = useState({});
//   const [aboutFormErrors, setAboutFormErrors] = useState(false);

//   const [selectedCountry, setSelectedCountry] = useState({});
//   const [selectedState, setSelectedState] = useState({});
//   const [selectedCity, setSelectedCity] = useState({});
//   const [selectedRace, setSelectedRace] = useState({});
//   const [selectedNationality, setSelectedNationality] = useState({});
//   const [statesDisable, setStatesDisable] = useState(false);
//   const [citiesDisable, setCitiesDisable] = useState(false);

//   const {
//     control,
//     handleSubmit,
//     setValue,
//     watch,
//     resetField,
//     setError,
//     clearErrors,
//     formState: { errors, values },
//   } = useForm({
//     defaultValues: { ...formValues },
//     resolver: yupResolver(schema),
//     context: { statesDisable, citiesDisable },
//   });

//   const formVal = watch();
//   if (formVal.curr_country == "") {
//     formVal.curr_country = 132;
//   }
//   const currentDate = formatDate(new Date());
//   const dispatch = useDispatch();
//   const navigate = useNavigate();

//   useEffect(() => {
//     fetchCountry();
//     fetchEthinicities();
//   }, []);

//   const fetchCountry = async () => {
//     //const { data, status } = await getCountries();
//     const { data, status } = await getAllCountries();
//     if (status === 200) {
//       const location = data.data;
//       const country = location.map((x) => {
//         return { ...x, label: x.Name, value: x.Id };
//       });
//       setCountry(country);

//       const countryListExcludeDefault = country.filter(
//         (x) => x.value !== mynextTalent.defaultCountryId
//       );
//       setNMcountry(countryListExcludeDefault);

//       // Edit mode
//       if (formVal.curr_country) {
//         let selCountry = country.filter((x) => x.value == formVal.curr_country);
//         // let selCountry = country.filter(x => x.value == mynextTalent.defaultCountryId);
//         if (selCountry.length > 0) {
//           selCountry = selCountry[0];
//           setValue("curr_country", selCountry.value);
//         }
//         setSelectedCountry(selCountry);
//         fetchStates(selCountry.value);
//       }
//       // Nationality
//       if (formVal.country) {
//         let selNationality = country.filter((x) => x.value == formVal.country);
//         if (selNationality.length > 0) {
//           selNationality = selNationality[0];
//           setValue("country", selNationality.value);
//         }
//         setSelectedNationality(selNationality);
//       }
//     }
//   };

//   const fetchStates = async (countryId) => {
//     const { data, status } = await getStates(countryId);
//     if (status === 200) {
//       const location = data.data;
//       const states = location.map((x) => {
//         return { ...x, label: x.Name, value: x.Id };
//       });
//       if (states.length == 0) {
//         setStatesDisable(true);
//         setCitiesDisable(true);
//         setValue("city", "");
//         setValue("city_abbr", "");
//         setValue("state", "");
//         setValue("state_abbr", "");
//       } else {
//         setStatesDisable(false);
//         setCitiesDisable(false);
//       }
//       setStates(states);
//       if (formVal.state) {
//         let selState = states.filter((x) => x.value == formVal.state);
//         // let selState = states.filter(x => x.value == mynextTalent.defaultStateId);
//         if (selState.length > 0) {
//           selState = selState[0];
//           setValue("state", selState.value);
//           setSelectedState(selState);
//           fetchCities(countryId, selState.value);
//         }
//       }
//     }
//   };

//   const fetchCities = async (countryId, stateId) => {
//     const { data, status } = await getCities(countryId, stateId);
//     if (status === 200) {
//       const location = data.data;
//       const cities = location.map((x) => {
//         return { ...x, label: x.Name, value: x.Id };
//       });
//       if (cities.length == 0) {
//         setCitiesDisable(true);
//         setValue("city", "");
//         setValue("city_abbr", "");
//       } else {
//         setCitiesDisable(false);
//       }
//       setCities(cities);
//       if (formVal.city) {
//         let selCity = cities.filter((x) => x.value == formVal.city);
//         // let selCity = cities.filter(x => x.value == mynextTalent.defaultCityId);
//         if (selCity.length > 0) {
//           selCity = selCity[0];
//           setValue("city", selCity.value);
//         }
//         setSelectedCity(selCity);
//       }
//     }
//   };

//   const fetchEthinicities = async () => {
//     const { data, status } = await getEthinicities();
//     if (status === 200) {
//       const race = data.data;
//       const newRace = race.map((x) => {
//         return { ...x, label: x.Name, value: x.Id };
//       });
//       setRaces(newRace);
//       if (formVal.race) {
//         let selRace = newRace.filter((x) => x.value == formVal.race);
//         // let selRace = newRace.filter(x => x.value == mynextTalent.defaultRaceId);
//         if (selRace.length > 0) {
//           selRace = selRace[0];
//           setValue("race", selRace.value);
//         }
//         setSelectedRace(selRace);
//       }
//     }
//   };

//   const dropdownChange = (name, val) => {
//     setValue(name, val.value);
//     switch (name) {
//       case "country":
//         setSelectedNationality(val);
//         setValue("country_abbr", val.label);
//         setSelectedRace("");
//         setValue("race_abbr", "");
//         break;
//       case "curr_country":
//         setValue("city", "");
//         setValue("state", "");
//         setValue("postalcode", "");
//         setValue("state_abbr", "");
//         setValue("city_abbr", "");
//         setValue("curr_country_abbr", val.label);
//         setDisableStatus(false);
//         setSelectedCountry(val);
//         setSelectedState(null);
//         setSelectedCity(null);
//         setCities(null);
//         dispatch(
//           updateValues({
//             ...formValues,
//             ...formVal,
//             state: "",
//             city: "",
//           })
//         );
//         formVal.state = "";
//         formVal.city = "";
//         fetchStates(val.value);
//         break;
//       case "state":
//         setValue("city", "");
//         setValue("postalcode", "");
//         setValue("city_abbr", "");
//         setValue("state_abbr", val.label);
//         setSelectedState(val);
//         setSelectedCity(null);
//         dispatch(
//           updateValues({
//             ...formValues,
//             ...formVal,
//             city: "",
//           })
//         );
//         formVal.city = "";
//         fetchCities(formVal.curr_country, val.value);
//         break;
//       case "city":
//         setSelectedCity(val);
//         setValue("city_abbr", val.label);
//         setValue("postalcode", "");
//         formVal.postalcode = "";
//         dispatch(
//           updateValues({
//             ...formValues,
//             ...formVal,
//             postalcode: "",
//           })
//         );
//         break;
//       case "race":
//         setSelectedRace(val);
//         setValue("race_abbr", val.label);
//         setSelectedNationality("");
//         setValue("country", "");
//         setValue("country_abbr", "");
//         dispatch(
//           updateValues({
//             ...formValues,
//             ...formVal,
//             race: "",
//           })
//         );
//         break;
//       default:
//     }
//   };

//   const onErrorSubmit = () => {
//     setAboutFormErrors(true);
//   };

//   const onSubmit = async (formData) => {
//     if (Object.keys(errors).length === 0) {
//       dispatch(updateValues({ ...formValues, ...formVal }));
//       setAboutFormErrors(false);
//       window.allow_user_continue = true;
//       navigate("/aboutme");
//     }
//   };

//   const onMblNumberChange = (val) => {
//     if (val) {
//       setMblNumber(val);
//       setValue("mob_number", val);
//     }
//   };

//   const radioChange = (value, name) => {
//     setValue(name, value);
//     switch (name) {
//       case "disability_status":
//         if (value === 0) {
//           setValue("disability_code", "");
//         }
//         break;
//       case "id_type":
//         resetField("id_number");
//         setValue("nationality", 1);
//         setValue("country", "");
//         setValue("race", "");
//         setValue("country_abbr", "");
//         setValue("race_abbr", "");
//         setSelectedRace("");
//         setSelectedNationality("");
//         break;
//       case "nationality":
//         setValue("race", "");
//         setValue("race_abbr", "");
//         setSelectedRace("");
//         setValue("country", "");
//         setValue("country_abbr", "");
//         setSelectedNationality("");
//         break;
//       default:
//     }
//   };

//   const handleRegex = (value, name) => {
//     let res = /^[a-zA-Z]+$/.test(value);
//     if (!value) {
//       if (name === "full_name") {
//         setValue("full_name", "");
//       } else {
//         setValue("display_name", "");
//       }
//     } else {
//       if (res) {
//         setValue(name, value);
//       }
//     }
//   };

//   const inputChange = (e) => {
//     const { name } = e.target;
//     let value = e.target.value;

//     if (value) {
//       switch (name) {
//         case "id_number":
//           if (formVal.id_type === 1) {
//             var oldValue = ("" + value).replace(/\D/g, "");

//             var isNum = true;
//             if (oldValue.length > 0) {
//               let lastDigit = oldValue.charAt(oldValue.length - 1);
//               isNum = !isNaN(lastDigit);
//             }

//             let newValue = `${oldValue.substring(0, 6)}${
//               oldValue.length > 6 ? "-" : ""
//             }${oldValue.substring(6, 8)}${
//               oldValue.length > 8 ? "-" : ""
//             }${oldValue.substring(8, 12)}`;

//             if (/^[a-zA-Z\d-]+$/.test(oldValue) && isNum) {
//               setValue("id_number", newValue);
//             }
//           } else {
//             formVal.id_number = formVal?.id_number?.toUpperCase();
//             setValue(
//               "id_number",
//               /^[a-zA-Z\d]+$/.test(value?.toUpperCase())
//                 ? value?.toUpperCase()
//                 : formVal.id_number
//             );
//           }
//           break;
//         case "disability_code":
//           setValue(
//             name,
//             /^[0-9]+$/.test(value) ? value : formVal.disability_code
//           );
//           break;
//         default:
//           break;
//       }
//     } else {
//       setValue(name, value);
//     }
//   };

//   const onInputChange = (name, e) => {
//     const { value } = e.target;
//     const re = /^[a-zA-Z\s]*$/;
//     if (value === "" || re.test(value)) {
//       setValue(name, value);
//     }
//   };

//   const clearDate = (e, name) => {
//     e.preventDefault();
//     setValue(name, "");
//   };

//   const dateChange = (name, val) => {
//     setValue(name, formatDate(val));
//   };
//   const onValidate = async () => {
//     if (formVal.id_number) {
//       let code = formVal.id_type === 1 ? "1" : "0";
//       let req = {
//         data: formVal.id_number,
//       };
//       const { data, status } = await validateObf(req, code);
//       if (data && data.status === 1) {
//         clearErrors("id_number");
//       } else {
//         setError("id_number", { message: data && data.message });
//       }
//     }
//   };
//   const { t } = useTranslation();
  
//   return (
//     <UserCreationLayout sidebarImg={MaskGroup}>
//       <IntroSection
//         name={t(selfIntoStaticContent.title)}
//         description={t(selfIntoStaticContent.description)}
//       />
//       <PaginationDivider step={1} />
//       <FormWrapper>
//         <Row>
//           <FormGroup>
//             <LabelText>{t(selfIntoStaticContent.identification)}</LabelText>
//             <Row>
//               <Col lg="12" md="12" style={{ padding: "0px" }}>
//                 <IdentificationWrapper>
//                   <Controller
//                     name="id_type"
//                     control={control}
//                     render={({ field }) =>
//                       selfIntoStaticContent.identifications.map(
//                         (identity, index) => (
//                           <RadioInput
//                             key={identity.label + index}
//                             {...field}
//                             checked={identity.value === formVal.id_type}
//                             onChange={() =>
//                               radioChange(identity.value, "id_type")
//                             }
//                             label={t(identity.label)}
//                             margin="20px"
//                             name="id_type"
//                           />
//                         )
//                       )
//                     }
//                   />
//                 </IdentificationWrapper>
//               </Col>
//             </Row>
//             <Row>
//               <Col xl="4" lg="6" md="6" style={{ padding: "0px" }}>
//                 <Controller
//                   name="id_number"
//                   control={control}
//                   render={({ field }) => (
//                     <Input
//                       placeholder={
//                         formVal.id_type === 1
//                           ? t(selfIntoStaticContent.identificationNum)
//                           : t("Passport Number")
//                       }
//                       {...field}
//                       maxLength={formVal.id_type === 1 ? 14 : 16}
//                       onChange={inputChange}
//                       onBlur={onValidate}
//                     />
//                   )}
//                 />
//                 {errors.id_type && <ErrorField err={t(errorMessages.id_type)} />}
//                 {errors.id_number && (
//                   <ErrorField err={t(errors.id_number.message)} />
//                 )}
//               </Col>
//             </Row>
//           </FormGroup>
//         </Row>
//         <Row>
//           <Col>
//             <FormGroup style={{ paddingLeft: "0px" }}>
//               <LabelText>{t(selfIntoStaticContent.fullName)}</LabelText>
//               <Controller
//                 name="full_name"
//                 control={control}
//                 render={({ field }) => (
//                   <Input
//                     type="text"
//                     onChange={(e) => onInputChange("full_name", e)}
//                     placeholder={t("Full Name")}
//                     {...field}
//                     value={formVal.full_name}
//                     checked={1 === formVal.nationality}
//                     label={selfIntoStaticContent.malaysian}
//                     maxLength="100"
//                   />
//                 )}
//               />
//               {errors.full_name && (
//                 <ErrorField err={t(errors.full_name.message)} />
//               )}
//             </FormGroup>
//           </Col>
//         </Row>
//         <Row>
//           <Col>
//             <FormGroup style={{ paddingLeft: "0px" }}>
//               <LabelText>{t(selfIntoStaticContent.displayName)}</LabelText>
//               <Controller
//                 name="display_name"
//                 control={control}
//                 render={({ field }) => (
//                   <Input
//                     placeholder={t("Your display name")}
//                     {...field}
//                     maxLength="100"
//                   />
//                 )}
//               />
//               {errors.display_name && (
//                 <ErrorField err={t(errors.display_name.message)} />
//               )}
//             </FormGroup>
//           </Col>
//         </Row>
//         <Row>
//           <Col>
//             <FormGroup style={{ paddingLeft: "0px" }}>
//               <LabelText>{t(selfIntoStaticContent.genderTitle)}</LabelText>
//               <IdentificationWrapper>
//                 <Controller
//                   name="gender"
//                   control={control}
//                   render={({ field }) =>
//                     selfIntoStaticContent.genders.map((gender, index) => (
//                       <RadioInput
//                         checked={gender === formVal.gender}
//                         onChange={() => radioChange(gender, "gender")}
//                         key={gender + index}
//                         label={t(gender)}
//                         name="gender"
//                       />
//                     ))
//                   }
//                 />
//               </IdentificationWrapper>
//               {errors.gender && <ErrorField err={t(errors.gender.message)} />}
//             </FormGroup>
//           </Col>
//         </Row>
//         <Row>
//           <Col xl="4" lg="6" md="6">
//             <FormGroup style={{ paddingLeft: "0px" }}>
//               <LabelText>{t(selfIntoStaticContent.dob)}</LabelText>
//               <DatesWrapper>
//                 <Controller
//                   name="dob"
//                   control={control}
//                   render={({ field }) => (
//                     <>
//                       <label>
//                         <MuiPickersUtilsProvider utils={DateFnsUtils}>
//                           <ThemeProvider theme={materialTheme}>
//                             <DatePicker
//                               // {...field}
//                               autoOk
//                               disableFuture
//                               variant="inline"
//                               format="dd/MM/yyyy"
//                               placeholder={t("dd/mm/yyyy")}
//                               views={["year", "month", "date"]}
//                               value={formVal.dob ? new Date(formVal.dob) : null}
//                               onChange={(val) => dateChange("dob", val)}
//                               fullWidth
//                               maxDate={new Date(moment().subtract(16, "years"))}
//                             />
//                           </ThemeProvider>
//                         </MuiPickersUtilsProvider>
//                         <CalenderIconWrapper>
//                           <CalenderIcon />
//                           {formVal.dob && (
//                             <IconButton onClick={(e) => clearDate(e, "dob")}>
//                               <img src={CancelIcon} alt="" />
//                             </IconButton>
//                           )}
//                         </CalenderIconWrapper>
//                       </label>
//                     </>
//                   )}
//                 />
//               </DatesWrapper>
//               {errors.dob && <ErrorField err={t(errors.dob.message)} />}
//             </FormGroup>
//           </Col>
//         </Row>
//         <Row>
//           <Col xl="4" lg="6" md="6">
//             <FormGroup>
//               <LabelText>{t(selfIntoStaticContent.mblTitle)}</LabelText>
//               <Controller
//                 name="mob_number"
//                 control={control}
//                 render={({ field }) => (
//                   <PhoneInput
//                     international
//                     withCountryCallingCode={true}
//                     countryCallingCodeEditable={false}
//                     defaultCountry="MY"
//                     value={formVal.mob_number}
//                     onChange={onMblNumberChange}
//                     maxLength={16}
//                   />
//                 )}
//               />
//               {(errors.mob_number && formVal.mob_number === "") ||
//                 (errors.mob_number && (
//                   <ErrorField err={t(errors.mob_number.message)} />
//                 ))}
//             </FormGroup>
//           </Col>
//         </Row>

//         <FormGroup>
//           <Row>
//             <LabelText>{t(selfIntoStaticContent.nationality)}</LabelText>
//             <Col lg="12" sm="12" md="6" xl="4">
//               <NationalityWrapper>
//                 <Controller
//                   name="nationality"
//                   control={control}
//                   render={({ field }) => (
//                     <RadioInput
//                       {...field}
//                       checked={1 === formVal.nationality}
//                       onChange={() => radioChange(1, "nationality")}
//                       label={t(selfIntoStaticContent.malaysian)}
//                       margin="20px"
//                       name="nationality"
//                     />
//                   )}
//                 />
//                 <Controller
//                   name="race"
//                   control={control}
//                   render={({ field }) => (
//                     <Dropdown
//                       search={true}
//                       options={races}
//                       placeholder={t("Select the Race")}
//                       //val={formVal.race}
//                       val={formVal.nationality == 0 ? "" : selectedRace?.value}
//                       label={selectedRace?.label}
//                       disable={formVal.nationality == 1 ? false : true}
//                       change={(val) => dropdownChange("race", val)}
//                       {...field}
//                       refCallback={field?.ref} 
//                       ref={null}
//                     />
//                   )}
//                 />
//                 {errors.race && <ErrorField err={t(errors.race.message)} />}
//               </NationalityWrapper>
//             </Col>
//             <Col lg="12" sm="12" md="6" xl="4">
//               <NationalityWrapper>
//                 <Controller
//                   name="nationality"
//                   control={control}
//                   render={({ field }) => (
//                     <RadioInput
//                       checked={0 == formVal.nationality}
//                       onChange={
//                         formVal.id_type == 1
//                           ? ""
//                           : () => radioChange(0, "nationality")
//                       }
//                       label={t(selfIntoStaticContent.nonMalaysian)}
//                       disable={
//                         formVal.id_type == 1
//                           ? true
//                           : formVal.nationality == 1
//                           ? true
//                           : false
//                       }
//                       margin="20px"
//                       name="nationality"
//                       data_disabled={
//                         formVal.id_type == 1
//                           ? true
//                           : formVal.nationality == 1
//                           ? true
//                           : false
//                       }
//                     />
//                   )}
//                 />
//                 <Controller
//                   name="country"
//                   control={control}
//                   render={({ field }) => (
//                     <Dropdown
//                       search={true}
//                       options={countryListExcludeDefault}
//                       placeholder={t("Select the Country")}
//                       disable={
//                         formVal.id_type == 1
//                           ? true
//                           : formVal.nationality == 1
//                           ? true
//                           : false
//                       }
//                       val={
//                         formVal.id_type == 1 || formVal.nationality == 1
//                           ? ""
//                           : selectedNationality?.value
//                       }
//                       label={selectedNationality?.label}
//                       change={(val) => dropdownChange("country", val)}
//                       {...field}
//                       refCallback={field?.ref} 
//                       ref={null}
//                     />
//                   )}
//                 />
//                 {errors.country && <ErrorField err={t(errors.country.message)} />}
//               </NationalityWrapper>
//             </Col>
//             {errors.nationality && (
//               <ErrorField err={t(errorMessages.nationality)} />
//             )}
//           </Row>
//         </FormGroup>

//         <DividerLine />
//         <Row>
//           <Col lg="6">
//             <FormGroup>
//               <LabelText>{t(selfIntoStaticContent.currentlyLive)}</LabelText>
//               <Controller
//                 name="curr_country"
//                 control={control}
//                 render={({ field }) => (
//                   <Dropdown
//                     search={true}
//                     options={countries}
//                     change={(val) => dropdownChange("curr_country", val)}
//                     val={selectedCountry?.value}
//                     label={selectedCountry?.label}
//                     // onFocus={() => {getOptions('SL')}}
//                     placeholder={t("Enter your Country")}
//                     {...field}
//                     refCallback={field?.ref} 
//                     ref={null}
//                   />
//                 )}
//               />

//               {errors.curr_country && (
//                 <ErrorField err={t(errors.curr_country.message)} />
//               )}
//             </FormGroup>
//           </Col>
//         </Row>

//         <Row>
//           <Col lg="12" sm="12" md="12" xl="4">
//             <FormGroup>
//               <LabelText>
//                 {errors.state ? selfIntoStaticContent.state : t("State*")}
//               </LabelText>
//               <Controller
//                 name="state"
//                 control={control}
//                 render={({ field }) => (
//                   <Dropdown
//                     search={true}
//                     // options={selectState && selectState.curr_country}
//                     options={states}
//                     placeholder={t("Enter your State")}
//                     change={(val) => dropdownChange("state", val)}
//                     //val={formVal.state}
//                     val={selectedState?.value}
//                     label={selectedState?.label}
//                     disable={statesDisable}
//                     // onFocus={() => {getOptions('SL')}}
//                     {...field}
//                     refCallback={field?.ref} 
//                     ref={null}
//                   />
//                 )}
//               />
//               {errors.state && <ErrorField err={t(errors.state.message)} />}
//             </FormGroup>
//           </Col>

//           <Col lg="12" sm="12" md="6" xl="4">
//             <FormGroup>
//               <LabelText>
//                 {errors.city ? selfIntoStaticContent.city : t("City*")}
//               </LabelText>
//               <Controller
//                 name="city"
//                 control={control}
//                 render={({ field }) => (
//                   <Dropdown
//                     search={true}
//                     placeholder={t("Enter your City")}
//                     change={(val) => dropdownChange("city", val)}
//                     //val={formVal.city}
//                     val={selectedCity?.value}
//                     label={selectedCity?.label}
//                     disable={citiesDisable}
//                     // onFocus={() => {getOptions('SL')}}
//                     // options={selectCity && selectCity.state}
//                     options={cities}
//                     {...field}
//                     refCallback={field?.ref} 
//                     ref={null}
//                   />
//                 )}
//               />
//               {errors.city && <ErrorField err={t(errors.city.message)} />}
//             </FormGroup>
//           </Col>

//           <Col lg="12" md="6" sm="12" xl="4">
//             <FormGroup>
//               <LabelText>{t(selfIntoStaticContent.postcode)}</LabelText>
//               <Controller
//                 name="postalcode"
//                 control={control}
//                 render={({ field }) => (
//                   <Input
//                     placeholder={t("Postcode")}
//                     type="text"
//                     onKeyPress={(event) => {
//                       if (!/[a-zA-Z0-9]/.test(event.key)) {
//                         event.preventDefault();
//                       }
//                       if (event.target.value.length > 9) {
//                         event.preventDefault();
//                       }
//                     }}
//                     // value={postalcode}
//                     // onKeyPress={validatePostCode}
//                     disabled={formVal.curr_country ? false : true}
//                     {...field}
//                   />
//                 )}
//               />
//               {errors.postalcode && (
//                 <ErrorField err={t(errors.postalcode.message)} />
//               )}
//             </FormGroup>
//           </Col>
//         </Row>

//         <DividerLine />
//         <Row>
//           <FormGroup>
//             <LabelText>{t(selfIntoStaticContent.pku)}</LabelText>
//             <Row>
//               <Col lg="6" md="6">
//                 <RadioOptionWrapper>
//                   <Controller
//                     name="disability_status"
//                     control={control}
//                     rules={{ required: true }}
//                     render={({ field }) =>
//                       selfIntoStaticContent.isDisability.map(
//                         (option, index) => (
//                           <RadioInput
//                             checked={option.value == formVal.disability_status}
//                             onChange={() =>
//                               radioChange(option.value, "disability_status")
//                             }
//                             key={option.label + index}
//                             label={t(option.label)}
//                             margin="10px"
//                             name="disability_status"
//                           />
//                         )
//                       )
//                     }
//                   />
//                 </RadioOptionWrapper>
//               </Col>

//               <Col lg="6" md="6">
//                 <Controller
//                   name="disability_code"
//                   control={control}
//                   rules={{ required: true }}
//                   render={({ field }) => (
//                     <Input
//                       placeholder={t("If Yes, OKU Registration Number")}
//                       disabled={formVal.disability_status == 0}
//                       maxLength={12}
//                       {...field}
//                       onChange={inputChange}
//                     />
//                   )}
//                 />
//               </Col>

//               {errors.disability_code && (
//                 <ErrorField err={t(errors.disability_code.message)} />
//               )}
//             </Row>
//           </FormGroup>
//         </Row>

//         <Row>
//           <Col lg="4">
//             <FormGroup>
//               {formErrors && <ErrorField err={t(formErrors)} />}
//               {loading ? (
//                 <Loader />
//               ) : (
//                 <Button
//                   onClick={handleSubmit(onSubmit, onErrorSubmit)}
//                   name={t("Continue")}
//                   width="198px"
//                 />
//               )}
//             </FormGroup>
//             {aboutFormErrors && (
//               <ErrorField
//                 err={
//                   t("Please make sure all the mandatory fields have been inserted")
//                 }
//               />
//             )}
//           </Col>
//         </Row>
//       </FormWrapper>
//     </UserCreationLayout>
//   );
// }

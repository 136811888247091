import * as yup from "yup";
import { countPlaces } from "../../utilities/commonFunctions";
export const employeeStaticContent = {
  title: "Tell us more about yourself",
  description:
    "We will need some basic information about you before we get started",
  employeeTitle: "Current Employer(please provide exact company name)?*",
  sectorTitle: "Sector/Industry*",
  scope: "Scope of Study*",
  gradeTitle: "Please indicate your current grade*",
  academicTitle: "Highest Academic Qualification*",

  grades: ["CGPA", "Grades", "Others", "No Current Grade"],
  englishTestTitle: "English Equivalent Tests*",
  tests: [
    "MUET",
    "CEFR",
    "TOEFL",
    "IELTS",
    "Others",
    "I have not taken any tests",
  ],

  workDuration: "Years of Experience (Tenure in Years)*",
  job: "Position/Job Title*",
  allowance: "Salary (RM per month before tax)*",
  highestQualification: "Country of Highest Institution*",
  countries: [
    { label: "Malaysia", value: 1 },
    { label: "Other Countries", value: 0 },
  ],
  tcProgram: "Are you currently taking part in any TalentCorp programmes?*",
  programs: [
    { label: "Yes", value: 1 },
    { label: "No", value: 0 },
  ],
  lastEmployee: "Where were you last employed?*",
};

export const employeeFields = {
  curr_employer: "",
  sector: "",
  dur_year: "",
  dur_month: "",
  position: "",
  salary: "",
  high_qualification: "",
  insti_country_status: "",
  insti_name: "",
  insti_country: "",
  scope: "",
  grade_status: "",
  grade: "",
  english_tests: "",
  english_score: "",
  curr_tc_program: "",
  programmee: "",
  last_employer: "",
  step: 2,
};

export const errorMessages = {
  curr_tc_program: "Please select anyone option.",
  insti_location_status: "Please select one option.",
};

const tests = ["MUET", "CEFR", "TOEFL", "IELTS", "Others"];

export const employeeSchema = yup
  .object({
    curr_employer: yup.string().required("Company name is required"),
    sector: yup.string().required("Sector/Industry is required"),
    dur_year: yup
      .string()
      .required("Year is required")
      .test(
        "dur_year",
        "Year should be minimum of 0 and maximum of 100",
        (val) => val === undefined || val === null || (val <= 100 && val >= 0)
      ),

    dur_month: yup
    .string()
    .required("Month is required")
    .test(
      "dur_month",
      "Month should be between 1 to 12",
      (val) => val === undefined || val === null || (val <= 12 && val >= 1)
    ),
    position: yup.string().required("Job title is required"),
    salary: yup.string().required("Allowance per month is required"),
    high_qualification: yup
      .string()
      .required("Academic qualification is required"),
    insti_country_status: yup.string().required("Country is required"),
    insti_name: yup
      .string()
      .when("$instiCountryDisable",(instiCountryDisable)=>{
        if(instiCountryDisable){
          return yup.string().required("Institution country is required")
        }
      }),
      // .required("Institution name is required"),
    university: yup
      .string()
      .when("$instiCountryDisable", (instiCountryDisable) => {
        if (!instiCountryDisable) {
          return yup.string().required().matches(/^[A-Za-z][A-Za-z0-9 .,\-'&]+$/, "Insert valid university name");
        }
      }),
    insti_country: yup
      .string()
      .when("$instiCountryDisable", (instiCountryDisable) => {
        if (!instiCountryDisable) {
          return yup.string().required("Institution country is required");
        }
      }),
    //insti_country: yup.string().required("Institution country is required "),
    scope: yup.string().required("scope is required"),
    grade_status: yup.string().required("grade status is required"),
    grade: yup.string().when("grade_status", (grade_status) => {
      if (grade_status === "CGPA") {
        return yup
          .string()
          .required("CGPA is required")
          .test(
            "grade",
            "CGPA should be between 1 and 10 and precision up to 2 decimal places",
            (val) => val === undefined || val === null || (val <= 10 && val >= 1)
          );
      }
      else if (grade_status === "Grades") {
        return yup
          .string()
          .required("Grade is required");
      }
      else if (grade_status === "Others") {
        return yup
          .string()
          .required("Grade is required");
      }
      else {
        if (tests.indexOf(grade_status) !== -1)
          return yup.string().required("Grade is required");
      }
    }),
    english_tests: yup.string().required("Test selection is required"),
    english_score: yup.string().when("english_tests", (english_tests) => {
      if (english_tests === "IELTS") {
        return yup
          .string()
          .required("Test score is required")
          .test(
            "english_score",
            "Score should be between 0 and 9",
            (val) => val === undefined || val === null || (val <= 9 && val >= 0)
          );
      } else if (english_tests === "TOEFL") {
        return yup
          .string()
          .required("Test score is required")
          .test(
            "english_score",
            "Score should be between 0 and 30",
            (val) => val === undefined || val === null || (val <= 30 && val >= 0)
          );
      } else if (english_tests === "Others") {
        return yup
          .string()
          .required("Test score and type is required")
      } else {
        if (tests.indexOf(english_tests) !== -1)
          return yup.string().required("Test score is required");
      }
    }),
  })
  .required();

export const unEmployeeSchema = yup
  .object({
    high_qualification: yup
      .string()
      .required("Academic qualification is required"),
    insti_country_status: yup
      .string()
      .required("Institution country name is required"),
    insti_name: yup
      .string()
      .required("Institution name is required"),
    university: yup
      .string()
      .when("$instiCountryDisable", (instiCountryDisable) => {
        if (!instiCountryDisable) {
          return yup.string().required().matches(/^[A-Za-z][A-Za-z0-9 .,\-'&]+$/, "Insert valid university name");
        }
      }),
    // insti_country: yup
    //   .string()
    //   .when("insti_country_status", (insti_country_status) => {
    //     if (insti_country_status === 0) return yup.string().required();
    //   }),
    insti_country: yup
      .string()
      .when("$instiCountryDisable", (instiCountryDisable) => {
        if (!instiCountryDisable) {
          return yup.string().required("Institution country is required");
        }
      }),
    scope: yup.string().required("scope is required"),
    grade_status: yup.string().required("grade status is required"),
    grade: yup.string().when("grade_status", (grade_status) => {
      if (grade_status === "CGPA") {
        return yup
          .string()
          .required("CGPA is required")
          .test(
            "grade",
            "CGPA should be between 1 and 10 and precision up to 2 decimal places",
            (val) => val === undefined || val === null || (val <= 10 && val >= 1)
          );
      }
      else if (grade_status === "Grades") {
        return yup
          .string()
          .required("Grade is required");
      }
      else if (grade_status === "Others") {
        return yup
          .string()
          .required("Grade is required");
      }
      else {
        if (tests.indexOf(grade_status) !== -1)
          return yup.string().required("Grade is required");
      }
    }),
    english_tests: yup.string().required("Test selection is required"),
    english_score: yup.string().when("english_tests", (english_tests) => {
      if (english_tests === "IELTS") {
        return yup
          .string()
          .required("Test score is required")
          .test(
            "english_score",
            "Score should be between 0 and 9",
            (val) => val === undefined || val === null || (val <= 9 && val >= 0)
          );
      } else if (english_tests === "TOEFL") {
        return yup
          .string()
          .required("Test score is required")
          .test(
            "english_score",
            "Score should be between 0 and 30",
            (val) => val === undefined || val === null || (val <= 30 && val >= 0)
          );
      } else if (english_tests === "Others") {
        return yup
          .string()
          .required("Test score and type is required")
      } else {
        if (tests.indexOf(english_tests) !== -1)
          return yup.string().required("Test score is required");
      }
    }),
    curr_tc_program: yup.number().required(),
    programmee: yup.string().when("curr_tc_program", (curr_tc_program) => {
      if (curr_tc_program === 1)
        return yup.string().required("Talentcorp programme is required");
    }),
    last_employer: yup.string().required("Employment detail is required"),
  })
  .required();

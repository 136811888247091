import { useState } from "react";

import styled from "styled-components";
import { useSelector } from "react-redux";
import { useNavigate } from "react-router-dom";

import ErrorField from "../../components/errorField/errorField";
import PaginationDivider from "../../components/paginationDivider/paginationDivider";
import UserCreationLayout from "../../layouts/userCreation/userCreationLayout";
import IntroSection from "../../components/introSection/introSection";
import overview from "../../assets/overview.png";
import detailimage from "../../assets/avatar.png";
import Button from "../../components/button/button";
import { onBoardFormSubmission } from "../../services/apiCalls";
import { removeKeys } from "../../utilities/commonFunctions";
import Loader from "../../components/loader/loader";
import { mynextTalent } from "../mynextConstant";
import ReactGA from "react-ga4";
import ReactPixel from 'react-facebook-pixel';
import { useTranslation } from "react-i18next";

import { deviceStore } from "../../utilities/devices";


const ImageWrappper = styled.div`
  display: flex;
  flex-direction: row;
  justify-content: center;
  width: 100%;
`;
const DetailImage = styled.img`
  height: 200px;
  width: 200px;
  border-radius: 50%;
  border: 1px dashed #d04c8d;
  padding: 10px;
`;

const Line = styled.hr`
  border: 1px solid rgba(235, 235, 235, 0.62);
  max-width: 100%;
  margin: 20px 0;
  min-width: 200px;

  @media (max-width: 900px) {
    max-width: 100%;
  }
`;
const HeadingWrapper = styled.div`
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  width: 100%;
  margin-top: 50px;
`;
const CardHeader = styled.span`
  font-weight: 600;
  font-size: 22px;
  line-height: 25px;
  color: #000;
`;

const CardSubHeader = styled.span`
  font-weight: 500;
  font-size: 18px;
  line-height: 25px;
  color: #d04c8d;
  text-decoration: underline;
  cursor: pointer;
`;
const OverviewWrapper = styled.div`
  display: flex;
  flex-direction: column;
  margin-top: 20px;
`;
const DetailQuestion = styled.label`
  font-weight: 500;
  font-size: 18px;
  line-height: 25px;
  color: #000;
`;
const DetailAnswer = styled.label`
  font-weight: 600;
  font-size: 18px;
  line-height: 25px;
  color: #d04c8d;
`;
const GridContainer = styled.div`
  display: grid;
  grid-column-gap: ${(props) => (props.gap ? props.gap : "30px")};
  grid-template-columns: ${(props) =>
    props.columns ? props.columns : "auto auto"};

  @media (max-width: 1060px) {
    display: block;
  }
`;

export default function Overview() {
  const formValues = useSelector((state) => state.selfIntroOne.value);
  const [loading, setLoading] = useState(false);
  const [formErrors, setFormErrors] = useState("");
  const navigate = useNavigate();
  const options = {
    autoConfig: true, // set pixel's autoConfig. More info: https://developers.facebook.com/docs/facebook-pixel/advanced/
    debug: false, // enable logs
  };

  const onSubmit = async () => {

    var gaTrackingID = process.env.REACT_APP_ENV === "UAT" ? process.env.REACT_APP_GOOGLE_ANALYTICS_EVENT_UAT : process.env.REACT_APP_GOOGLE_ANALYTICS_EVENT_PRODUCTION;
    var fbPixelTrackingID = process.env.REACT_APP_ENV === "UAT" ? process.env.REACT_APP_FACEBOOK_PIXEL_EVENT_UAT : process.env.REACT_APP_FACEBOOK_PIXEL_EVENT_PRODUCTION;

    await ReactGA.initialize(gaTrackingID);
    await ReactGA.event({
      category: "Talent",
      action: "Submit About Me",
      label: "Complete Registration",
      value: 1,
    });

    await ReactPixel.init(fbPixelTrackingID, options);
    await ReactPixel.trackCustom("Complete Registration", {
      category: "Talent",
      action: "Submit About Me",
      label: "Complete Registration",
    });
    setLoading(true);
    const req = removeKeys({ ...formValues });
    const { data } = await onBoardFormSubmission({
      ...req,
      step: formValues.profile_picture ? 4 : 0,
      // step: 0
    });
    if (deviceStore.platform === 'ios') {
      navigate("/dashboard");
      setLoading(false);
      setFormErrors("");

    } else {
      if (data && data.status === 1) {
        navigate("/dashboard");
        setLoading(false);
        setFormErrors("");
      } else {
        setLoading(false);
        setFormErrors(data && data.message);

      }

    }
  };

  const onNavigate = (path) => {
    switch (path) {
      case "status":
        if (
          formValues.talent_status === "studying" ||
          formValues.talent_status === "internship"
        ) {
          navigate("/aboutme");
          //navigate("/studying");
        } else {
          navigate("/aboutme");
          //navigate("/employeed");
        }
        break;
      default:
        navigate("/self-intro");
    }
  };

  const getUniName = (talent_status, insti_country_status, insti_location_status, insti_name, university) => {
    //returns true if study locally, false if study abroad
    if (talent_status === "studying" || talent_status === "internship") {
      if (insti_location_status === 1) { //study locally
        return true;
      } else { //study abroad
        return false;
      }
    } else if (talent_status === "employed" || talent_status === "unemployed") {
      if (insti_country_status === 1) { //study locally
        return true;
      } else { //study abroad
        return false;
      }
    }
  };

  const { t } = useTranslation();

  return (
    <UserCreationLayout sidebarImg={overview}>
      <IntroSection
        name={t("You're almost done")}
        description={t("Please review all the fields that you entered and make sure it is accurate before submiting")}
      />
      <PaginationDivider step={4} />
      <ImageWrappper>
        <DetailImage
          src={
            formValues.profile_picture
              ? formValues.profile_picture
              : detailimage
          }
        />
      </ImageWrappper>
      <HeadingWrapper>
        <CardHeader>{t("Personal Information")}</CardHeader>
        <CardSubHeader onClick={() => onNavigate("/self-intro")}>
          {t("Edit")}
        </CardSubHeader>
      </HeadingWrapper>
      <OverviewWrapper>
        <DetailQuestion>
          {t("Identification - ")}
          {formValues.id_type === 1 ? t("Malaysian IC") : t("Passport")}
        </DetailQuestion>
        <DetailAnswer>{formValues.id_number}</DetailAnswer>
      </OverviewWrapper>
      <OverviewWrapper>
        <DetailQuestion>{t("Full Name")}</DetailQuestion>
        <DetailAnswer>{formValues.full_name}</DetailAnswer>
      </OverviewWrapper>
      <OverviewWrapper>
        <DetailQuestion>{t("Gender")}</DetailQuestion>
        <DetailAnswer>{t(formValues.gender)}</DetailAnswer>
      </OverviewWrapper>
      <OverviewWrapper>
        <DetailQuestion>{t("Date of Birth")}</DetailQuestion>
        <DetailAnswer>{formValues.dob}</DetailAnswer>
      </OverviewWrapper>
      <OverviewWrapper>
        <DetailQuestion>{t("Mobile Number")}</DetailQuestion>
        <DetailAnswer>{formValues.mob_number}</DetailAnswer>
      </OverviewWrapper>
      <OverviewWrapper>
        <DetailQuestion>{t("Nationality")}</DetailQuestion>
        <DetailAnswer>
          {formValues.nationality === 1
            ? `Malaysian ${formValues.race_abbr}`
            : `Non Malaysian`}
        </DetailAnswer>
      </OverviewWrapper>
      <GridContainer gap="5px" columns="repeat(2,1fr)">
        <OverviewWrapper>
          <DetailQuestion>{t("Country")}</DetailQuestion>
          <DetailAnswer>
            {formValues.curr_country === 132
              ? mynextTalent.defaultCountry
              : formValues.curr_country_abbr}
          </DetailAnswer>
        </OverviewWrapper>
        {formValues.state_abbr && (
          <OverviewWrapper>
            <DetailQuestion>{t("State")}</DetailQuestion>
            <DetailAnswer>{formValues.state_abbr}</DetailAnswer>
          </OverviewWrapper>
        )}
      </GridContainer>
      <GridContainer gap="5px" columns="repeat(2,1fr)">
        {formValues.city_abbr && (
          <OverviewWrapper>
            <DetailQuestion>{t("City")}</DetailQuestion>
            <DetailAnswer>{formValues.city_abbr}</DetailAnswer>
          </OverviewWrapper>
        )}

        <OverviewWrapper>
          <DetailQuestion>{t("Postcode")}</DetailQuestion>
          <DetailAnswer>{formValues.postalcode}</DetailAnswer>
        </OverviewWrapper>
      </GridContainer>
      <OverviewWrapper>
        <DetailQuestion>OKU</DetailQuestion>
        <DetailAnswer>
          {formValues.disability_status ? formValues.disability_code : t("No")}
        </DetailAnswer>
      </OverviewWrapper>
      <Line />
      <HeadingWrapper>
        <CardHeader>{t("Current Status Details")}</CardHeader>
        <CardSubHeader onClick={() => onNavigate("status")}>{t("Edit")}</CardSubHeader>
      </HeadingWrapper>
      <OverviewWrapper>
        <DetailQuestion>{t("Status")}</DetailQuestion>
        <DetailAnswer>
          {formValues?.talent_status?.charAt(0)?.toUpperCase() +
            formValues?.talent_status?.slice(1)?.replace(/-./g, " ")}
        </DetailAnswer>
      </OverviewWrapper>
      {formValues.talent_status === "unemployed" ? (
        <>
          <GridContainer gap="5px" columns="repeat(2,1fr)">
            {formValues.curr_tc_program === 1 && (
              <OverviewWrapper>
                <DetailQuestion>{t("TalentCorp Programmes")}</DetailQuestion>
                <DetailAnswer>{formValues.curr_tc_program_abbr}</DetailAnswer>
              </OverviewWrapper>
            )}
            <OverviewWrapper>
              <DetailQuestion>{t("Last Employer")}</DetailQuestion>
              <DetailAnswer>{formValues.last_employer}</DetailAnswer>
            </OverviewWrapper>
          </GridContainer>
        </>
      ) : (
        ""
      )}
      {/* employeed */}
      {formValues.talent_status === "employed" && (
        <>
          <GridContainer gap="5px" columns="repeat(2,1fr)">
            <OverviewWrapper>
              <DetailQuestion>{t("Sector/Industry")}</DetailQuestion>
              <DetailAnswer>{formValues.sector_abbr}</DetailAnswer>
            </OverviewWrapper>
            <OverviewWrapper>
              <DetailQuestion>{t("Current Employer")}</DetailQuestion>
              <DetailAnswer>{formValues.curr_employer}</DetailAnswer>
            </OverviewWrapper>
          </GridContainer>
          <GridContainer gap="5px" columns="repeat(2,1fr)">
            <OverviewWrapper>
              <DetailQuestion>{t("Position/Job Title")}</DetailQuestion>
              <DetailAnswer>{formValues.position}</DetailAnswer>
            </OverviewWrapper>
            <OverviewWrapper>
              <DetailQuestion>
                {t("Working Duration (Tenure in Years)")}
              </DetailQuestion>
              <DetailAnswer>
                {formValues.dur_year === "1" ? (
                  <label>{formValues.dur_year + " year"}</label>
                ) : (
                  <label>{formValues.dur_year + " years"}</label>
                )}{" "}
                {formValues.dur_month === "0" ||
                  formValues.dur_month === "1" ? (
                  <label>{formValues.dur_month + " month"}</label>
                ) : (
                  <label>{formValues.dur_month + " months"}</label>
                )}
              </DetailAnswer>
            </OverviewWrapper>
          </GridContainer>
          <GridContainer gap="5px" columns="repeat(2,1fr)">
            <OverviewWrapper>
              <DetailQuestion>{t("Salary (RM per month before tax)")}</DetailQuestion>
              <DetailAnswer>{formValues.salary}</DetailAnswer>
            </OverviewWrapper>
          </GridContainer>
        </>
      )}
      {formValues.talent_status === "unemployed" && (
        <>
          <OverviewWrapper>
            <DetailQuestion>{t("Highest Institution Country")}</DetailQuestion>
            <DetailAnswer>
              {formValues.insti_country_status === 1
                ? mynextTalent.defaultCountry
                : formValues.insti_country_abbr}
            </DetailAnswer>
          </OverviewWrapper>
        </>
      )}
      {formValues.talent_status === "employed" && (
        <>
          <OverviewWrapper>
            <DetailQuestion>{t("Highest Institution Country")}</DetailQuestion>
            <DetailAnswer>
              {formValues.insti_country_status === 1
                ? mynextTalent.defaultCountry
                : formValues.insti_country_abbr}
            </DetailAnswer>
          </OverviewWrapper>
        </>
      )}
      {/* end */}

      {getUniName(formValues.talent_status, formValues.insti_country_status, formValues.insti_location_status, formValues.insti_name_abbr, formValues.university) ? (
        <>
          {formValues.insti_name_abbr && (
            <>
              <OverviewWrapper>
                <DetailQuestion>{t("Institution")}</DetailQuestion>
                <DetailAnswer>{formValues.insti_name_abbr}</DetailAnswer>
              </OverviewWrapper>
            </>
          )}

          {formValues.campus_abbr && (
            <>
              <OverviewWrapper>
                <DetailQuestion>{t("Campus")}</DetailQuestion>
                <DetailAnswer>{formValues.campus_abbr}</DetailAnswer>
              </OverviewWrapper>
            </>
          )}
          {formValues.faculty && (
            <>
              <OverviewWrapper>
                <DetailQuestion>{t("Faculty")} </DetailQuestion>
                <DetailAnswer>{formValues.faculty_abbr}</DetailAnswer>
              </OverviewWrapper>
            </>
          )}
          {formValues.study_program && (
            <>
              <OverviewWrapper>
                <DetailQuestion>{t("Programme")}</DetailQuestion>
                <DetailAnswer>{formValues.study_program_abbr}</DetailAnswer>
              </OverviewWrapper>
            </>
          )}
        </>
      ) : (
        <>
          {formValues.university && (
            <>
              <OverviewWrapper>
                <DetailQuestion>{t("University")}</DetailQuestion>
                <DetailAnswer>{formValues.university}</DetailAnswer>
              </OverviewWrapper>
            </>
          )}

          {formValues.talent_status === "internship" ||
            (formValues.talent_status === "studying" && (
              <>
                {formValues.insti_country && (
                  <>
                    <OverviewWrapper>
                      <DetailQuestion>{t("Country")}</DetailQuestion>
                      <DetailAnswer>
                        {formValues.insti_country_abbr}
                      </DetailAnswer>
                    </OverviewWrapper>
                  </>
                )}
              </>
            ))}
        </>
      )}

      {/* {formValues.insti_country && (
        <>
          <OverviewWrapper>
            <DetailQuestion>Country</DetailQuestion>
            <DetailAnswer>{formValues.insti_country_abbr}</DetailAnswer>
          </OverviewWrapper>
        </>
      )} */}

      {formValues.talent_status === "internship" ? (
        <>
          <GridContainer gap="5px" columns="repeat(2,1fr)">
            <OverviewWrapper>
              <DetailQuestion>{t("Internship Duration")}</DetailQuestion>
              <DetailAnswer>
                {formValues.intern_dur_year} &nbsp; &nbsp;
                {formValues.intern_dur_month}
              </DetailAnswer>
            </OverviewWrapper>
            <OverviewWrapper>
              <DetailQuestion>{t("Position/Job Title")}</DetailQuestion>
              <DetailAnswer>{formValues.intern_position}</DetailAnswer>
            </OverviewWrapper>
          </GridContainer>
          <OverviewWrapper>
            <DetailQuestion>{t("Allowance Per Month (RM)")} </DetailQuestion>
            <DetailAnswer>{formValues.intern_allowance}</DetailAnswer>
          </OverviewWrapper>
        </>
      ) : (
        ""
      )}
      <OverviewWrapper>
        <DetailQuestion>{t("Scope of Study")} </DetailQuestion>
        <DetailAnswer>{formValues.scope_abbr}</DetailAnswer>
      </OverviewWrapper>
      {formValues.talent_status === "studying" ||
        formValues.talent_status === "internship" ? (
        <>
          <GridContainer gap="5px" columns="repeat(2,1fr)">
            <OverviewWrapper>
              <DetailQuestion>{t("University Enrollment Date")}</DetailQuestion>
              <DetailAnswer>{formValues.univ_enroll_date}</DetailAnswer>
            </OverviewWrapper>
            <OverviewWrapper>
              <DetailQuestion>{t("Expected Graduation Date")}</DetailQuestion>
              <DetailAnswer>{formValues.exp_graduate_date}</DetailAnswer>
            </OverviewWrapper>
          </GridContainer>
          <OverviewWrapper>
            <DetailQuestion>{t("Current Year of Study")}</DetailQuestion>
            <DetailAnswer>{formValues.curr_study_year_abbr}</DetailAnswer>
          </OverviewWrapper>
        </>
      ) : (
        ""
      )}
      <GridContainer gap="5px" columns="repeat(2,1fr)">
        <OverviewWrapper>
          <DetailQuestion>{t("Academic Qualification")}</DetailQuestion>
          <DetailAnswer>
            {formValues.curr_qualification
              ? formValues.curr_qualification_abbr
              : formValues.high_qualification_abbr}
          </DetailAnswer>
        </OverviewWrapper>
        <OverviewWrapper>
          <DetailQuestion>{t("Current Grade")}</DetailQuestion>
          <DetailAnswer>
            {formValues.grade_status === "Grades"
              ? formValues.grade_abbr
              : formValues.grade}
          </DetailAnswer>
        </OverviewWrapper>
      </GridContainer>
      {formValues.talent_status === "studying" ||
        formValues.talent_status === "internship" ? (
        <OverviewWrapper>
          <DetailQuestion>{t("Type of Scholarship")}</DetailQuestion>
          <DetailAnswer>
            {formValues.scholar_status === 0
              ? (formValues.scholar_data === 1
                ? t("MARA (Scholarship)")
                : formValues.scholar_data === 2
                  ? t("JPA (Loan)")
                  : formValues.scholar_data === 3
                    ? t("Others")
                    : formValues.scholar_data === 4
                      ? t("MARA (Loan)")
                      : formValues.scholar_data === 5
                        ? t("PTPTN (Loan)")
                        : formValues.scholar_data === 6
                          ? t("JPA (Scholarship)") : "")
              : t("Self funded")}
          </DetailAnswer>
        </OverviewWrapper>
      ) : (
        ""
      )}
      <GridContainer gap="5px" columns="repeat(2,1fr)">
        <OverviewWrapper>
          <DetailQuestion>{t("English Test Taken")}</DetailQuestion>
          <DetailAnswer>{formValues.english_tests}</DetailAnswer>
        </OverviewWrapper>
        <OverviewWrapper>
          <DetailQuestion>{t("English Test Result")}</DetailQuestion>
          <DetailAnswer>{formValues.english_score}</DetailAnswer>
        </OverviewWrapper>
      </GridContainer>
      {formErrors && <ErrorField err={formErrors} />}
      <OverviewWrapper>
        {loading ? (
          <Loader />
        ) : (
          <Button width="30%" onClick={onSubmit} name={t("Finish")} />
        )}
      </OverviewWrapper>
    </UserCreationLayout>
  );
}

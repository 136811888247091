import { useState, useEffect } from "react";

import styled from "styled-components";
import { useNavigate } from "react-router-dom";
import { useForm, Controller } from "react-hook-form";
import { yupResolver } from "@hookform/resolvers/yup";
import * as yup from "yup";
import { useSelector, useDispatch } from "react-redux";
import { useTranslation } from "react-i18next";

import UserCreationLayout from "../../layouts/userCreation/userCreationLayout";
import IntroSection from "../../components/introSection/introSection";
import PaginationDivider from "../../components/paginationDivider/paginationDivider";
import employecover from "../../assets/employecover.png";
import unEmployeeCover from "../../assets/unemployeecover.png";
import { Colors } from "../../utilities/colors";
import RadioInput from "../../components/radioinput/radioinput";
import Dropdown from "../../components/dropdown/dropdown";
import ErrorField from "../../components/errorField/errorField";
import { employeeStaticContent, errorMessages, employeeSchema, unEmployeeSchema } from "./staticContent";
import Input from "../../components/input/input";
import Button from "../../components/button/button";
import {
  onBoardStepTwoForm,
  onBoardFormSubmission,
  // onBoardGetDataStudying,
  getOption,
  getAllCountries,
  getUniversity,
  getAcademicQualifications,
  getScopeOfStudies,
  getGrades,
  getSector,
  getProgrameTypes,
} from "../../services/apiCalls";
import { updateValues } from "../selfIntro/reducer";
import Loader from "../../components/loader/loader";
import { removeKeys } from "../../utilities/commonFunctions";
import { Col, Row } from "react-bootstrap";
// styles start
const FlexWrapper = styled.div`
  display: flex;
  align-items: center;
`;
const WidthWrapper = styled.div`
  width: ${(props) => (props.width ? props.width : "100%")};
`;
const FormWrapper = styled.div`
  margin: 30px 0 0 0;
  max-width: 100%;
  @media (max-width: 1024px) {
    max-width: 100%;
  }
`;
const LabelText = styled.label`
  font-style: normal;
  font-weight: 500;
  font-size: 18px;
  line-height: 24px;
  color: ${Colors.labelText};
  margin-bottom: 10px;
  display: block;
`;
const FormGroup = styled.div`
  margin-bottom: 25px;
  
`;
const ScholarshipWrapper = styled.div`
  display: flex;
  align-items: center;
  @media (max-width: 599px) {
    margin-bottom: 20px;
  }
`;
const GradeWrapper = styled.div`
  display: flex;
  align-items: center;
  width:100%;
  @media (max-width: 800px) {
    display:grid;
    grid-template-columns:1fr 12fr;
    grid-row-gap:10px;
  }
`;
const DividerLine = styled.hr`
  border: 0.5px solid #ebebeb;
  margin: 30px 0;
`;
const EnglishTestWrapper = styled.div`
  margin-bottom: 10px;
`;

const InternWrapper = styled(FlexWrapper)`
  > div {
    margin-right: 20px;
  }
`;
const ButtonWrapper = styled(FlexWrapper)`
  justify-content: space-between;
  margin-top: 40px;
  @media (max-width: 600px) {
    flex-direction: column;
  }
  @media (max-width: 1024px) {
    justify-content: space-between;
  }
`;
const SaveButton = styled.p`
  font-weight: 500;
  font-size: 18px;
  line-height: 24px;
  text-align: center;
  text-decoration-line: underline;
  color: #504f8c;
  margin: 0;
  cursor: pointer;
  text-align: left;
  margin-left: 35px;
  @media (max-width: 900px) {
    text-align: center;
  }
  @media (max-width: 800px) {
    margin-left:0;
  }
`;
// styling ends

const schema = yup
  .object({
    scholar_status: yup.string().required(),
    curr_employer: yup.string().required(),
  })
  .required();

export default function StudingPage() {
  const formValues = useSelector((state) => state.selfIntroOne.value);
  const value = formValues.talent_status;
  const options = useSelector((state) => state.selfIntroOne.formOptions);
  const [formErrors, setFormErrors] = useState("");
  const [loading, setLoading] = useState(false);
  const [gradeInput, setGradeInput] = useState(false);
  const [options1, setOptions1] = useState({});

  const [nMcountry, setNMcountry] = useState([]);
  const [selectedCountry, setSelectedCountry] = useState([]);
  const [institutionList, setinstitutionList] = useState([]);
  const [selectedInstitution, setSelectedInstitution] = useState([]);
  const [sectors, setSectors] = useState([]);
  const [selectedSector, setSelectedSector] = useState([]);

  const [employFormErrors, setEmployFormErrors] = useState(false);
  const [highQualification, setHighQualification] = useState([]);
  const [selectedHighqualification, setSelectedHighqualification] = useState(
    []
  );

  const [programmeList, setProgrammeList] = useState([]);
  const [selectedProgrammee, setSelectedProgrammee] = useState([]);

  const [scope_of_studies, setScope_of_studies] = useState([]);
  const [selectedScopeOfStudies, setSelectedScopeOfStudies] = useState([]);

  const [grades, setGrade] = useState([]);
  const [selectedGrade, setSelectedGrade] = useState([]);
  const [englishScore, setEnglishScore] = useState();
  const [min, setMin] = useState();
  const [max, setMax] = useState();
  const [thisIELTSMin, setThisIELTSMin] = useState(0.1);
  const [thisIELTSMax, setThisIELTSMax] = useState(10.0);
  const [thisCgpaMin, setThisCgpaMin] = useState(0.1);
  const [thisCgpaMax, setThisCgpaMax] = useState(10.0);

  const [instiCountryDisable, setInstiCountryDisable] = useState(true);

  const dispatch = useDispatch();
  const navigate = useNavigate();
  const {
    control,
    setError,
    handleSubmit,
    setValue,
    resetField,
    watch,
    formState: { errors },
  } = useForm({
    defaultValues: { ...formValues },
    context: { instiCountryDisable },
    resolver: yupResolver(
      value === "employed" ? employeeSchema : unEmployeeSchema
    ),
  });
  const formVal = watch();
  useEffect(() => {
    if (!window.allow_user_continue) navigate("/self-intro");
  }, [window.allow_user_continue]);

  const onErrorSubmit = () => {
    
    console.log("ErrorSubmit",formVal);
    setEmployFormErrors(true);
  };

  useEffect(() => {
    if (formVal.insti_country_status === 1) {
      setInstiCountryDisable(true);
    } else {
      setInstiCountryDisable(false);
    }
  }, []);

  const onSubmit = async () => {
    console.log("this calling",formVal);

    setLoading(true);
    if (formVal.insti_country === undefined) {
      formVal.insti_country = "";
    }
    const { data } = await onBoardStepTwoForm({
      ...formVal,
      talent_status: formValues.talent_status,
    });
    console.log("validData",data);
    if (data && data.status === 1) {
      dispatch(updateValues({ ...formValues, ...formVal }));
      setLoading(false);
      setFormErrors("");
      setEmployFormErrors(false);
      window.allow_user_continue = true;
      navigate("/uploadpic");
    } else {
      setLoading(false);
      setFormErrors(data && data.message);
    }
  };

  const radioChange = async (value, name) => {
    setValue(name, value);
    switch (name) {
      case "english_tests":
        setValue("english_score", "");
        setEnglishScore("");
        if (value === "TOEFL") {
          setMin(0);
          setMax(30);
        } else if (value === "IELTS") {
          setMin(0);
          setMax(9);
        } else {
          setMin(null);
          setMax(null);
        }
        if (value === "I have not taken any tests") {
          setValue("englishscore", "I have not taken any tests");
          setValue("english_score", "I have not taken any tests")
        }
        break;
        case "grade_status":
          setValue("grade", "");
          if (value === "Grades") {
            setGradeInput(true);
          } else {
            setGradeInput(false);
          }
          if (value === "No Current Grade") {
            setValue("grade_abbr", "No Current Grade");
            setValue("grade", "No Current Grade")
          }
          break;
      case "insti_country_status":
        setValue("insti_country", "");
        setValue("insti_name", "");
        setValue("insti_name", "");
        setValue("insti_country_abbr", "");
        setValue("university","");
        setValue("insti_name_abbr", "");
        setSelectedCountry("");
        setSelectedInstitution("");
        if (value == 1) {
          setInstiCountryDisable(true);
        } else {
          setInstiCountryDisable(false);
        }

        break;
      case "curr_tc_program":
        setValue("programmee", "");
        setValue("curr_tc_program_abbr", "");
        setSelectedProgrammee("");
        break;

      default:
        break;
    }
    setValue(name, value);
    let Key_term = value;
  };

  const dropdownChange = async (name, val) => {
    setValue(name, val.value);
    switch (name) {
      case "high_qualification":
        setSelectedHighqualification(val);
        setValue("high_qualification_abbr", val.label);
        dispatch(
          updateValues({
            ...formValues,
            ...formVal,
            [name]: val.value,
          })
        );
        break;
      case "scope":
        setSelectedScopeOfStudies(val);
        setValue("scope_abbr", val.label);
        dispatch(
          updateValues({
            ...formValues,
            ...formVal,
            [name]: val.value,
          })
        );
        break;

      case "grade":
        setSelectedGrade(val);
        setValue("grade_abbr", val.label);
        dispatch(
          updateValues({
            ...formValues,
            ...formVal,
            [name]: val.value,
          })
        );
        break;

      case "programmee":
        setSelectedProgrammee(val);
        setValue("curr_tc_program_abbr", val.label);
        dispatch(
          updateValues({
            ...formValues,
            ...formVal,
            [name]: val.value,
          })
        );
        break;

      case "insti_name":
        setSelectedInstitution(val);
        setValue("insti_name_abbr", val.label);
        dispatch(
          updateValues({
            ...formValues,
            ...formVal,
            [name]: val.value,
          })
        );
        break;

      case "insti_country":
        setSelectedCountry(val);
        setValue("insti_country_abbr", val.label);
        dispatch(
          updateValues({
            ...formValues,
            ...formVal,
            [name]: val.value,
          })
        );
        break;

      case "sector":
        setSelectedSector(val);
        setValue("sector_abbr", val.label);
        dispatch(
          updateValues({
            ...formValues,
            ...formVal,
            [name]: val.value,
          })
        );
        break;
      default:
    }
  };

  const getOptions = async (name) => {
    let key_term = name;
    const { data, status } = await getOption({ search_key: "" }, key_term);
    if (data.data.length !== 0) {
      let newData = data.data.map((x) => {
        return {
          label: x,
          value: x,
        };
      });
      let obj = { ...options1 };
      obj[name] = newData;
      setOptions1(obj);
    } else {
      return null;
    }
  };

  const limitMaxMonth = (val) => {
    if (Number(val) > 12) {
      setValue("dur_month", "");
      setError("dur_month", {
        type: "manual",
        message: "Month does not exceed 12",
      });
    } else {
      setValue("dur_month", val);
      setError("dur_month", {
        type: "manual",
        message: "",
      });
    }
  };

  const onNavigate = (path) => {
    navigate(path);
  };

  const onSave = async () => {
    setLoading(true);
    const req = removeKeys({ ...formVal });
    const { data } = await onBoardFormSubmission({
      ...req,
      step: formValues.profile_picture ? 4 : 3,
      // step: 3
    });
    if (data && data.status === 1) {
      dispatch(updateValues({ ...formValues, ...formVal }));
      navigate("/dashboard");
      setLoading(false);
      setFormErrors("");
    } else {
      setLoading(false);
      setFormErrors(data && data.message);
    }
  };
  const getNonMalaysian = async () => {
    // const { data, status } = await getCurrentCountries(
    const { data, status } = await getAllCountries(
      {
        search_key: "",
      },
      { key_term: "country", hint: "nm", loc: "loc" }
    );
    if (status === 200) {
      setNonMalaysian(data);
    }
  };

  const setNonMalaysian = (data) => {
    if (data.status === 1) {
      const location = data.data;
      const country = location.map((x) => {
        return { ...x, label: x.Name, value: x.Id };
      });
      setNMcountry(country);

      // Edit mode
      if (formVal.insti_country) {
        let selCountry = country.filter(
          (x) => x.value == formVal.insti_country
        );
        if (selCountry.length > 0) {
          selCountry = selCountry[0];
          setValue("insti_country", selCountry.value);
        }
        setSelectedCountry(selCountry);
      }
    }
  };

  const getInstitution = async () => {
    const { data, status } = await getUniversity();
    if (status === 200) {
      setInstitutionDatas(data);
    }
  };
  const setInstitutionDatas = (data) => {
    if (data.status === 1) {
      const institutionData = data.data;
      const institution = institutionData.map((x) => {
        return { ...x, label: x.Name, value: x.Id };
      });
      setinstitutionList(institution);

      // Edit mode
      if (formVal.insti_name) {
        let selInstitution = institution.filter(
          (x) => x.value == formVal.insti_name
        );
        if (selInstitution.length > 0) {
          selInstitution = selInstitution[0];
          setValue("insti_name", selInstitution.value);
          // setInstiCountryDisable(true);
        }
        setSelectedInstitution(selInstitution);
      }
    }
  };

  // fetching high_qualification data's
  const fetchHighQualification = async () => {
    const { data, status } = await getAcademicQualifications();
    if (status === 200) {
      const qualifications = data.data;
      const highestqualification = qualifications.map((x) => {
        return { ...x, label: x.Name, value: x.Id };
      });
      setHighQualification(highestqualification);

      // Edit mode
      if (formVal.high_qualification) {
        let selHighqualification = highestqualification.filter(
          (x) => x.value == formVal.high_qualification
        );
        if (selHighqualification.length > 0) {
          selHighqualification = selHighqualification[0];
          setValue("high_qualification", selHighqualification.value);
        }
        setSelectedHighqualification(selHighqualification);
      }
    }
  };

  // fetching sectors
  const fetchSector = async () => {
    const { data, status } = await getSector();
    if (status === 200) {
      const sectors = data.data;
      const sector = sectors.map((x) => {
        return { ...x, label: x.Name, value: x.Id };
      });
      setSectors(sector);

      // Edit mode
      if (formVal.sector) {
        let selSector = sector.filter((x) => x.value == formVal.sector);
        if (selSector.length > 0) {
          selSector = selSector[0];
          setValue("sector", selSector.value);
          setValue("sector_abbr", selSector.label);
        }
        setSelectedSector(selSector);
      }
    }
  };

  // get scope of studies
  const fetchScopeOfStudies = async () => {
    const { data, status } = await getScopeOfStudies();
    if (status === 200) {
      const Scopes = data.data;
      const Scope_Of_Studies = Scopes.map((x) => {
        return { ...x, label: x.Name, value: x.Id };
      });
      setScope_of_studies(Scope_Of_Studies);
      if (formVal.scope) {
        let scopeOfStudies = Scope_Of_Studies.filter(
          (x) => x.value == formVal.scope
        );
        if (scopeOfStudies.length > 0) {
          scopeOfStudies = scopeOfStudies[0];
        }
        setSelectedScopeOfStudies(scopeOfStudies);
      }
    }
  };

  const fetchGrade = async () => {
    const { data, status } = await getGrades();
    if (status === 200) {
      const gradesList = data.data;
      const grades = gradesList.map((x) => {
        return { ...x, label: x.Title, value: x.Id };
      });
      setGrade(grades);

      // Edit mode
      if (formVal.grade) {
        let selGrade = grades.filter((x) => x.value == formVal.grade);
        if (selGrade.length > 0) {
          selGrade = selGrade[0];
          setValue("grade", selGrade.value);
          setValue("grade_abbr", selGrade.label);
        }
        setSelectedGrade(selGrade);
      }
    }
  };

  const fetchProgrameTypes = async () => {
    const { data, status } = await getProgrameTypes();
    if (status === 200) {
      const programeeList = data.data;
      const programme = programeeList.map((x) => {
        return { ...x, label: x.Title, value: x.Id };
      });
      setProgrammeList(programme);

      // Edit mode
      if (formVal.programmee) {
        let selPrograme = programme.filter(
          (x) => x.value == formVal.programmee
        );
        if (selPrograme.length > 0) {
          selPrograme = selPrograme[0];
          setValue("programmee", selPrograme.value);
        }
        setSelectedProgrammee(selPrograme);
      }
    }
  };

  useEffect(() => {
    getNonMalaysian();
    getInstitution();
    fetchHighQualification();
    fetchScopeOfStudies();
    fetchGrade();
    fetchSector();
    fetchProgrameTypes();
  }, []);

  const setNameOfInstitution = (val) => {
    setValue("insti_name_abbr", val);
    setValue("insti_name", val);
  };

  const setEnglishValue = (val) => {
    setValue("english_score", val);
    setValue("englishscore", val);
  };

  const setGradeValue = (val) => {
    setValue("grade", val);
    setValue("grade_abbr", val);
  };

  const { t } = useTranslation();

  return (
    <UserCreationLayout
      sidebarImg={value == "unemployed" ? unEmployeeCover : employecover}
    >
      <IntroSection
        name={t(employeeStaticContent.title)}
        description={t(employeeStaticContent.description)}
      />
      <PaginationDivider step={2} />
      <FormWrapper>
        {value === "unemployed" && (
          <>
            <Row>
              <FormGroup>
                <LabelText>{t(employeeStaticContent.tcProgram)}</LabelText>
                <Row>
                  <Col style={{ paddingLeft: "0px" }}>
                    <ScholarshipWrapper>
                      <Controller
                        name="curr_tc_program"
                        control={control}
                        render={({ field }) =>
                          employeeStaticContent.programs.map(
                            (identity, index) => (
                              <RadioInput
                                key={identity.label + index}
                                {...field}
                                checked={
                                  identity.value === formVal.curr_tc_program
                                }
                                onChange={() =>
                                  radioChange(identity.value, "curr_tc_program")
                                }
                                label={t(identity.label)}
                                margin="20px"
                                name="curr_tc_program"
                              />
                            )
                          )
                        }
                      />
                    </ScholarshipWrapper>
                  </Col>
                </Row>

                <Row>
                  <Col style={{ padding: "0px" }}>
                    <Controller
                      name="programmee"
                      control={control}
                      render={({ field }) => (
                        <Dropdown
                          search={true}
                          options={programmeList}
                          placeholder={t("Select Programme")}
                          change={(val) => dropdownChange("programmee", val)}
                          {...field}
                          val={selectedProgrammee.value}
                          label={selectedProgrammee.label}
                        disable={formVal.curr_tc_program === 0}
                          refCallback={field?.ref} 
                          ref={null}
                        />
                      )}
                    />

                    {errors.programmee && (
                      <ErrorField err={t(errors.programmee.message)} />
                    )}
                  </Col>
                </Row>

                {errors.curr_tc_program && (
                  <ErrorField err={t(errorMessages.curr_tc_program)} />
                )}
              </FormGroup>
            </Row>
            <Row>
              <Col>
                <FormGroup>
                  <LabelText>{t(employeeStaticContent.lastEmployee)}</LabelText>
                  <Controller
                    name="last_employer"
                    control={control}
                    render={({ field }) => (
                      <Input placeholder={t("Name of last employer")} {...field} />
                    )}
                  />
                  {errors.last_employer && (
                    <ErrorField err={t(errors.last_employer.message)} />
                  )}
                </FormGroup>
              </Col>
            </Row>

            <DividerLine />
          </>
        )}
        {value === "employed" && (
          <>
            <Row>
              <Col>
                <FormGroup>
                  <LabelText>{t(employeeStaticContent.employeeTitle)}</LabelText>
                  <Controller
                    name="curr_employer"
                    control={control}
                    rules={{
                      maxLength: 4,
                    }}
                    render={({ field }) => (
                      <Input
                        placeholder={t("Max 100 characters")}
                        {...field}
                        maxLength="100"
                      />
                    )}
                  />
                  {errors.curr_employer && (
                    <ErrorField err={t(errors.curr_employer.message)} />
                  )}
                </FormGroup>
              </Col>
            </Row>
            <Row>
              <Col>
                <FormGroup>
                  <LabelText>{t(employeeStaticContent.sectorTitle)}</LabelText>
                  <Controller
                    name="sector"
                    control={control}
                    render={({ field }) => (
                      <Dropdown
                        search={true}
                        options={sectors}
                        placeholder={t("Select")}
                        val={selectedSector.value}
                        label={selectedSector.label}
                        // disable={formVal.sector ? false : true}
                        change={(val) => dropdownChange("sector", val)}
                        {...field}
                           refCallback={field?.ref} 
                          ref={null}
                      />
                    )}
                  />
                  {errors.sector && <ErrorField err={t(errors.sector.message)} />}
                </FormGroup>
              </Col>
            </Row>

            <Row>
              <LabelText>{t(employeeStaticContent.workDuration)}</LabelText>
              <Col xs="12" lg="6" md="6" xl="4">
                <FormGroup>
                  <Controller
                    name="dur_year"
                    control={control}
                    render={({ field }) => (
                      <Input type="number" placeholder={t("Years")} {...field}
                      onKeyPress={(event) => {
                        if (!/[0-9]/.test(event.key)) {
                          event.preventDefault();
                        }
                        if (event.target.value.length > 2) {
                          event.preventDefault();
                        }
                      }}
                        min={0}
                        max={100} />
                    )}
                  />
                  {errors.dur_year && (
                    <ErrorField err={t(errors.dur_year.message)} />
                  )}
                </FormGroup>
              </Col>
              <Col xs="12" lg="6" md="6" xl="4">
                <FormGroup>
                  <Controller
                    name="dur_month"
                    control={control}
                    render={({ field }) => (
                      <Input
                        type="number"
                        placeholder={t("Months")}
                        {...field}
                        onChange={(e) => limitMaxMonth(e.target.value)}
                        onKeyPress={(event) => {
                          if (!/[0-9]/.test(event.key)) {
                            event.preventDefault();
                          }
                          if (event.target.value.length > 1) {
                            event.preventDefault();
                          }
                        }}
                        min={1}
                        max={12}
                      />
                    )}
                  />
                  {errors.dur_month && (
                    <ErrorField err={t(errors.dur_month.message)} />
                  )}
                </FormGroup>
              </Col>
            </Row>
            <Row>
              <Col>
                <FormGroup>
                  <LabelText>{t(employeeStaticContent.job)}</LabelText>
                  <Controller
                    name="position"
                    control={control}
                    render={({ field }) => (
                      <Input
                        type="text"
                        placeholder={t("Position/Job title")}
                        {...field}
                        maxLength="100"
                      />
                    )}
                  />
                  {errors.position && (
                    <ErrorField err={t(errors.position.message)} />
                  )}
                </FormGroup>
              </Col>
            </Row>
            <Row>
              <Col>
                <FormGroup>
                  <LabelText>{t(employeeStaticContent.allowance)}</LabelText>
                  <Controller
                    name="salary"
                    control={control}
                    render={({ field }) => (
                      <Input
                        type="number"
                        placeholder={t("Salary (RM per month before tax)")}
                        {...field}
                      />
                    )}
                  />
                  {errors.salary && <ErrorField err={t(errors.salary.message)} />}
                </FormGroup>
              </Col>
            </Row>
          </>
        )}
        <Row>
          <Col>
            <FormGroup>
              <LabelText>{t(employeeStaticContent.academicTitle)}</LabelText>
              <Controller
                name="high_qualification"
                control={control}
                render={({ field }) => (
                  <Dropdown
                    search={true}
                    placeholder={t("Select")}
                    options={highQualification}
                    change={(val) => dropdownChange("high_qualification", val)}
                    val={selectedHighqualification.value}
                    label={selectedHighqualification.label}
                    // disable={formVal.high_qualification ? false : true}
                    {...field}
                    refCallback={field?.ref} 
                    ref={null}
                  />
                )}
              />
              {errors.high_qualification && (
                <ErrorField err={t(errors.high_qualification.message)} />
              )}
            </FormGroup>
          </Col>
        </Row>

        <FormGroup>
          <Row>
            <Col>
              <LabelText>
                {t(employeeStaticContent.highestQualification)}
              </LabelText>
              <ScholarshipWrapper>
                <Controller
                  name="insti_country_status"
                  control={control}
                  render={({ field }) =>
                    employeeStaticContent.countries.map((identity, index) => (
                      <RadioInput
                        key={identity.label + index}
                        {...field}
                        checked={
                          identity.value === formVal.insti_country_status
                        }
                        onChange={() =>
                          radioChange(identity.value, "insti_country_status")
                        }
                        label={t(identity.label)}
                        name="insti_country_status"
                        margin="20px"
                      />
                    ))
                  }
                />
              </ScholarshipWrapper>
            </Col>
          </Row>
        </FormGroup>
          {formVal.insti_country_status === 0 && (
          <> {/* study abroad: show insti_country and university input */}
            <Row>
              <Col>
                <FormGroup>
                  <Controller
                  name="insti_country"
                  control={control}
                  render={({ field }) => (
                    <Dropdown
                      search={true}
                      options={nMcountry}
                      placeholder={t("If Other Countries, select country")}
                      val={selectedCountry.value}
                      label={selectedCountry.label}
                      disable={instiCountryDisable}
                      change={(val) => dropdownChange("insti_country", val)}
                      {...field}
                      refCallback={field?.ref} 
                      ref={null}
                    />
                  )}
                />

                {errors.insti_country && (
                  <ErrorField err={t(errors.insti_country.message)} />
                )}
                </FormGroup>
              </Col>
            </Row>
            <Row>
              <Col>
                <FormGroup>
                    <Controller
                      name="university"
                      control={control}
                      render={({ field }) => (
                        <Input
                          placeholder={t("University")}
                          {...field}
                          value={formVal.university}
                        />
                      )}
                    />
                  {errors.university && (
                    <ErrorField err={t(errors.university.message)} />
                  )}
                </FormGroup>
              </Col>
            </Row>
          </>
        )}

        {formVal.insti_country_status === 1 && (
          <> {/* study locally: show insti_name dropdown */}
            <Row>
              <Col>
                <FormGroup>
                  <Controller
                    name="insti_name"
                    control={control}
                    render={({ field }) => (
                      <Dropdown
                          search={true}
                          options={institutionList}
                          placeholder={t("Select Name of Institution")}
                          change={(val) => dropdownChange("insti_name", val)}
                          val={selectedInstitution.value}
                          label={selectedInstitution.label}
                          {...field}
                          refCallback={field?.ref} 
                          ref={null}
                        />
                    )}
                  />
                  {errors.insti_name && (
                    <ErrorField err={t(errors.insti_name.message)} />
                  )}
                </FormGroup>
              </Col>
            </Row>
          </>
          )}

        <Row>
          <Col>
            <FormGroup>
              <LabelText>{t(employeeStaticContent.scope)}</LabelText>
              <Controller
                name="scope"
                control={control}
                render={({ field }) => (
                  <Dropdown
                    search={true}
                    options={scope_of_studies}
                    placeholder={t("Please select")}
                    change={(val) => dropdownChange("scope", val)}
                    val={selectedScopeOfStudies.value}
                    label={selectedScopeOfStudies.label}
                    disabled={formVal.scope ? false : true}
                    {...field}
                    refCallback={field?.ref} 
                    ref={null}
                  />
                )}
              />
              {errors.scope && <ErrorField err={t(errors.scope.message)} />}
            </FormGroup>
          </Col>
        </Row>

        <DividerLine />
        <Row>
          <Col>
          <FormGroup>
              <LabelText>{t(employeeStaticContent.gradeTitle)}</LabelText>
              <GradeWrapper>
                <Controller
                  name="grade_status"
                  control={control}
                  render={({ field }) =>
                    employeeStaticContent.grades.map((grade, index) => (
                      <RadioInput
                        key={grade + index}
                        {...field}
                        checked={grade === formVal.grade_status}
                        onChange={() => radioChange(grade, "grade_status")}
                        label={t(grade)}
                        margin="20px"
                      />
                    ))
                  }
                />
              </GradeWrapper>
            </FormGroup>
          </Col>
        </Row>

        <Row>
          <Col>
            <FormGroup>
              <Controller
                name="grade"
                control={control}
                render={({ field }) => (
                  <>
                    {gradeInput || formVal.grade_status == "Grades" ? (
                      <Dropdown
                        search={true}
                        options={grades}
                        placeholder={t("Please select a grade")}
                        change={(val) => dropdownChange("grade", val)}
                        val={selectedGrade?.value}
                        label={selectedGrade?.label}
                        disabled={formVal.grade ? false : true}
                        {...field}
                        refCallback={field?.ref} 
                        ref={null}
                      />
                    ) : formVal.grade_status === "CGPA" ? (
                      <Input
                        placeholder={t("Please enter CGPA")}
                        type="number"
                        onKeyPress={(event) => {
                          if (!/[0-9.]/.test(event.key)) {
                            event.preventDefault();
                          }
                          if (event.target.value.length > 3) {
                            event.preventDefault();
                          }
                        }}
                        min={thisCgpaMin}
                        max={thisCgpaMax}
                        step={thisCgpaMin}
                        value={formVal.grade}
                        onChange={(e) => setGradeValue(e.target.value)}
                      />
                    ) : formVal.grade_status === "Others" ? (
                      <Input
                        placeholder={t("Please specify")}
                        type="text"
                        value={formVal.grade}
                        onChange={(e) => setGradeValue(e.target.value)}
                      />
                    ) : formVal.grade_status === "No Current Grade" ? (
                      <Input
                        placeholder="No current grade"
                        type="hidden"
                        value="No current grade"
                        onChange={(e) => setGradeValue(e.target.value)}
                      />
                    ) : (
                      <Input
                        placeholder={t("Please enter")}
                        type="number"
                        onKeyPress={(event) => {
                          if (!/[0-9.]/.test(event.key)) {
                            event.preventDefault();
                          }
                          if (event.target.value.length > 3) {
                            event.preventDefault();
                          }
                        }}
                        min={thisCgpaMin}
                        max={thisCgpaMax}
                        step={thisCgpaMin}
                        value={formVal.grade}
                        onChange={(e) => setGradeValue(e.target.value)}
                      />
                    )}
                  </>
                )}
              />
              {errors.grade && <ErrorField err={t(errors.grade.message)} />}
            </FormGroup>
          </Col>
        </Row>

        <FormGroup>
          <Row>
            <Col>
            <FormGroup>
              <LabelText>{t(employeeStaticContent.englishTestTitle)}</LabelText>
              <Controller
                name="english_tests"
                control={control}
                render={({ field }) =>
                  employeeStaticContent.tests.map((test, index) => (
                    <EnglishTestWrapper>
                      <RadioInput
                        key={test + index}
                        {...field}
                        checked={test === formVal.english_tests}
                        onChange={() => radioChange(test, "english_tests")}
                        label={t(test)}
                      />
                    </EnglishTestWrapper>
                  ))
                }
              />
              {errors.english_tests && (
                <ErrorField err={t(errors.english_tests.message)} />
              )}
            </FormGroup>
            <FormGroup>
              <Controller
                name="english_score"
                control={control}
                render={({ field }) => (
                  <>
                    {formVal.english_tests === "CEFR" ||
                      formVal.english_tests === "MUET" ? (
                      <Dropdown
                        options={
                          formVal.english_tests === "CEFR"
                            ? options1
                              ? options1.CEFR
                              : null
                            : options1
                              ? options1.MUET
                              : null
                        }
                        placeholder={t("Select")}
                        onFocus={() => {
                          getOptions(formVal.english_tests);
                        }}
                        val={
                          formVal.english_score
                            ? formVal.english_score
                            : englishScore
                        }
                        label={
                          formVal.english_score
                            ? formVal.english_score
                            : englishScore
                        }
                        change={(val) => dropdownChange("english_score", val)}
                        {...field}
                        refCallback={field?.ref} 
                        ref={null}
                      />
                      
                    ) : formVal.english_tests === "IELTS" ? (
                      
                      <Input
                        placeholder={t("Please enter IELTS score")}
                        type="number"
                        onKeyPress={(event) => {
                          if (!/[0-9.]/.test(event.key)) {
                            event.preventDefault();
                          }
                          if (event.target.value.length > 3) {
                            event.preventDefault();
                          }
                        }}
                        min={thisIELTSMin}
                        max={thisIELTSMax}
                        step={thisIELTSMin}
                        value={formVal.english_score}
                        onChange={(e) => setEnglishValue(e.target.value)}
                      />
                    ) : formVal.english_tests === "Others" ? (
                      
                      <Input
                        placeholder={t("Please specify")}
                        type="text"
                        value={formVal.english_score}
                        onChange={(e) => setEnglishValue(e.target.value)}
                      />
                    ) : formVal.english_tests === "I have not taken any tests" ?  (
                      <Input
                        placeholder="I have not taken any tests"
                        type="hidden"
                        value="I have not taken any tests"
                        onChange={(e) => setEnglishValue(e.target.value)}
                      />
                    ) : (
                      <Input
                        placeholder=""
                        {...field}
                        onKeyPress={(event) => {
                          if (!/[0-9]/.test(event.key)) {
                            event.preventDefault();
                          }
                        }}
                        min={min}
                        max={max}
                        type="number"
                      />
                    )}
                  </>
                )}
              />

              {errors.english_score && (
                <ErrorField err={t(errors.english_score.message)} />
              )}
            </FormGroup>
            </Col>
          </Row>
        </FormGroup>
        {loading ? (
          <Loader />
        ) : (
          <>
            <ButtonWrapper>
              <Button
                width="198px"
                onClick={handleSubmit(onSubmit, onErrorSubmit)}
                name={t("Continue")}
              />
              <Button
                width="198px"
                onClick={() => onNavigate("/aboutme")}
                secondary={true}
                name={t("Back")}
              />
            </ButtonWrapper>
            {formValues.Profile_picture === "" ? (
              ""
            ) : (
              <SaveButton onClick={handleSubmit(onSave)}>
                {t("Save and Exit")}
              </SaveButton>
            )}
          </>
        )}
        {employFormErrors && (
          <ErrorField
            err={t("Please make sure all the mandatory fields have been inserted")}
          />
        )}
      </FormWrapper>
    </UserCreationLayout>
  );
}

import { useState, useEffect } from "react";
import styled from "styled-components";
import { useNavigate, NavLink, useLocation } from "react-router-dom";
// import { Link, NavLink } from 'react-router-dom'
import { Colors } from "../../utilities/colors";
import { menus } from "../../menuItem";
import Logo from "../../assets/icons/svg/mynextLogo.svg";
import SidebarSallyIcon from "../../assets/sidebar.png";
import ProfileCompletionBar from "../profileCompletionBar/profileCompletionBar";
import { fetchPercentage } from "../../Redux/Reducers/ProfileReducer/ProfileReducer";
import { useDispatch, useSelector } from "react-redux";
import ReactTooltip from "react-tooltip";
import { useTranslation } from "react-i18next";
import { deviceStore } from "../../utilities/devices";
import { companyProxy } from "../../pages/dashboard/companyproxy";
import { useSnapshot } from "valtio";

const SidebarWrapper = styled.nav`
  min-width: 346px;
  box-sizing: border-box;
  position: relative;
`;

const MenuDiv = styled.div`
  width: 100%;
  height: 10%;
  margin: 0px;
  display: flex;
  flex-direction: column;
  padding: 1rem 0;
`;
const MenuList = styled(NavLink)`
  padding: 10px 0px;
  font-family: "General Sans";
  font-style: normal;
  font-weight: 500;
  font-size: 18px;
  line-height: 24px;
  letter-spacing: 1px;
  display: flex;
  align-items: center;
  color: #acacac;
  margin-left: 5rem;
  text-decoration: none;
  &:hover {
    color: ${Colors.pink};
    border-right: 5px solid ${Colors.pink};
  }

  &.active {
    color: ${Colors.pink};
    border-right: 5px solid ${Colors.pink};
  }
`;

const MenuList1 = styled(NavLink)`
  padding: 10px 0px;
  font-family: "General Sans";
  font-style: normal;
  font-weight: 500;
  font-size: 18px;
  line-height: 24px;
  letter-spacing: 1px;
  display: flex;
  align-items: center;
  color: #acacac;
  text-decoration: none;
  &:hover {
    color: ${Colors.pink};
    border-bottom: 5px solid ${Colors.pink};
  }

  &.active {
    color: ${Colors.pink};
    border-bottom: 5px solid ${Colors.pink};
  }
`;
const MenuDisabledList = styled.div`
  padding: 10px 0px;
  font-family: "General Sans";
  font-style: normal;
  font-weight: 500;
  font-size: 18px;
  line-height: 24px;
  letter-spacing: 1px;
  display: flex;
  align-items: center;
  color: #acacac;
  margin-left: 5rem;
  text-decoration: none;
`;
const MenuDisabledList1 = styled.div`
  padding: 10px 0px;
  font-family: "General Sans";
  font-style: normal;
  font-weight: 500;
  font-size: 18px;
  line-height: 24px;
  letter-spacing: 1px;
  display: flex;
  align-items: center;
  color: #acacac;
  text-decoration: none;
`;
const MenuSpanText = styled.p`
  width: 150px;
  font-weight: 500;
  font-size: 18px;
  line-height: 24px;
  margin-left: 20px;
  margin-top: unset;
`;
const MenuSpanText1 = styled.p`
  font-weight: 500;
  font-size: 14px;
  line-height: 24px;
  margin:0;
`;
const MenuSpanIcons = styled.span`
  width: auto;
  margin-bottom: 1rem;
  > img {
    width: 30px;
    height: 30px;
  }
`;
const SallyImg = styled.img`
  position: absolute;
  left: 40px;
  width: 57%;
  z-index: 0;
`;

const SideNavHeaderImage = styled.div`
  background-image: url(${Logo});
  background-repeat: no-repeat;
  width: 202px;
  height: 66px;
  margin: 66px 104px 10px 70px;
  position: relative;
  cursor: pointer;
`;

const SideNavHeaderImageSubTitle = styled.span`
  color: #d04d8b;
  position: absolute;
  top: 4.5vh;
  right: 2rem;
`;

const SidebarSally = styled.div`
  position: relative;
`;

export default function Sidebar() {
  useSnapshot(deviceStore)
  const checkbrowser = deviceStore.checkbrowser
  useSnapshot(companyProxy)
  const dispatch = useDispatch();
  const navigate = useNavigate();
  const location = useLocation();

  const percent = useSelector((state) => {
    return state.profile.profilePercentage;
  });

  const [activeLink, setActiveLink] = useState("");

  const InternshipsMessage =
    "Please complete About Me, Personality & Motivation, <br/>Work Interests, and English Proficiency Assessments<br/>to use this feature";
  //
  const onNavigate = () => {
    navigate({ pathname: "/dashboard" });
  };

  const getActiveLocation = () => {
    setActiveLink(location.pathname);
  };

  useEffect(() => {
    getActiveLocation();
    ReactTooltip.rebuild();
  }, []);

  useEffect(async () => {
    dispatch(fetchPercentage());
    /*var dataValue = await getTalentUserData();
    temp_array.push(dataValue.data.data.additional_info.percentage)
    const { data, status } = await getAssesmentList();
    data.data.map((comp) => (
      temp_array.push(parseInt(comp.completed))
    ))
    const percent3 = temp_array.reduce((partialSum, a) => partialSum + a, 0) / 8;
    setPercent(percent3)*/
  }, []);

  const { t } = useTranslation();

  return (
    <>
    {
      (checkbrowser)?
      <SidebarWrapper>
      <div>
        <SideNavHeaderImage onClick={onNavigate}>
          <SideNavHeaderImageSubTitle>
            <h5>TALENT</h5>
          </SideNavHeaderImageSubTitle>
        </SideNavHeaderImage>
      </div>

      <MenuDiv>
        {menus.map((menuItem, i) => (
          <>
          {
           (companyProxy["showcompanysidemenu"] && menuItem.name==="MyCompany")?<MenuList key={i} to={menuItem.path}>
           <MenuSpanIcons>{menuItem.icon}</MenuSpanIcons>
           <MenuSpanText>{t(menuItem.name)}</MenuSpanText>
           </MenuList>:<>
           {
            (menuItem.name!="MyCompany")?<>
          {(percent >= menuItem.condition) ?  /* add "1" instead of "menuItem.condition" to skip internship exams*/
        
        
         
          
            <MenuList key={i} to={menuItem.path}>
              <MenuSpanIcons>{menuItem.icon}</MenuSpanIcons>
              <MenuSpanText>{t(menuItem.name)}</MenuSpanText>
            </MenuList>
            
            : <MenuDisabledList key={i} data-tip={t(InternshipsMessage)} data-html={true} data-place="right" >
              <MenuSpanIcons>{menuItem.icon}</MenuSpanIcons>
              <MenuSpanText>{t(menuItem.name)}</MenuSpanText>
            </MenuDisabledList>
            }
            </>:""

        }
           </>
        }
        
        
          </>
          

        ))}
      </MenuDiv>

      <SidebarSally>
        <SallyImg src={SidebarSallyIcon} alt="sally" />
        <ProfileCompletionBar />
      </SidebarSally>
    </SidebarWrapper>:<>
    <div class="position-relative  bg-white w-100 ">
    <div class="position-fixed bottom-0 start-0  bg-white  w-100 rounded-top">

  
    
    <MenuDiv>
      <div class="row  bg-white">
      {menus.map((menuItem, i) => (
          percent >= menuItem.condition ?  /* add "1" instead of "menuItem.condition" to skip internship exams*/
            (<div class="col-3"><MenuList1 key={i} to={menuItem.path}>
              <div className="row  text-center">
              <div className="col-12 p-0 m-0"><MenuSpanIcons>{menuItem.icon}</MenuSpanIcons></div>
              {/* <div className="col-12 p-0 m-0"><MenuSpanText1>{menuItem.name}</MenuSpanText1></div> */}
              </div>
              
              
            </MenuList1></div>)
            : (<div class="col-3"><MenuDisabledList1 key={i} data-tip={t(InternshipsMessage)} data-html={true} data-place="right" >
              <div className="row text-center">
              <div className="col-12 p-0 m-0"><MenuSpanIcons>{menuItem.icon}</MenuSpanIcons></div>
              {/* <div className="col-12 p-0 m-0"><MenuSpanText1>{menuItem.name}</MenuSpanText1></div> */}
              </div>
            </MenuDisabledList1></div>)

        ))}
        
      </div>
      </MenuDiv>
      </div>
    </div>
    </>
    }
    </>
  );
}

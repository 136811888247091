import { useState, useEffect } from "react";
import styled from "styled-components";
import { useDispatch, useSelector } from "react-redux";
import { useNavigate } from "react-router";
import Menus from "../../components/hamburger/menus";
import HamburgerLay from "../../components/hamburger/hamburger";
import MainLayout from "../../layouts/mainLayout/mainLayout";
import { Colors } from "../../utilities/colors";
import IntroImage from "../../assets/Frame.png";
import completeAssessment from "../../assets/complete.png";
import IntroBackImage from "../../assets/Mask Group.png";
// import BlogCard from "../../components/blogCard/blogCard";
import {
  getAllCountries,
  getAssesmentList,
  getEthinicities,
  getEmployee,
  changEmployee,
  getTalentUserData,
  onBoardFormSubmission,
  blogList,
  validateObf,
} from "../../services/apiCalls";
import Loader from "../../components/loader/loader";
import { updateTalentData } from "../login/reducer";
import DashboardPopup from "../../components/dashboardPopup/dashboardPopup";
import { updatePercentageData } from "../dashboard/reducer";
import Slider from "react-slick";
import Icon from "../../assets/assesment.png";
import { removeKeys } from "../../utilities/commonFunctions";
import { onBoardFormGetData, internshipStatus } from "../../services/apiCalls";
import { updateValues } from "../../pages/selfIntro/reducer";
import { toast } from "react-toastify";
import HeaderGreeting from "../../components/headerGreeting/headerGreeting";
import { useTranslation } from "react-i18next";
import { AspectRatio } from "react-aspect-ratio";
import "react-aspect-ratio/aspect-ratio.css";

import { resetFilter } from "../internship/reducer"; //INIVOS
import { deviceStore } from "../../utilities/devices";
import { useSnapshot } from "valtio";
import SidebarSallyIcon from "../../assets/sidebar.png";
import ProfileCompletionBar from "../../components/profileCompletionBar/profileCompletionBar";

import { InAppBrowser } from "@ionic-native/in-app-browser";
import { fetchPercentage } from "../../Redux/Reducers/ProfileReducer/ProfileReducer";
import AlertModal from "../../components/alertModal/alertModal";
import CustomModal from "../../components/modal/modalmobile";
import { useForm, Controller } from "react-hook-form";
import { Row, Col } from "react-bootstrap";
import Input from "../../components/input/input";
import {
  selfIntoStaticContent,
  errorMessages,
  schema,
} from "../selfIntro/selfIntroFormConstant";
import ErrorField from "../../components/errorField/errorField";
import { yupResolver } from "@hookform/resolvers/yup";
import RadioInput from "../../components/radioinput/radioinput";
import Dropdown from "../../components/dropdown/dropdown";
import { mynextTalent } from "../mynextConstant";
import Button from "../../components/button/button";
import IntroSection from "../../components/introSection/introSection";

import { Link } from "react-router-dom";
import JobPortalModal from "../../components/jobPortalModal/jobPortalModal";
import { companyProxy } from "./companyproxy";
import AddEmployeeConfirm from "../addemployee/linkAccountModal";
import AddCompanySingleModal from "../addcompany/addCompanySingleModal";
import AddCompanyConfirmationModal from "../addcompany/addcompanyConfirmationmodal";
import { getmycvuserdatails } from "../../services/apiCalls";
import moment from "moment";

const BlogCardWrapper = styled.div`
  background: ${Colors.light};
  border-radius: 34px;
  position: relative;
  margin: 10px;
  overflow: hidden;
`;
const ContentSection = styled.div`
  padding: 8px 20px 20px;
`;
const Image = styled.img`
  width: 100%;
  height: 16vh;
  max-width: 100%;
  border-radius: 34px 34px 0px 0px;
`;
const TitleBlog = styled.h5`
  font-weight: 600;
  font-size: 20px;
  line-height: 25px;
  margin: 0;
  color: ${Colors.primaryText};
`;
const DescriptionBlog = styled.div`
  font-style: normal;
  font-weight: 500;
  font-size: 12px;
  line-height: 16px;
  color: #525252;
  margin: 0;
 padding: 8px 0 0;
  height: 5vh;
 overflow: hidden;
 p{
   height: 4vh;
   line-height: 20px;
 }
}
`;

const NationalityWrapper = styled.div`
  margin-bottom: 10px;
`;
const IdentificationWrapper = styled.div`
  display: -webkit-box;
  align-items: center;
  @media (max-width: 599px) {
    // justify-content: space-between;
    margin-bottom: 20px;
  }
`;
const DashboardWrapper = styled.div`
  @media (max-width: 1024px) {
    display: flex;
  }
`;
const PageWrapper = styled.div`
  min-height: 60vh;
  padding: 0rem 3rem;
  @media (max-width: 767px) {
    padding: 0rem 0.8rem;
  }
`;
const HeaderWrapper = styled.div`
  display: flex;
  align-items: center;
  justify-content: end;
  > img {
    margin-right: 10px;
    max-width: 30px;
    cursor: pointer;
    &:last-child() {
      margin-right: 0px;
    }
  }
`;
const HeaderText = styled.h2`
  font-weight: 500;
  font-size: 28px;
  line-height: 38px;
  color: ${Colors.dark};
  margin: 0 0 1.5rem 0;
`;
const FlexDiv = styled.div`
  display: flex;
`;
const SubHeaderText = styled.div`
  font-weight: 500;
  font-size: 24px;
  line-height: 32px;
  color: ${Colors.dark};
  margin: 30px 0px 30px 20px;
`;
const IntroImg = styled.img`
  height: 30px;
  @media (max-width: 599px) {
    max-height: 40px;
  }
`;
const AssesmentWrapper = styled.div`
  margin: 15px 0px;
  display: grid;
  grid-template-columns: repeat(3, 1fr);
  grid-gap: 20px;
  @media (max-width: 599px) {
    grid-template-columns: unset !important;
  }
  @media (max-width: 1199px) {
    grid-template-columns: repeat(2, 1fr);
  }
`;
const HeadingWrapper = styled(FlexDiv)`
  align-items: center;
  justify-content: space-between;
  > p {
    margin: 0;
    font-weight: 500;
    font-size: 18px;
    line-height: 24px;
    text-decoration-line: underline;
    color: ${Colors.primaryText};
  }
`;
// const AssesmentWrapper = styled.div`
//   margin: 15px 0px;
//   display: grid;
//   grid-template-columns: repeat(3, 1fr);
//   grid-gap: 20px;
//   @media (max-width: 599px) {
//     grid-template-columns: unset !important;
//   }
//   @media (max-width: 1199px) {
//     grid-template-columns: repeat(2, 1fr);
//   }
// `;

const HamburgerLayout = styled.div`
  @media (min-width: 1025px) {
    display: none;
  }
`;
const SubheaderDiv = styled.div`
  display: flex;
  justify-content: space-between;
  align-items: center;
  position: relative;
  left: 13px;
  h3 {
    margin: 1.5rem 0;
  }
  .view {
    margin: 0px;
    font-weight: 500;
    font-size: 18px;
    line-height: 24px;
    text-decoration-line: underline;
    color: rgb(208, 76, 141);
    cursor: pointer;
  }
`;

const IntroCard = styled.div`
  background-image: linear-gradient(90deg, #57508d 0%, #f54ba1 100%);
  padding-left: 50px;
  display: flex;
  align-items: center;
  justify-content: space-between;
  border-radius: 10px;
  height: 100px;
  position: relative;

  @media (max-width: 1366px) {
    padding: 14px;
    height: 165px;
  }
  @media (max-width: 768px) {
    height: 165px;
  }
  @media (max-width: 599px) {
    height: 192px;
  }
`;
const IntroText = styled.div`
  width: 100%;
  display: flex;
  align-items: center;
  gap: 20px;
  margin-bottom: 5px;
  position: absolute;
  z-index: 1;
  @media (max-width: 1366px) {
    width: 94%;
  }
  @media (max-width: 768px) {
    width: 89%;
  }
  @media (max-width: 599px) {
    width: 94%;
  }
`;
const IntroRightImage = styled.img`
  height: 100%;
  object-fit: cover;
  position: absolute;
  right: 0;
`;
const Title = styled.h5`
  font-weight: normal;
  font-size: 20px;
  line-height: 20px;
  margin-top: 5px;
  color: #ffffff;
  @media (max-width: 599px) {
    font-size: 18px;
  }
`;
const Description = styled.p`
  font-style: normal;
  font-weight: 300;
  font-size: 16px;
  margin: 0;
  line-height: 20px;
  color: #ffffff;
  @media (max-width: 599px) {
    font-size: 14px;
    line-height: 20px;
  }
`;

const DescriptionBold = styled.p`
  font-style: normal;
  font-weight: 300;
  font-size: 18px;
  margin: 0;
  line-height: 28px;
  color: #ffffff;
  @media (max-width: 599px) {
    font-size: 14px;
    line-height: 20px;
  }
`;

const ProgressbarWrapper = styled.div`
  width: calc(100% - 6em);
  height: 4px;
  background: rgba(255, 255, 255, 0.5);
  border-radius: 102px;
`;
const PercentageBar = styled.div`
  width: ${(props) => (props.percentage ? props.percentage + "%" : "50%")};
  height: 100%;
  background: ${(props) => (props.bg ? props.bg : "#00FF38")};
  border-radius: 102px;
`;
const RowDiv = styled.div`
  width: 100%;
`;


const FormLabel = styled.label`
  font-style: normal;
  font-weight: 500;
  font-size: 18px;
  line-height: 24px;
  padding:0px 12px;
  display: block;
  color: ${Colors.labelText};
  visibility: ${(props) => (props.visible ? "hidden" : "default")};
  margin-bottom: 10px;
  > span {
    font-style: italic;
    font-weight: 300;
    font-size: 14px;
}
`;

const CloseWrapper = styled.div`
  display:flex;
  justify-content:space-between;
  padding:12px;
  height:52px;
`;

const PopUpHeader = styled.div`
background: rgba(83, 80, 141, 0.1);
border-radius: 24px 24px 0px 0px;
padding:12px 0px;
`;

const PopupWrapper = styled.div`
   margin:8px;
`;
const PopupContent = styled.div`
  text-align:center;
  padding:24px 12px;
`;
const ContentText = styled.div`
font-family: 'General Sans';
font-style: normal;
font-weight: 500;
font-size: 18px;
line-height: 24px;
text-align: center;
color: #202020;
`;

const ButtonWrapper = styled.div`
   display:flex;
   flex-direction:row;
   justify-content:space-around;
   padding:24px 0px;
   max-width:480px;
   margin:auto;
   `;
const SidebarSally = styled.div`
  position: relative;
`;

const SallyImg = styled.img`
  height: 15vh;
  height: 15vh;
  width: 100%;
`;

const FormWrapper = styled.div`
  margin: 30px 0 0 0;
  max-width: 100%;
  @media (max-width: 1024px) {
    max-width: 100%;
  }
`;
const LabelText = styled.label`
  font-style: normal;
  font-weight: 500;
  font-size: 18px;
  line-height: 24px;
  color: ${Colors.labelText};
  margin-bottom: 10px;
  display: block;
`;
const FormGroup = styled.div`
  margin-bottom: 25px;
`;
const RadioOptionWrapper = styled.div`
  display: flex;
  justify-content: space-between;
  align-items: center;

  @media (max-width: 599px) {
    justify-content: space-between;
  }
  @media (max-width: 990px) {
    justify-content: space-between;
  }
`;

export default function Dashboard() {
  window.history.pushState(
    { urlPath: "/dashboard" },
    "",
    "/dashboard" + window.location.search
  );
  useSnapshot(deviceStore);
  const formValues = useSelector((state) => state.selfIntroOne.value);

  const checkbrowser = deviceStore.checkbrowser;
  const dispatch = useDispatch();
  const [isOpenham, setOpenHam] = useState(false);
  const [loading, setLoader] = useState(true);
  const [startIndex, setStartIndex] = useState(0);
  const [assesmentLists, setAssesmentList] = useState([]);
  const [user, setUser] = useState("");
  const [resizeXs, setResizeXs] = useState(false);
  const [resizeMd, setResizeMd] = useState(false);

  ///
  const [userDetails, setUserDetails] = useState()
  const [userVisible, setUserVisible] = useState()

  const [remind, setRemind] = useState(true);

  const [isOpen, setOpen] = useState(false);
  const [internshipStatusData, setInternshipStatusData] = useState();
  const [selectedRace, setSelectedRace] = useState({});
  const [selectedNationality, setSelectedNationality] = useState({});
  const [races, setRaces] = useState([]);
  const [countryListExcludeDefault, setNMcountry] = useState([]);
  const [countries, setCountry] = useState([]);
  const [selectedCountry, setSelectedCountry] = useState({});
  const [aboutFormErrors, setAboutFormErrors] = useState(false);
  const [openPopup, setOpenPopup] = useState(false);

  const options = {
    location: "no",
    zoom: "no",
    hideurlbar: "yes",
    toolbarposition: "bottom",
  };
  var browser;
 
  const [reminder, setReminder] = useState(null)
  const [emplyeRemind, setEmplyRemind] = useState("")
  const [internRemind, setInternRemind] = useState("")

  const [profileRemindStatus, setProfileRemindstatus] = useState(false)
  const [cvRemindStatus, setCVRemindstatus] = useState(false)

  const openBrowser = (url) => {
    if(deviceStore.platform==="ios"){
      const options =  "_blank";
  
      const browser = InAppBrowser.create(url, options);
    
      browser.on("loadstart").subscribe(async (event) => {
        browser.show();
    
        // Check if the URL contains "/dashboard/" to detect when the assessment is completed
        if (event.url.indexOf("/dashboard/") !== -1) {
          browser.close();
          window.location.reload(); // Reload the app after the assessment is completed
        }
      });
    
      // Handle the "exit" event to reload the app or perform any other necessary actions
      browser.on("exit").subscribe(() => {
        window.location.reload();
      });
      
    }else{
      browser = InAppBrowser.create(url, "_self", options);
      // if (deviceStore.platform == "ios") {
      //   browser = InAppBrowser.create(url, "_blank", options);
      // }
      if (deviceStore.platform == "android") {
        browser = InAppBrowser.create(url, "_self", options);
      }
  
      browser?.on("loadstart")?.subscribe(async (event) => {
        browser.show();
        // browser.executeScript({code: `(function() { url = window.location.href; if(url.indexOf("/dashboard/")!=-1){alert(url);alert(url.indexOf("/dashboard/"));window.open('', '_self', ''); window.close();} })()`});
  
        browser
          .executeScript({
            code: `(function() { return window.location.href;})()`,
          })
          .then(
            (res) => {
              if (res.toString().indexOf("/dashboard/") != -1) {
                browser.close();
                window.location.reload();
              }
            },
            (err) => { }
          );
      });
    }
    
  };
  // popUps
  const [updateProfilePopup, setUpdateProfilePopup] = useState(false)
  const [graduatePopup, setGraduatePopup] = useState(false)
  const [employedPopup, setEmployedPopup] = useState(false)
  const [internshipPopup, setInternshipPopup] = useState(false)


  const {
    control,
    handleSubmit,
    setValue,
    watch,
    resetField,
    setError,
    clearErrors,
    formState: { errors, values },
  } = useForm({
    defaultValues: { ...formValues },
    resolver: yupResolver(schema),
  });

  const formVal = watch();

  console.log("formvalllllllll----------------------", formVal);
  function viewAllInternships() { }
  const [blogData, setBlogData] = useState([]);
  const [request, setRequest] = useState({
    title: "",
    description: "",
    image: "",
    status: "public",
  });
  
  const { t, i18n } = useTranslation();

  useEffect(() => {
    getInternshipStatus();
    getTalentUser();
    getAssesmentData();
    employee();
    getBlog();
    //INIVOS
    dispatch(resetFilter());
    // getTimeZone();
    // setOpenPopup(true);
  }, []);

  const employee = async () => {
    const { status, data } = await getEmployee();
    if (status == 200) {
      if (data["data"]["employee"] && data["data"]["employee"].length > 0) {
        let e = data["data"]["employee"][0];
        companyProxy.employeedata = e;
        if (data["data"]["org_code"] && data["data"]["org_code"].length > 0) {
          companyProxy.org_code = data["data"]["org_code"];
        }
        if (data["data"]["org_name"] && data["data"]["org_name"].length > 0) {
          companyProxy.org_name = data["data"]["org_name"];
        }
        console.log(e);
        if (Number(e["joined_company"]) == 0) {
          companyProxy.empmodal1 = true;
        } else if (Number(e["joined_company"]) == 1) {
          companyProxy.showcompanysidemenu = true;
        }
      }
    }
  };

  const updateemp1 = async () => {
    companyProxy.empmodal1 = false;
    const { data } = await changEmployee(
      "no",
      companyProxy.employeedata["id"],
      {}
    );
  };

  const updateemp2 = async () => {
    companyProxy.empmodal3 = false;
    const { data } = await changEmployee(
      "confirm",
      companyProxy.employeedata["id"],
      { joined_date: new Date().toISOString() }
    );
    if (data["status"] == 1) {
      companyProxy.showcompanysidemenu = true;
      toast.success(
        t("Your account have been added successfully to your company"),
        {
          position: toast.POSITION.TOP_RIGHT,
        }
      );
    }
  };

  const [pop_status, setPopStatus] = useState("");

  useEffect(() => {
    let pop_status_initial = localStorage.getItem("pop_status");
    if (!pop_status_initial) {
      setOpen(true);
      pop_status_initial = localStorage.setItem("pop_status", 1);
    }
  }, []);

  useEffect(() => {
    dispatch(updatePercentageData(profileCompletion));
  }, []);

  const getInternshipStatus = async () => {
    const { data } = await internshipStatus();
    setInternshipStatusData(data);
  };

  const aboutMeData = {
    quiz: "About Me",
    name: "self-intro",
    image: "",
    description: "View and Manage information about yourself",
    completed: "",
  };

  const getTalentUser = async () => {
    const { data } = await getTalentUserData();
    if (data && data.status === 1) {
      dispatch(updateTalentData(data.data));
      aboutMeData["completed"] = data.data.additional_info.percentage;
      setUser(data.data);
    }
  };

  const getAssesmentData = async () => {
    const { data } = await getAssesmentList();
    if (data && data.status === 1) {
      const slickItems = data.data;
      slickItems.unshift(aboutMeData);

      setAssesmentList(slickItems);
      setLoader(false);
    } else {
      setLoader(false);
    }
  };

  var totalValue = assesmentLists.reduce(
    (accum, item) => accum + Math.round(item.completed),
    0
  );
  let profileCompletion = Math.round(totalValue / 8);
  const talentUser = useSelector((state) => state.selfIntroOne.userData);
  const navigate = useNavigate();

  const onChangePage = (pageNum) => {
    setStartIndex(pageNum);
  };

  const windowResize = () => {
    if (window.innerWidth <= 600) {
      setResizeXs(true);
      setResizeMd(false);
    } else if (window.innerWidth <= 1200) {
      setResizeXs(false);
      setResizeMd(true);
    } else {
      setResizeXs(false);
      setResizeMd(false);
    }
  };

  window.addEventListener("resize", windowResize);

  var allCompleted = [];
  const [isAll, setIsAll] = useState("");

  useEffect(() => {
    if (assesmentLists !== undefined && assesmentLists.length > 0) {
      assesmentLists.map((assesment) =>
        allCompleted.push(parseInt(assesment.completed))
      );
      var allPercent2 =
        allCompleted.reduce((partialSum, a) => partialSum + a, 0) / 8;
      setIsAll(allPercent2);
    }
  }, [assesmentLists, aboutMeData]);

  var progBar = "";
  var colorVal = "";
  var bgVal = "";
  const getSliderData = (assesment) => {
    const percentageVal = assesment.completed
      ? Math.round(parseInt(assesment.completed))
      : 0;
    progBar = percentageVal;
    colorVal = percentageVal === 0 ? "#FF0000" : "#00FF38";
    bgVal = percentageVal === 0 ? "transparent" : "#00FF38";
  };

  const redirectToProfileSection = async () => {
    const { data, status } = await onBoardFormGetData();
    if (data && data.status === 1) {
      const newObj = removeKeys(data.data);
      dispatch(updateValues({ ...newObj }));
      navigate("/self-intro");
    } else {
      toast.error(data && data.message, {
        position: toast.POSITION.TOP_RIGHT,
      });
    }
  };

  

  const check6MonthsUpdate = (profDate, cvDate) => {
    console.log("profDate-->", profDate, cvDate, "<---cvDate")
    const currentTime = moment().unix();
    if (profDate) {
      const lastUpdated = profDate;
      const dateObject = new Date(lastUpdated);
      const epochTime = dateObject.getTime();
      const lastUpdatedEpoch = Math.floor(epochTime / 1000);
      if ((currentTime - lastUpdatedEpoch) >= (6 * 30 * 24 * 60 * 60)) setProfileRemindstatus(true);
    }
    if (cvDate) {
      const lastUpdated = cvDate;
      const dateObject = new Date(lastUpdated);
      const epochTime = dateObject.getTime();
      const lastUpdatedEpoch = Math.floor(epochTime / 1000);
      if ((currentTime - lastUpdatedEpoch) >= (6 * 30 * 24 * 60 * 60)) setCVRemindstatus(true);
    }
  }

  const check3MonthsBeforeGraduation = (date, email) => {
    const dateObject = new Date(date);
    const epochTime = dateObject.getTime();
    const lastUpdatedEpoch = Math.floor(epochTime / 1000);
    const currentTime = moment().unix();
    // setInternRemind(email + "threeMonths" + date)
    console.log(currentTime - lastUpdatedEpoch, 3 * 30 * 24 * 60 * 60, "lastUpdatedEpoch - currentTime")
    if ((lastUpdatedEpoch - currentTime) <= (3 * 30 * 24 * 60 * 60) && (lastUpdatedEpoch - currentTime) > 0) {
      // if (localStorage.getItem(email + "threeMonths" + date) === null) {
      setInternshipPopup(true)
      // }
    }

  }

  const check1MonthsAfterGraduation = (date, email) => {
    const dateObject = new Date(date);
    const epochTime = dateObject.getTime();
    const lastUpdatedEpoch = Math.floor(epochTime / 1000);
    const currentTime = moment().unix();
    // setEmplyRemind(email + "oneMonth" + date)
    console.log(lastUpdatedEpoch - currentTime, 1 * 30 * 24 * 60 * 60, "currentTime - lastUpdatedEpoch")
    if ((currentTime - lastUpdatedEpoch) >= (1 * 30 * 24 * 60 * 60) && (currentTime - lastUpdatedEpoch) > 0) {
      // if (localStorage.getItem(email + "oneMonth" + date) === null) {
      setEmployedPopup(true)
      // }
    }
  }



  const updateYourProfile =
    <PopupWrapper>
      <PopUpHeader>
        <CloseWrapper>
          <p style={{ color: "#FA4AA1" }}>{t("PROFILE UPDATE")}</p>
          <p style={{ cursor: "pointer" }} onClick={() => {
            setProfileRemindstatus(false);
            setCVRemindstatus(false);
          }}> X </p>
        </CloseWrapper>
        <FormLabel style={{ marginTop: "-10px" }}>{t("Update your profile")}</FormLabel>
      </PopUpHeader>
      <PopupContent>
        <ContentText>{t("Would you like to update your profile?")}</ContentText>
        <ButtonWrapper>
          {profileRemindStatus ? <Button name={t("Update My Profile")} onClick={() => onRedirectToAssesment("self-intro")} /> : null}
          {cvRemindStatus ? <Button name={t("Update My CV")} onClick={() => navigate('/cv')} /> : null}
        </ButtonWrapper>
      </PopupContent>
    </PopupWrapper>

  const graduateTalentSearch =
    <PopupWrapper>
      <PopUpHeader>
        <CloseWrapper>
          <p style={{ color: "#FA4AA1" }}>PROFILE UPDATE</p>
          <p style={{ cursor: "pointer" }} onClick={() => setGraduatePopup(false)}> X </p>
        </CloseWrapper>
        <FormLabel style={{ marginTop: "-10px" }}>Graduate Talent Search</FormLabel>
      </PopUpHeader>
      <PopupContent>
        <ContentText>Would you like to make your profile visible ?</ContentText>
        <ButtonWrapper>
          <Button name={"Make Myself Visible"} onClick={() => navigate('/cv')} />
        </ButtonWrapper>
      </PopupContent>
    </PopupWrapper>

  const employed =
    <PopupWrapper>
      <PopUpHeader>
        <CloseWrapper>
          <p style={{ color: "#FA4AA1" }}>PROFILE UPDATE</p>
          <p style={{ cursor: "pointer" }} onClick={() => setEmployedPopup(false)}> X </p>
        </CloseWrapper>
        <FormLabel style={{ marginTop: "-10px" }}>Have you been Employed ?</FormLabel>
      </PopUpHeader>
      <PopupContent>
        <ContentText>Would you like to update your profile ?</ContentText>
        <ButtonWrapper>
          <Button name={"Update your Profile"} onClick={() => {
            setEmployedPopup(false);
            onRedirectToAssesment("self-intro");
            // localStorage.setItem(emplyeRemind, true)
          }} />
        </ButtonWrapper>
      </PopupContent>
    </PopupWrapper>

  const internship =
    <PopupWrapper>
      <PopUpHeader>
        <CloseWrapper>
          <p style={{ color: "#FA4AA1" }}>PROFILE UPDATE</p>
          <p style={{ cursor: "pointer" }} onClick={() => setInternshipPopup(false)}> X </p>
        </CloseWrapper>
        <FormLabel style={{ marginTop: "-10px" }}>Have you been completed your Internship ?</FormLabel>
      </PopUpHeader>
      <PopupContent>
        <ContentText>Would you like to update your profile ?</ContentText>
        <ButtonWrapper>
          <Button name={"Update your Profile"} onClick={() => {
            setInternshipPopup(false)
            navigate('/aboutme')
            // localStorage.setItem(internRemind, true)
          }
          } />
        </ButtonWrapper>
      </PopupContent>
    </PopupWrapper>

  const settings = {
    dots: true,
    infinite: true,
    speed: 1000,
    slidesToShow: 3,
    slidesToScroll: 3,
    adaptiveHeight: true,

    responsive: [
      {
        breakpoint: 1024,
        settings: {
          slidesToShow: 3,
          slidesToScroll: 3,
          infinite: false,
          dots: true,
        },
      },
      {
        breakpoint: 600,
        settings: {
          slidesToShow: 2,
          slidesToScroll: 2,
          initialSlide: 2,
          infinite: false,
          dots: false,
        },
      },
      {
        breakpoint: 480,
        settings: {
          slidesToShow: 1,
          slidesToScroll: 1,
          dots: false,
          infinite: false,
        },
      },
    ],
  };

  const fetchCountry = async () => {
    //const { data, status } = await getCountries();
    const { data, status } = await getAllCountries();
    if (status === 200) {
      const location = data.data;
      const country = location.map((x) => {
        return { ...x, label: x.Name, value: x.Id };
      });
      setCountry(country);

      const countryListExcludeDefault = country.filter(
        (x) => x.value !== mynextTalent.defaultCountryId
      );
      setNMcountry(countryListExcludeDefault);

      // Edit mode
      if (formVal.curr_country) {
        let selCountry = country.filter((x) => x.value == formVal.curr_country);
        // let selCountry = country.filter(x => x.value == mynextTalent.defaultCountryId);
        if (selCountry.length > 0) {
          selCountry = selCountry[0];
          setValue("curr_country", selCountry.value);
        }
        setSelectedCountry(selCountry);
      }
      // Nationality
      if (formVal.country) {
        let selNationality = country.filter((x) => x.value == formVal.country);
        if (selNationality.length > 0) {
          selNationality = selNationality[0];
          setValue("country", selNationality.value);
        }
        setSelectedNationality(selNationality);
      }
    }
  };
  const dropdownChange = (name, val) => {
    setValue(name, val.value);
    switch (name) {
      case "country":
        setSelectedNationality(val);
        setValue("country_abbr", val.label);
        setSelectedRace("");
        setValue("race_abbr", "");
        break;
      case "race":
        setSelectedRace(val);
        setValue("race_abbr", val.label);
        setSelectedNationality("");
        setValue("country", "");
        setValue("country_abbr", "");
        dispatch(
          updateValues({
            ...formValues,
            ...formVal,
            race: "",
          })
        );
        break;
      default:
    }
  };

  const radioChange = (value, name) => {
    setValue(name, value);
    switch (name) {
      case "disability_status":
        if (value === 0) {
          setValue("disability_code", "");
        }
        break;
      case "id_type":
        resetField("id_number");
        setValue("nationality", 1);
        setValue("country", "");
        setValue("race", "");
        setValue("country_abbr", "");
        setValue("race_abbr", "");
        setSelectedRace("");
        setSelectedNationality("");
        break;
      case "nationality":
        setValue("race", "");
        setValue("race_abbr", "");
        setSelectedRace("");
        setValue("country", "");
        setValue("country_abbr", "");
        setSelectedNationality("");
        break;
      default:
    }
  };

  const inputChange = (e) => {
    const { name } = e.target;
    let value = e.target.value;

    if (value) {
      switch (name) {
        case "id_number":
          if (formVal.id_type === 1) {
            var oldValue = ("" + value).replace(/\D/g, "");

            var isNum = true;
            if (oldValue.length > 0) {
              let lastDigit = oldValue.charAt(oldValue.length - 1);
              isNum = !isNaN(lastDigit);
            }

            let newValue = `${oldValue.substring(0, 6)}${
              oldValue.length > 6 ? "-" : ""
            }${oldValue.substring(6, 8)}${
              oldValue.length > 8 ? "-" : ""
            }${oldValue.substring(8, 12)}`;

            if (/^[a-zA-Z\d-]+$/.test(oldValue) && isNum) {
              setValue("id_number", newValue);
            }
          } else {
            formVal.id_number = formVal?.id_number?.toUpperCase();
            setValue(
              "id_number",
              /^[a-zA-Z\d]+$/.test(value?.toUpperCase())
                ? value?.toUpperCase()
                : formVal.id_number
            );
          }
          break;
        case "disability_code":
          setValue(
            name,
            /^[0-9]+$/.test(value) ? value : formVal.disability_code
          );
          break;
        default:
          break;
      }
    } else {
      setValue(name, value);
    }
  };

  const onValidate = async () => {
    if (formVal.id_number) {
      let code = formVal.id_type === 1 ? "1" : "0";
      let req = {
        data: formVal.id_number,
      };
      const { data, status } = await validateObf(req, code);
      if (data && data.status === 1) {
        clearErrors("id_number");
      } else {
        setError("id_number", { message: data && data.message });
      }
    }
  };
  const onInputChange = (name, e) => {
    const { value } = e.target;
    const re = /^[a-zA-Z\s]*$/;
    if (value === "" || re.test(value)) {
      setValue(name, value);
    }
  };
  const fetchOnBoardData = async () => {
    const { data, status } = await onBoardFormGetData();
    if (data && data.status === 1) {
      const onBoardData = data.data;
      dispatch(updateValues({ ...formVal, ...onBoardData }));
    } else {
      if(deviceStore.platform === 'ios'){
        toast.error("Please complete your About Me form", {
        position: toast.POSITION.TOP_RIGHT,
        });
      }else {
        toast.error(data && data.message, {
        position: toast.POSITION.TOP_RIGHT,
        });
      }
      
    }
  };
  console.log("formvalues---------------", formValues);





  const onRedirectToAssesment = (name) => {
     if (name === "self-intro") {
      redirectToProfileSection();
    } else {
      const { str_token } = user && user.additional_info;
      let url = `${process.env.REACT_APP_ASSESMENT_URL}${str_token}/${name}/${i18n.language}`;
      if(deviceStore.platform === 'ios'){
        const browser = this.inAppBrowser.create(url, '_blank', 'location=no,clearsessioncache=yes,clearcache=yes');

        browser.on('exit').subscribe(() => {
          // Handle any actions after the assessment is completed and the browser is closed
        });
      }else{
        openBrowser(url);
      }
       openBrowser(url);
      
    }
    
  };
  const getBlog = async (code) => {
    const { data } = await blogList();
    if (data) {
      setBlogData(data.results);
    }
  };
  const checkGraduationPopups = async () => {
    const { data, status } = await onBoardFormGetData();
    const cvResp = await getmycvuserdatails()
    console.log(cvResp, "cvResp")
    const date = data?.data?.exp_graduate_date;
    // const date = "2023-08-19";
    const email = data?.data?.email
    console.log(date, "cvResp")
    if (data?.data?.updated_at) {
      await check6MonthsUpdate(data?.data?.updated_at, cvResp?.data?.data?.last_updated_date)
      // await check6MonthsUpdate("2022-12-02", cvResp?.data?.data?.last_updated_date)
    }
    if (date) {
      const role = data?.data?.talent_status

      //
      const lastUpdatedOn = data?.data?.updated_at;
      //  const lastUpdatedOn = "2023-02-03";
      const dateObjectLAstUpdate = new Date(lastUpdatedOn);
      const epochTime = dateObjectLAstUpdate.getTime();
      const lastUpdatedOnEpoch = Math.floor(epochTime / 1000);
      const currentEpoch = moment().unix();



      if (role !== "employed" && data?.data?.talent_status !== "unemployed") {
        await check1MonthsAfterGraduation(date, email);
      }
      if (role === "internship") {
        console.log(currentEpoch - lastUpdatedOnEpoch, 3 * 30 * 24 * 60 * 60, "3 * 30 * 24 * 60 * 60")
        if (currentEpoch - lastUpdatedOnEpoch > 3 * 30 * 24 * 60 * 60 && currentEpoch - lastUpdatedOnEpoch > 0) await check3MonthsBeforeGraduation(date, email);
      }
    }
  }

  useEffect(() => {
    //  checkLastUpdatedDate()
    checkGraduationPopups()
  }, [])

  useEffect(() => {
    fetchCountry();
    fetchEthinicities();
    fetchOnBoardData();
  }, []);

  const fetchEthinicities = async () => {
    const { data, status } = await getEthinicities();
    if (status === 200) {
      const race = data.data;
      const newRace = race.map((x) => {
        return { ...x, label: x.Name, value: x.Id };
      });
      setRaces(newRace);
      if (formValues.race) {
        let selRace = newRace.filter((x) => x.value == formValues.race);
        // let selRace = newRace.filter(x => x.value == mynextTalent.defaultRaceId);
        if (selRace.length > 0) {
          selRace = selRace[0];
          setValue("race", selRace.value);
        }
        setSelectedRace(selRace);
      }
  
    }
  }


 
  console.log("formvalues---------------", formValues);

  const onErrorSubmit = () => {
    setAboutFormErrors(true);
  };
  const onSubmit = async () => {
    let obj1 = JSON.parse(JSON.stringify(formVal));
    delete obj1['dur_year'];
    delete obj1["dur_month"];
    delete obj1["intern_dur_year"];
    delete obj1["intern_dur_month"];
    const { data } = await onBoardFormSubmission({
      ...obj1,
    });
    if (data && data.status === 1) {
      dispatch(updateValues({ ...formValues, ...formVal }));
      setOpenPopup(false);
      toast.success(data && "details Saved Successfully", {
        position: toast.POSITION.TOP_RIGHT,
      });
    } else {
    }
    
   
  };

  return (
    <>
      {isOpen === true ? (
        <DashboardPopup
          setOpen={setOpen}
          isOpen={isOpen}
          pop_status={pop_status}
        />
      ) : (
        ""
      )}
      {profileRemindStatus || cvRemindStatus ? <JobPortalModal show={true} content={updateYourProfile} /> : null}
      {/* <JobPortalModal show={graduatePopup} content={graduateTalentSearch} /> */}
      {/* {profileRemindStatus || cvRemindStatus ? null : <JobPortalModal show={employedPopup} content={employed} />} */}
      {/* {profileRemindStatus || cvRemindStatus ? null : <JobPortalModal show={internshipPopup} content={internship} />} */}
      <MainLayout>
      <PageWrapper>
        <CustomModal open={openPopup} noCloseIcon>
          <IntroSection name={"Personal Infoormation"} />
          <FormWrapper>
            <FormGroup style={{ paddingLeft: "0px" }}>
              <LabelText>{t(selfIntoStaticContent.fullName)}</LabelText>
              <Controller
                name="full_name"
                control={control}
                render={({ field }) => (
                  <Input
                    type="text"
                    onChange={(e) => onInputChange("full_name", e)}
                    placeholder={t("Full Name")}
                    {...field}
                    value={formVal.full_name}
                    checked={1 === formVal.nationality}
                    label={selfIntoStaticContent.malaysian}
                    maxLength="100"
                  />
                )}
              />
              {errors.full_name && (
                <ErrorField err={t(errors.full_name.message)} />
              )}
            </FormGroup>
            <FormGroup>
              <LabelText>{t(selfIntoStaticContent.identification)}</LabelText>
              <Row>
                <Col lg="12" md="12" style={{ padding: "0px" }}>
                  <IdentificationWrapper>
                    <Controller
                      name="id_type"
                      control={control}
                      render={({ field }) =>
                        selfIntoStaticContent.identifications.map(
                          (identity, index) => (
                            <RadioInput
                              key={identity.label + index}
                              {...field}
                              checked={identity.value === formVal.id_type}
                              onChange={() =>
                                radioChange(identity.value, "id_type")
                              }
                              label={t(identity.label)}
                              margin="20px"
                              name="id_type"
                            />
                          )
                        )
                      }
                    />
                  </IdentificationWrapper>
                </Col>
              </Row>
              <Row>
                <Col xl="4" lg="6" md="6" style={{ padding: "0px" }}>
                  <Controller
                    name="id_number"
                    control={control}
                    render={({ field }) => (
                      <Input
                        placeholder={
                          formVal.id_type === 1
                            ? t(selfIntoStaticContent.identificationNum)
                            : t("Passport Number")
                        }
                        {...field}
                        maxLength={formVal.id_type === 1 ? 14 : 16}
                        onChange={inputChange}
                        onBlur={onValidate}
                      />
                    )}
                  />
                  {errors.id_type && (
                    <ErrorField err={t(errorMessages.id_type)} />
                  )}
                  {errors.id_number && (
                    <ErrorField err={t(errors.id_number.message)} />
                  )}
                </Col>
              </Row>
            </FormGroup>
            <FormGroup>
              <Row>
                <LabelText>{t(selfIntoStaticContent.nationality)}</LabelText>
                <Col lg="6">
                  <NationalityWrapper>
                    <Controller
                      name="nationality"
                      control={control}
                      render={({ field }) => (
                        <RadioInput
                          {...field}
                          checked={1 === formVal.nationality}
                          onChange={() => radioChange(1, "nationality")}
                          label={t(selfIntoStaticContent.malaysian)}
                          margin="20px"
                          name="nationality"
                        />
                      )}
                    />
                    <Controller
                      name="race"
                      control={control}
                      render={({ field }) => (
                        <Dropdown
                          search={true}
                          options={races}
                          placeholder={t("Select the Race")}
                          //val={formVal.race}
                          val={
                            formVal.nationality == 0 ? "" : selectedRace?.value
                          }
                          label={selectedRace?.label}
                          disable={formVal.nationality == 1 ? false : true}
                          change={(val) => dropdownChange("race", val)}
                          {...field}
                          refCallback={field?.ref}
                          ref={null}
                        />
                      )}
                    />
                    {errors.race && <ErrorField err={t(errors.race.message)} />}
                  </NationalityWrapper>
                </Col>
                <Col lg="6">
                  <NationalityWrapper>
                    <Controller
                      name="nationality"
                      control={control}
                      render={({ field }) => (
                        <RadioInput
                          checked={0 == formVal.nationality}
                          onChange={
                            formVal.id_type == 1
                              ? ""
                              : () => radioChange(0, "nationality")
                          }
                          label={t(selfIntoStaticContent.nonMalaysian)}
                          disable={
                            formVal.id_type == 1
                              ? true
                              : formVal.nationality == 1
                              ? true
                              : false
                          }
                          margin="20px"
                          name="nationality"
                          data_disabled={
                            formVal.id_type == 1
                              ? true
                              : formVal.nationality == 1
                              ? true
                              : false
                          }
                        />
                      )}
                    />
                    <Controller
                      name="country"
                      control={control}
                      render={({ field }) => (
                        <Dropdown
                          search={true}
                          options={countryListExcludeDefault}
                          placeholder={t("Select the Country")}
                          disable={
                            formVal.id_type == 1
                              ? true
                              : formVal.nationality == 1
                              ? true
                              : false
                          }
                          val={
                            formVal.id_type == 1 || formVal.nationality == 1
                              ? ""
                              : selectedNationality?.value
                          }
                          label={selectedNationality?.label}
                          change={(val) => dropdownChange("country", val)}
                          {...field}
                          refCallback={field?.ref}
                          ref={null}
                        />
                      )}
                    />
                    {errors.country && (
                      <ErrorField err={t(errors.country.message)} />
                    )}
                  </NationalityWrapper>
                </Col>
                {errors.nationality && (
                  <ErrorField err={t(errorMessages.nationality)} />
                )}
              </Row>
            </FormGroup>
            <FormGroup>
              <LabelText>{t(selfIntoStaticContent.pku)}</LabelText>
              <Row>
                <Col lg="6" md="6">
                  <RadioOptionWrapper>
                    <Controller
                      name="disability_status"
                      control={control}
                      rules={{ required: true }}
                      render={({ field }) =>
                        selfIntoStaticContent.isDisability.map(
                          (option, index) => (
                            <RadioInput
                              checked={
                                option.value == formVal.disability_status
                              }
                              onChange={() =>
                                radioChange(option.value, "disability_status")
                              }
                              key={option.label + index}
                              label={t(option.label)}
                              margin="10px"
                              name="disability_status"
                            />
                          )
                        )
                      }
                    />
                  </RadioOptionWrapper>
                </Col>

                <Col lg="6" md="6">
                  <Controller
                    name="disability_code"
                    control={control}
                    rules={{ required: true }}
                    render={({ field }) => (
                      <Input
                        placeholder={t("If Yes, OKU Registration Number")}
                        disabled={formVal.disability_status == 0}
                        maxLength={12}
                        onChange={inputChange}
                        {...field}
                      />
                    )}
                  />
                </Col>

                {errors.disability_code && (
                  <ErrorField err={t(errors.disability_code.message)} />
                )}
              </Row>
            </FormGroup>
            <FormGroup>
              <div
                style={{
                  display: "flex",
                  alignItems: "center",
                  justifyContent: "center",
                }}
              >
                <Button
                  onClick={handleSubmit(onSubmit, onErrorSubmit)}
                  name={t("Save")}
                  width="198px"
                />
                <Button
                  name={t("cancel")}
                  width="198px"
                  secondary
                  style={{ marginLeft: "1rem" }}
                  onClick={() => setOpenPopup(false)}
                />
              </div>
            </FormGroup>
          </FormWrapper>
        </CustomModal>
        
          <DashboardWrapper>
            <HamburgerLayout>
              <HamburgerLay onToggle={() => setOpenHam(!isOpenham)} />
            </HamburgerLayout>
            {isOpenham && <Menus />}
            <HeaderGreeting
              userName={user && user.display_name}
            ></HeaderGreeting>
          </DashboardWrapper>
          {isAll && isAll === 100 ? (
            <IntroCard className="mb-5 pl-0">
              <IntroText>
                <IntroImg
                  src={completeAssessment}
                  alt={t("Complete Assessment")}
                  style={{ height: "105px" }}
                />
                <div>
                  <Title>
                    {t(
                      "Congratulations! You've completed all your assessments"
                    )}
                  </Title>
                  <Description>
                    {t(
                      "You can now explore and apply for internship opportunities matching your profile. Do not forget to update your CV in the My CV screen to let the recruiters know more about you."
                    )}
                  </Description>
                </div>
              </IntroText>
              <IntroRightImage src={IntroBackImage} alt="Earned" />
            </IntroCard>
          ) : (
            <IntroCard className="mb-5 pl-10">
              <IntroImg src={IntroImage} alt="intro section" />
              <IntroText>
                <IntroImg src={IntroImage} alt="intro section" />
                <RowDiv>
                  <Title>
                    {t("Complete Your mynext Profiling Assessments")}
                  </Title>
                  <DescriptionBold>
                    {t(
                      "To apply for internships and jobs, kindly complete the Personality & Motivation, Work Interests, and English proficiency assessments."
                    )}
                    <br />
                    {t(
                      "Results of the assessments will be used to personalise your profile and cater to what’s best for you."
                    )}
                  </DescriptionBold>
                </RowDiv>
              </IntroText>
            </IntroCard>
          )}
          {loading ? (
            <Loader />
          ) : checkbrowser ? (
            <>
              <div className="dashboard_slider mb-3">
                <Slider {...settings}>
                  {assesmentLists.length > 0
                    ? assesmentLists
                        // .slice(
                        //   startIndex,
                        //   resizeXs
                        //     ? startIndex + 1
                        //     : resizeMd
                        //       ? startIndex + 2
                        //       : startIndex + 3
                        // )
                        .map((assesment, index) => (
                          <>
                            {getSliderData(assesment)}
                            <div
                              className="position-relative after_class mx-2"
                              onClick={() =>
                                {formValues.race==="" || formValues.id_number===""? setOpenPopup(true):onRedirectToAssesment(assesment.name)}
                              }
                            >
                              <img
                                src={assesment.image ? assesment.image : Icon}
                              ></img>
                              <div className="content_div">
                                <h5 className="title_">{t(assesment.quiz)}</h5>
                                <p className="paragraph_details">
                                  {t(assesment.description)}
                                </p>
                                <div
                                  className="complete_100"
                                  style={{ color: colorVal }}
                                >
                                  {progBar !== 100 && (
                                    <ProgressbarWrapper>
                                      <PercentageBar
                                        percentage={progBar.toString()}
                                        bg={bgVal}
                                      />
                                    </ProgressbarWrapper>
                                  )}
                                  <label style={{ color: colorVal }}>
                                    {progBar === 100 && (
                                      <img src="data:image/png;base64,iVBORw0KGgoAAAANSUhEUgAAABIAAAASCAYAAABWzo5XAAAACXBIWXMAAAsTAAALEwEAmpwYAAAAAXNSR0IArs4c6QAAAARnQU1BAACxjwv8YQUAAAGHSURBVHgBnZS/S8NAFMe/Vyq6KEVQBFEqHR1sBqVuyeRWdXATin9ZcXZQO7okThUcmj9AMGAFoSClgvij5nzvLkmTGGviF653eb33yfcl9yKQJdkw6bdFg+eqDgqX/qAhLiG6F+kUkQJwUjsATJNHw4K48X6C5HYdKNkUqiCXxBAYE+zWnYCUE9nLD4nDpMHOSkHELg5RDjinrR3JnQMq6bxIOroD4P2LnuRKGLHKBNnPDRh9AJ0+MHjT17vLwKwqqkUgWU+/vEzdvQBXj+TEBxZmgOZaCGGZVFpDRpvPPGBpLm5Zy3kCes96XZsH9lbjEKVytOKa2XL/Vc/NdYqNk6XwDYxFZIkd3SM8vaNP7Ypnts/weCnsNlPSZX/X0TUnHFX1zDCGcCnHtSkQ5ccVQV/ZiThDOg/AZuXXUpLyD8OTzSAT/5NHJ3sjfPQnRBuisFSOxSsNUl3sW8VgvNePvgCTw6C6WBjQn4i/5Ki9Qeer9Oybqf6jIbdoSz2IehqAU3LhpFO+ASB/gPGyf1f2AAAAAElFTkSuQmCC" />
                                    )}
                                    {progBar}
                                    {t("% Completed")}
                                  </label>
                                </div>
                              </div>
                            </div>
                          </>
                        ))
                    : ""}
                </Slider>
              </div>
            </>
          ) : (
            <>
           
              <div className="card mb-3" style={{ borderRadius: "34px" }}>
                <SidebarSally>
                  <div className="row text-center align-items-center">
                    <div className="col-4 p-0">
                      <SallyImg src={SidebarSallyIcon} alt="sally" />
                    </div>
                    <div className="col-8">
                      <ProfileCompletionBar />
                    </div>
                  </div>
                </SidebarSally>
              </div>
              <div className="dashboard_slider mb-3">
                {assesmentLists.length > 0
                  ? assesmentLists
                      // .slice(
                      //   startIndex,
                      //   resizeXs
                      //     ? startIndex + 1
                      //     : resizeMd
                      //       ? startIndex + 2
                      //       : startIndex + 3
                      // )
                      .map((assesment, index) => (
                        <>
                          {getSliderData(assesment)}
                          <div
                            className="position-relative after_class mx-2"
                            style={{
                              width: "95%",
                              height: "35vh",
                              marginBottom: "15px",
                            }}
                            onClick={() =>
                              {formValues.race==="" || formValues.id_number===""? setOpenPopup(true):onRedirectToAssesment(assesment.name)}
                            }
                          >
                            <img
                              style={{
                                display: "block",
                                background: "#ffffff",
                                borderRadius: "34px",
                                position: "relative",
                                height: "35vh",
                                lineHeight: "0",
                                objectFit: "cover",
                                cursor: "pointer",
                                width: "100%",
                              }}
                              src={assesment.image ? assesment.image : Icon}
                            ></img>
                            <div className="content_div">
                              <h5 className="title_">{t(assesment.quiz)}</h5>
                              <p className="paragraph_details">
                                {t(assesment.description)}
                              </p>
                              <div
                                className="complete_100"
                                style={{ color: colorVal }}
                              >
                                {progBar !== 100 && (
                                  <ProgressbarWrapper>
                                    <PercentageBar
                                      percentage={progBar.toString()}
                                      bg={bgVal}
                                    />
                                  </ProgressbarWrapper>
                                )}
                                <label style={{ color: colorVal }}>
                                  {progBar === 100 && (
                                    <img
                                      style={{
                                        display: "block",
                                        background: "#ffffff",
                                        borderRadius: "34px",
                                        position: "relative",
                                        height: "35vh",
                                        lineHeight: "0",
                                        objectFit: "cover",
                                        cursor: "pointer",
                                        width: "100%",
                                      }}
                                      src="data:image/png;base64,iVBORw0KGgoAAAANSUhEUgAAABIAAAASCAYAAABWzo5XAAAACXBIWXMAAAsTAAALEwEAmpwYAAAAAXNSR0IArs4c6QAAAARnQU1BAACxjwv8YQUAAAGHSURBVHgBnZS/S8NAFMe/Vyq6KEVQBFEqHR1sBqVuyeRWdXATin9ZcXZQO7okThUcmj9AMGAFoSClgvij5nzvLkmTGGviF653eb33yfcl9yKQJdkw6bdFg+eqDgqX/qAhLiG6F+kUkQJwUjsATJNHw4K48X6C5HYdKNkUqiCXxBAYE+zWnYCUE9nLD4nDpMHOSkHELg5RDjinrR3JnQMq6bxIOroD4P2LnuRKGLHKBNnPDRh9AJ0+MHjT17vLwKwqqkUgWU+/vEzdvQBXj+TEBxZmgOZaCGGZVFpDRpvPPGBpLm5Zy3kCes96XZsH9lbjEKVytOKa2XL/Vc/NdYqNk6XwDYxFZIkd3SM8vaNP7Ypnts/weCnsNlPSZX/X0TUnHFX1zDCGcCnHtSkQ5ccVQV/ZiThDOg/AZuXXUpLyD8OTzSAT/5NHJ3sjfPQnRBuisFSOxSsNUl3sW8VgvNePvgCTw6C6WBjQn4i/5Ki9Qeer9Oybqf6jIbdoSz2IehqAU3LhpFO+ASB/gPGyf1f2AAAAAElFTkSuQmCC"
                                    />
                                  )}
                                  {progBar}% Completed
                                </label>
                              </div>
                            </div>
                          </div>
                        </>
                      ))
                  : ""}
              </div>
            </>
          )}
          <SubheaderDiv>
            <SubHeaderText>{t("Blogs & Updates")}</SubHeaderText>
            {/* <p className="view" onClick={() => {}}>
                  View all
                </p> */}
            {/* <Link
                  // key={`/${toPath.join("/")}`}
                  to="/internships"
                >
                  View all
                </Link> */}
          </SubheaderDiv>
          <div className="dashboard_blog_slider">
            <Slider {...settings}>
              {blogData.length > 0
                ? blogData?.map((item, index) => {
                  return (
                    <div>
                      <Link
                        to={`/view-blog/${item.slug}`}
                        style={{ textDecoration: "none" }}
                      >
                        <BlogCardWrapper key={index}>
                          {item && <AspectRatio ratio="16/9" ><Image src={item.image} alt="blog card" /></AspectRatio>}
                          <ContentSection>
                            <TitleBlog>{item.title}</TitleBlog>
                            <DescriptionBlog
                              dangerouslySetInnerHTML={{
                                __html: item.description,
                              }}
                            />
                          </ContentSection>
                        </BlogCardWrapper>
                      </Link>
                    </div>
                  );
                })
                : ""}
            </Slider>
          </div>
        </PageWrapper>
      </MainLayout>

      {companyProxy.empmodal1 ? (
        <AddEmployeeConfirm
          show={companyProxy.empmodal1}
          message={t("Would you to link your account to your company ?")}
          closeModal={(e) => {
            if (e == "yes") {
              updateemp1();
            }
          }}
          onAccept={(e) => {
            if (e == "yes") {
              companyProxy.empmodal1 = false;
              companyProxy.empmodal2 = true;
            }
          }}
        />
      ) : (
        ""
      )}

      {companyProxy.empmodal2 ? (
        <AddCompanySingleModal
          show={companyProxy.empmodal2}
          title={t("PROFILE UPDATE")}
          message={t("Enter Your Organization email address ")}
          value={companyProxy.employeedata["email"]}
          closeModal={(e) => {
            if (e == "confirm1") {
              companyProxy.empmodal2 = false;
            }
          }}
          onAccept={(e) => {
            if (e == "confirm1") {
              companyProxy.empmodal2 = false;
              companyProxy.empmodal3 = true;
            }
          }}
        />
      ) : (
        ""
      )}

      {companyProxy.empmodal3 ? (
        <AddCompanyConfirmationModal
          show={companyProxy.empmodal3}
          title={t("PROFILE UPDATE")}
          message={t("Enter Your Organization email address ")}
          data={companyProxy.employeedata}
          org_name={companyProxy.org_name}
          closeModal={(e) => {
            if (e == "confirm2") {
              companyProxy.empmodal2 = false;
            }
          }}
          onAccept={(e) => {
            if (e == "confirm2") {
              updateemp2();
            }
          }}
        />
      ) : (
        ""
      )}
    </>
  );
}



import { createChatBotMessage } from 'react-chatbot-kit';
import BotProfile from "../../assets/botProfile.png";
import Options from './widgets/options/options';
import { t } from 'i18next';
import ListComponent from './widgets/list/list';
import ChatbotRating from './widgets/chatbotRating/chatbotRating';
import FeedbackComponent from './widgets/feedback/feedback';
import Resolution from "./widgets/resolution/resolution";


const botName = 'MynextBot';

const config = {
  initialMessages: [
    createChatBotMessage(t("Hi! I'm MynextBot. How may I help you?"), {
    widget: "options",
  }),
  ],
  customComponents: {
    // Replaces the default header
    //  header: () => <div style={{ backgroundColor: 'red', padding: "5px", borderRadius: "3px" }}>This is the header</div>
    // Replaces the default bot avatar
    botAvatar: (props) => <img src={BotProfile} className='height-40px' {...props} />,
    // Replaces the default bot chat message container
    //  botChatMessage: (props) => <CustomChatMessage {...props} />,
    // Replaces the default user icon
    //  userAvatar: (props) => <MyUserAvatar {...props} />,
    // Replaces the default user chat message
    //  userChatMessage: (props) => <MyUserChatMessage {...props} />
  },
  widgets: [
    {
      widgetName: 'options',
      widgetFunc: (props) => <Options {...props} />,
    },
    {
      widgetName: 'listComponent',
      widgetFunc: (props) => <ListComponent {...props} />,
    },
    {
      widgetName: 'chatbotRating',
      widgetFunc: (props) => <ChatbotRating {...props} />,
    },
    {
      widgetName: 'feedback',
      widgetFunc: (props) => <FeedbackComponent {...props} />,
    },
    {
      widgetName: 'resolution',
      widgetFunc: (props) => <Resolution {...props} />
    },
  ],
};

export default config;
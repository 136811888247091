import { useEffect, useState } from "react";
import styled from "styled-components";
import { useTranslation } from "react-i18next";
import {
  useForm,
  Controller,
  useFieldArray,
  useWatch,
  FormProvider,
  useFormContext,
} from "react-hook-form";
import { useParams } from "react-router-dom";
import { MuiPickersUtilsProvider, DatePicker } from "@material-ui/pickers";
import { createTheme } from "@material-ui/core";
import { ThemeProvider } from "@material-ui/styles";
import DateFnsUtils from "@date-io/date-fns";

import { Colors } from "../../../utilities/colors";
import Loader from "../../../components/loader/loader";
import { ReactComponent as CalenderIcon } from "../../../assets/icons/svg/calendarIcon.svg";
import {
  getOrCreateInternshipForm,
  getInternshipEvaluationQA,
  getInternshipSkills,
  createInternshipEvaluationForm,
  updateInternshipEvaluationForm,
} from "../../../services/apiCalls";
import _ from "lodash";
import { Button, Form, FormCheck } from "react-bootstrap";
import { formatDate } from "../../../utilities/commonFunctions";
import { toast } from "react-toastify";
import { useNavigate } from "react-router";

import "./internEvaluation.css";

const materialTheme = createTheme({
  palette: {
    primary: {
      main: "#D44B9C",
    },
  },
  overrides: {
    MuiPickersCalendarHeader: {
      switchHeader: {
        color: "#D44B9C",
      },
    },
  },
});

const LayoutWrapper = styled.section`
  display: flex;
  // height: 100vh;
  @media (max-width: 1024px) {
    display: block;
  }
  background: #e6e6e6;
`;

const PageContentSection = styled.div`
  overflow-y: scroll;
  flex-grow: 2;
  max-width: 80rem;
  margin: 0 auto;
  height: 100%;
  margin-top: 2rem;
`;

const CardWrapper = styled.div`
  background: #ffffff;
  border-radius: 34px;
  padding: 3rem;
`;

const FormHeaderWrapper = styled.div`
  // padding: 3rem;
`;

const FormHeadingText = styled.h2`
  font-size: 28px;
  color: #000000;
  font-weight: 500;
  margin-bottom: 5px;
`;
const SpanText = styled.span`
  font-size: 16px;
  color: #858585;
  font-weight: 500;
`;

export const GroupWrapper = styled.div`
  margin-bottom: 30px;
`;

export const FormLabel = styled.label`
  font-style: normal;
  font-weight: 500;
  font-size: 18px;
  line-height: 24px;
  color: #202020;
  margin-top: 1.5rem;
  display: block;
`;

export const FlexWrapper = styled.div`
  display: flex;
`;

const CalenderIconWrapper = styled.div`
  position: relative;
  right: -13rem;
  top: -2.3rem;
  cursor: pointer;
`;

const CheckboxLabel = styled.label`
  font-style: normal;
  font-weight: 500;
  font-size: 16px;
  line-height: 22px;
  color: ${Colors.labelText};
  //margin: 0 1rem;
`;

const SaveButton = styled.p`
  font-weight: 500;
  font-size: 18px;
  line-height: 24px;
  text-align: center;
  text-decoration-line: underline;
  color: #504f8c;
  margin: 0;
  cursor: pointer;
  text-align: left;
  margin-left: 35px;
  @media (max-width: 900px) {
    text-align: center;
  }
  @media (max-width: 800px) {
    margin-left: 0;
  }
`;

const ConditionalInput = ({ control, index }) => {
  const {
    register,
    formState: { errors },
  } = useFormContext();

  const value = useWatch({
    name: `responses.${index}.selected_answers`,
    control,
  });

  return value?.includes("30") ||
    value?.includes("61") ||
    value?.includes("79") ||
    value?.some(
      (element) =>
        element.answer_code === 30 ||
        element.answer_code === 61 ||
        element.answer_code === 79
    ) ? (
    <>
      <Form.Control
        type={"text"}
        {...register(`responses.${index}.others`, {
          required: true,
        })}
      />
      {errors.responses?.[index]?.others && (
        <Form.Control.Feedback type="invalid">
          The value cannot be empty
        </Form.Control.Feedback>
      )}
    </>
  ) : null;
};

export default function InternEvaluation() {
  const [loading, setLoading] = useState(true);
  const [formUpdateMode, setFormUpdateMode] = useState(false);

  const { internshipId } = useParams();

  // const { t } = useTranslation();
  const navigate = useNavigate();

  const methods = useForm({
    defaultValues: {
      form: {
        id: null,
        internship_id: null,
        internship_start_date: null,
        internship_end_date: null,
        monthly_allowance: null,
        acknowledgement: false,
      },
      responses: [],
    },
    mode: "onBlur",
  });

  const {
    control,
    handleSubmit,
    getValues,
    formState: { isSubmitting, errors },
    reset,
    register,
    setError,
  } = methods;

  const { fields } = useFieldArray({
    control,
    name: "responses",
  });

  useEffect(() => {
    if (internshipId != undefined) {
      (async () => {
        const { data, status } = await getOrCreateInternshipForm(internshipId);
        setTimeout(function () {
          setLoading(false);
        }, 2000);

        if (status == 200) {
          if (
            data.hasOwnProperty("form") &&
            data.hasOwnProperty("responses") &&
            data.hasOwnProperty("skill_ratings")
          ) {
            setFormUpdateMode(true);
          }

          Promise.all([
            getInternshipEvaluationQA(),
            getInternshipSkills(internshipId),
          ]).then(function (results) {
            reset({
              form: {
                id: data?.form?.id,
                internship_id: data?.form?.internship,
                internship_start_date: data?.form?.internship_start_date,
                internship_end_date: data?.form?.internship_end_date,
                monthly_allowance: data?.form?.monthly_allowance,
                acknowledgement:
                  data?.form?.acknowledgement == 1 ? true : false,
              },
              responses:
                results[0].data.length > 0
                  ? results[0].data
                      .map((item) => {
                        results[1].data?.technical_skills?.filter(
                          (techSkill, techSkillIndex) => {
                            if (
                              item.question.question_desc.toLowerCase() ===
                              `technical skill ${techSkillIndex + 1}`
                            ) {
                              item.question.question_desc =
                                techSkill.skill_name;
                              item.skill_ratings = [
                                {
                                  technical_skill: techSkill.skill_name,
                                  soft_skill: null,
                                  rating: null,
                                },
                              ];
                              return true;
                            }
                            return false;
                          }
                        );

                        results[1].data?.soft_skills?.filter(
                          (softSkill, softSkillIndex) => {
                            if (
                              item.question.question_desc.toLowerCase() ===
                              `soft skill ${softSkillIndex + 1}`
                            ) {
                              item.question.question_desc =
                                softSkill.skill_name;
                              item.skill_ratings = [
                                {
                                  technical_skill: null,
                                  soft_skill: softSkill.skill_name,
                                  rating: null,
                                },
                              ];
                              return true;
                            }
                            return false;
                          }
                        );

                        return item;
                      })
                      .filter(
                        (item) =>
                          item.question.question_desc.toLowerCase() !=
                            "technical skill 1" &&
                          item.question.question_desc.toLowerCase() !=
                            "technical skill 2" &&
                          item.question.question_desc.toLowerCase() !=
                            "technical skill 3" &&
                          item.question.question_desc.toLowerCase() !=
                            "soft skill 1" &&
                          item.question.question_desc.toLowerCase() !=
                            "soft skill 2" &&
                          item.question.question_desc.toLowerCase() !=
                            "soft skill 3"
                      )
                      .map((node) => {
                        if (
                          data?.responses != null &&
                          data?.responses?.length > 0
                        ) {
                          const responseExist = data.responses.find(
                            (response) =>
                              response.question_code ===
                              node.question.question_code
                          );
                          if (responseExist) {
                            node = {
                              id: responseExist.id,
                              question_code: responseExist.question_code,
                              question_desc: node.question.question_desc,
                              answers: node.answers,
                              is_checkbox: node.question.is_checkbox,
                              is_date_question: node.question.is_date_question,
                              is_int_question: node.question.is_int_question,
                              is_radio: node.question.is_radio,
                              is_text_question: node.question.is_text_question,
                              date_response: responseExist.date_response,
                              selected_answers: responseExist.selected_answers,
                              integer_response: responseExist.integer_response,
                              text_response: responseExist.text_response,
                              others: responseExist.others,
                              ...(node?.skill_ratings != undefined && {
                                skill_ratings: node?.skill_ratings,
                              }),
                            };
                          } else {
                            node = {
                              question_code: node.question.question_code,
                              question_desc: node.question.question_desc,
                              answers: node.answers,
                              is_checkbox: node.question.is_checkbox,
                              is_date_question: node.question.is_date_question,
                              is_int_question: node.question.is_int_question,
                              is_radio: node.question.is_radio,
                              is_text_question: node.question.is_text_question,
                              ...(node?.skill_ratings != undefined && {
                                skill_ratings: node?.skill_ratings,
                              }),
                            };
                          }
                        } else {
                          node = {
                            question_code: node.question.question_code,
                            question_desc: node.question.question_desc,
                            answers: node.answers,
                            is_checkbox: node.question.is_checkbox,
                            is_date_question: node.question.is_date_question,
                            is_int_question: node.question.is_int_question,
                            is_radio: node.question.is_radio,
                            is_text_question: node.question.is_text_question,
                            ...(node?.skill_ratings != undefined && {
                              skill_ratings: node?.skill_ratings,
                            }),
                          };
                        }

                        if (
                          data?.skill_ratings != null &&
                          data?.skill_ratings?.length > 0
                        ) {
                          const skillExist = data.skill_ratings.find(
                            (skillRating) => skillRating.response === node.id
                          );

                          if (skillExist) {
                            node = {
                              ...node,
                              skill_ratings: _.merge(node?.skill_ratings, [
                                {
                                  id: skillExist.id,
                                  rating: skillExist.rating,
                                },
                              ]),
                            };
                          }
                        }

                        return node;
                      })
                  : [],
            });
          });
        } else if (status == 404) {
          setError("root.serverError", {
            type: status,
            message: data,
          });
        } else {
          setError("root.serverError", {
            type: status,
            message: data?.message,
          });
        }
      })();
    }
  }, [internshipId, reset]);

  const onSubmit = async (data) => {
    const { status, data: responseData } = formUpdateMode
      ? await updateInternshipEvaluationForm(
          {
            internship_id: data.form.internship_id,
            form: _.omit(
              _.set(
                data.form,
                "acknowledgement",
                data.form.acknowledgement ? 1 : 0
              ),
              ["id", "internship_id"]
            ),
            responses: _.map(data.responses, (response) =>
              _.omit(response, [
                "question_desc",
                "is_checkbox",
                "is_date_question",
                "is_int_question",
                "is_radio",
                "is_text_question",
                "answers",
              ])
            ),
          },
          data.form.id
        )
      : await createInternshipEvaluationForm({
          internship_id: internshipId,
          form: _.omit(
            _.set(
              data.form,
              "acknowledgement",
              data.form.acknowledgement ? 1 : 0
            ),
            ["id", "internship_id"]
          ),
          responses: _.map(data.responses, (response) =>
            _.omit(response, [
              "question_desc",
              "is_checkbox",
              "is_date_question",
              "is_int_question",
              "is_radio",
              "is_text_question",
              "answers",
            ])
          ),
        });

    if (status === 200 || status === 201) {
      toast.success(responseData && responseData?.message, {
        position: toast.POSITION.TOP_RIGHT,
      });
      setTimeout(function () {
        navigate("/dashboard");
      }, 2000);
    } else {
      toast.error(responseData && responseData?.message, {
        position: toast.POSITION.TOP_RIGHT,
      });
    }
  };

  return loading ? (
    <div
      style={{
        width: "100vw",
        height: "100vh",
        display: "flex",
        justifyContent: "center",
        alignItems: "center",
      }}
    >
      <Loader />
    </div>
  ) : errors.root?.serverError != undefined ? (
    <div
      style={{
        width: "100vw",
        height: "100vh",
        display: "flex",
        justifyContent: "center",
        alignItems: "center",
      }}
    >
      {errors.root.serverError.message}
    </div>
  ) : (
    <>
      <LayoutWrapper>
        <PageContentSection>
          <CardWrapper>
            <FormHeaderWrapper>
              <FormHeadingText>{`Internship Evaluation`}</FormHeadingText>
              <SpanText>
                {`Complete your endorsement for National Structured Internship Programme (MySIP), by filling in the internship module.`}
              </SpanText>
            </FormHeaderWrapper>
            <FormProvider {...methods}>
              <form onSubmit={handleSubmit(onSubmit)}>
                {fields.map((field, index) => (
                  <>
                    <GroupWrapper>
                      <FormLabel>
                        {index + 1}
                        {". "}
                        {field.question_desc}
                      </FormLabel>
                      <div
                        style={{
                          marginTop: "1.5rem",
                          display: _.range(22, 32, 1).includes(
                            field.question_code
                          )
                            ? "flex"
                            : "block",
                          flexWrap: "wrap",
                        }}
                      >
                        {field.is_radio === true ? (
                          <>
                            {field.answers.map((answer) => (
                              <>
                                <Form.Check
                                  type={"radio"}
                                  defaultChecked={
                                    field?.skill_ratings != undefined &&
                                    field?.skill_ratings[0]?.rating ===
                                      parseInt(answer.answer_desc)
                                      ? true
                                      : answer.answer_desc ===
                                        field.text_response
                                      ? true
                                      : false
                                  }
                                  label={answer.answer_desc}
                                  value={answer.answer_desc}
                                  {...register(
                                    field?.skill_ratings != undefined
                                      ? `responses.${index}.skill_ratings.0.rating`
                                      : `responses.${index}.text_response`,
                                    {
                                      required: {
                                        value: true,
                                        message: "This field is required",
                                      },
                                    }
                                  )}
                                />
                              </>
                            ))}
                            {errors.responses?.[index]?.text_response && (
                              <Form.Control.Feedback type="invalid">
                                {
                                  errors.responses?.[index]?.text_response
                                    .message
                                }
                              </Form.Control.Feedback>
                            )}
                          </>
                        ) : field.is_checkbox === true ? (
                          <>
                            {field.answers.map((answer) => (
                              <>
                                <Form.Check
                                  type={"checkbox"}
                                  defaultChecked={
                                    field.hasOwnProperty("selected_answers") &&
                                    field.selected_answers.some(
                                      (element) =>
                                        element.answer_code ===
                                        answer.answer_code
                                    )
                                      ? true
                                      : false
                                  }
                                  label={answer.answer_desc}
                                  value={answer.answer_code}
                                  {...register(
                                    `responses.${index}.selected_answers`,
                                    {
                                      required: {
                                        value: true,
                                        message: "This field is required",
                                      },
                                      validate: (value) =>
                                        field.question_code === 34
                                          ? value.length >= 5 ||
                                            "At least five checkbox must be selected"
                                          : true,
                                    }
                                  )}
                                />
                              </>
                            ))}
                            <ConditionalInput {...{ control, index }} />
                            {errors.responses?.[index]?.selected_answers && (
                              <Form.Control.Feedback type="invalid">
                                {
                                  errors.responses?.[index]?.selected_answers
                                    .message
                                }
                              </Form.Control.Feedback>
                            )}
                          </>
                        ) : field.is_int_question === true ? (
                          <>
                            <Form.Control
                              type={"number"}
                              {...register(
                                `responses.${index}.integer_response`,
                                {
                                  required: {
                                    value: true,
                                    message: "This field is required",
                                  },
                                  min: {
                                    value: 0,
                                    message: "Must be a positive number",
                                  },
                                }
                              )}
                            />
                            {errors.responses?.[index]?.integer_response && (
                              <Form.Control.Feedback type="invalid">
                                {
                                  errors.responses?.[index]?.integer_response
                                    .message
                                }
                              </Form.Control.Feedback>
                            )}
                          </>
                        ) : field.is_text_question === true ? (
                          <>
                            <Form.Control
                              type={"text"}
                              {...register(`responses.${index}.text_response`, {
                                required: {
                                  value: true,
                                  message: "This field is required",
                                },
                              })}
                            />
                            {errors.responses?.[index]?.text_response && (
                              <Form.Control.Feedback type="invalid">
                                {
                                  errors.responses?.[index]?.text_response
                                    .message
                                }
                              </Form.Control.Feedback>
                            )}
                          </>
                        ) : field.is_date_question === true ? (
                          <>
                            <Controller
                              control={control}
                              name={
                                field.question_desc == "Internship Start Date"
                                  ? `form.internship_start_date`
                                  : field.question_desc == "Internship End Date"
                                  ? `form.internship_end_date`
                                  : `responses.${index}.date_response`
                              }
                              rules={{
                                required: {
                                  value: true,
                                  message: "This field is required",
                                },
                              }}
                              defaultValue={
                                field.question_desc == "Internship Start Date"
                                  ? getValues(`form.internship_start_date`)
                                  : field.question_desc == "Internship End Date"
                                  ? getValues(`form.internship_end_date`)
                                  : getValues(
                                      `responses.${index}.date_response`
                                    )
                              }
                              render={({ field }) => (
                                <label style={{ width: "max-content" }}>
                                  <MuiPickersUtilsProvider utils={DateFnsUtils}>
                                    <ThemeProvider theme={materialTheme}>
                                      <DatePicker
                                        {...field}
                                        autoOk
                                        variant="inline"
                                        format="dd/MM/yyyy"
                                        placeholder="dd/mm/yyyy"
                                        views={["year", "month", "date"]}
                                        fullWidth
                                        onChange={(value) =>
                                          field.onChange(formatDate(value))
                                        }
                                        sx={{
                                          ".MuiInputBase-input": {
                                            color: "black !important",
                                            fontWeight: "bold !important",
                                          },
                                        }}
                                        style={{}}
                                      />
                                    </ThemeProvider>
                                  </MuiPickersUtilsProvider>
                                  <CalenderIconWrapper>
                                    <CalenderIcon />
                                  </CalenderIconWrapper>
                                </label>
                              )}
                            />
                            {errors.responses?.[index]?.date_response && (
                              <Form.Control.Feedback type="invalid">
                                {
                                  errors.responses?.[index]?.date_response
                                    .message
                                }
                              </Form.Control.Feedback>
                            )}
                          </>
                        ) : null}
                      </div>
                    </GroupWrapper>
                  </>
                ))}
                <GroupWrapper>
                  <FormLabel>
                    <b>{`Terms & conditions`}</b>
                  </FormLabel>
                  <div style={{ marginTop: "1.5rem" }}>
                    <Controller
                      control={control}
                      name={`form.acknowledgement`}
                      rules={{ required: true }}
                      render={({ field }) => {
                        return (
                          <FormCheck>
                            <FormCheck.Input
                              {...field}
                              type={"checkbox"}
                              defaultChecked={getValues(`form.acknowledgement`)}
                            />

                            <FormCheck.Label
                              style={{
                                display: "flex",
                                paddingLeft: "10px",
                              }}
                            >
                              I hereby certify that the information contained
                              herein is true and accurate to the best of my
                              knowledge. I hereby consent for Talent Corporation
                              Malaysia Berhad (TalentCorp) to collect, store,
                              process and use my personal data contained herein
                              in accordance with the Personal Data Protection
                              Act 2010 for the purpose it was collected, which
                              includes but is not limited for administrative
                              purposes in connection with TalentCorp’s #MySIP
                              programme.
                            </FormCheck.Label>
                          </FormCheck>
                        );
                      }}
                    />
                    {errors.form?.acknowledgement && (
                      <Form.Control.Feedback type="invalid">
                        By posting this, you must agree to our terms and
                        conditions
                      </Form.Control.Feedback>
                    )}
                  </div>
                </GroupWrapper>
                <Button
                  as="input"
                  size="lg"
                  type="submit"
                  value={isSubmitting ? "Submitting..." : "Submit"}
                  disabled={isSubmitting}
                />
              </form>
            </FormProvider>
          </CardWrapper>
        </PageContentSection>
      </LayoutWrapper>
    </>
  );
}

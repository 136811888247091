import study from "../../assets/study.png";
import intern from "../../assets/intern.png";
import employe from "../../assets/employe.png";
import unemploye from "../../assets/unemployee.png";

export const talentStatus = {
  title: "Tell us more about yourself",
  description:
    "We will need some basic information about you before we get started",
  name: "What is your current status?",
  statuses: [
    {
      label: "Student",
      value: "studying",
      img: study,
      descriptions:
        "Currently studying and interested to explore mynext assessment and/or apply for internships via mynext",
    },

    {
      label: "Intern",
      value: "internship",
      img: intern,
      descriptions:
        "Currently undergoing internship programme and interested to explore mynext assessment",
    },

    {
      label: "Employed",
      value: "employed",
      img: employe,
      descriptions:
        "Currently employed and interested to explore mynext assessment",
    },

    {
      label: "Unemployed",
      value: "unemployed",
      img: unemploye,
      descriptions:
        "Currently unemployed and looking for internship opportunity and interested to explore mynext assessment",
    },
  ],
  err: "Please select a status.",
};

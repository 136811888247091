import { useState, useEffect } from "react";

import styled from "styled-components";
import { useNavigate } from "react-router-dom";
import { useForm, Controller } from "react-hook-form";
import { yupResolver } from "@hookform/resolvers/yup";
import { useSelector, useDispatch } from "react-redux";
import { ReactComponent as CalenderIcon } from "../../assets/icons/svg/calendarIcon.svg";
import Loader from "../../components/loader/loader";
import UserCreationLayout from "../../layouts/userCreation/userCreationLayout";
import IntroSection from "../../components/introSection/introSection";
import PaginationDivider from "../../components/paginationDivider/paginationDivider";
import internshipImg from "../../assets/internshipImg.png";
import { Colors } from "../../utilities/colors";
import RadioInput from "../../components/radioinput/radioinput";
import Dropdown from "../../components/dropdown/dropdown";
import ErrorField from "../../components/errorField/errorField";
import CancelIcon from "../../assets/icons/skillCancel.png";
import {
  studyingStaticContent,
  studySchema,
  internSchema,
} from "./stydyFormConstant";
import { mynextTalent } from "../mynextConstant";
import Input from "../../components/input/input";
import Button from "../../components/button/button";

import {
  onBoardStepTwoForm,
  onBoardFormSubmission,
  getOption,
  getUniversity,
  getCampus,
  getFaculty,
  getStudyPrograms,
  getScholarshipSubTypes,
  getAcademicQualifications,
  getScopeOfStudies,
  getYearOfStudies,
  getGrades,
  getAllCountries,
} from "../../services/apiCalls";
import { updateValues } from "../selfIntro/reducer";
import { removeKeys, formatDate } from "../../utilities/commonFunctions";
import { Col, Row } from "react-bootstrap";
import DateFnsUtils from "@date-io/date-fns";
import { MuiPickersUtilsProvider, DatePicker } from "@material-ui/pickers";

import { createTheme, IconButton } from "@material-ui/core";
import { ThemeProvider } from "@material-ui/styles";
import { useTranslation } from "react-i18next";

const materialTheme = createTheme({
  palette: {
    primary: {
      main: "#D44B9C",
    },
  },
  overrides: {
    MuiPickersCalendarHeader: {
      switchHeader: {
        color: "#D44B9C",
      },
    },
  },
});
// styles start
const FlexWrapper = styled.div`
  display: flex;
  align-items: center;
`;
const WidthWrapper = styled.div`
  width: ${(props) => (props.width ? props.width : "100%")};
  @media (max-width: 1024px) {
    width: 70%;
    margin-bottom: 20px;
  }
  @media (max-width: 599px) {
    width: 100%;
  }
`;
const FormWrapper = styled.div`
  margin: 30px 0 0 0;
  max-width: 100%;
  @media (max-width: 1024px) {
    max-width: 100%;
  }
`;
const LabelText = styled.label`
  font-style: normal;
  font-weight: 500;
  font-size: 18px;
  line-height: 24px;
  color: ${Colors.labelText};
  margin-bottom: 10px;
  display: block;
`;
const FormGroup = styled.div`
  margin-bottom: 25px;
`;
const ScholarshipWrapper = styled.div`
  display: flex;
  align-items: center;
  @media (max-width: 599px) {
    margin-bottom: 20px;
  }
  @media (max-width: 412px) {
   display:grid;
   grid-template-columns: 1fr 12fr;
   grid-row-gap:10px;
  }
`;
const InstitutionWrapper = styled(FlexWrapper)`
  @media (max-width: 1024px) {
    display: block;
  }
  justify-content: space-between;
  align-items: start;
`;
const DividerLine = styled.hr`
  border: 0.5px solid #ebebeb;
  margin: 30px 0;
`;
const EnglishTestWrapper = styled.div`
  margin-bottom: 10px;
`;
const DatesWrapper = styled(FlexWrapper)`
  label {
    position: relative;
    width: -webkit-fill-available;
  }
`;
const CalenderIconWrapper = styled.div`
  position: absolute;
  right: 1.5rem;
  top: 0.5rem;
  cursor: pointer;
`;
const InternWrapper = styled(FlexWrapper)`
  > div {
    margin-right: 20px;
  }
`;
const ButtonWrapper = styled(FlexWrapper)`
  justify-content: space-between;
  margin-top: 40px;
  @media (max-width: 600px) {
    flex-direction: column;
  }
  @media (max-width: 1024px) {
    justify-content: space-between;
  }
`;
const SaveButton = styled.p`
  font-weight: 500;
  font-size: 18px;
  line-height: 24px;
  text-decoration-line: underline;
  color: #504f8c;
  margin: 0;
  text-align: left;
  cursor: pointer;
  @media (max-width: 900px) {
    text-align: center;
  }
`;
// styling ends

export default function StudingPage() {
  const formValues = useSelector((state) => state.selfIntroOne.value);
  const value = formValues.talent_status;
  // const options = useSelector((state) => state.selfIntroOne.formOptions);
  const [formErrors, setFormErrors] = useState("");
  const [loading, setLoading] = useState(false);
  const [gradeInput, setGradeInput] = useState(false);
  const dispatch = useDispatch();
  const navigate = useNavigate();

  const [institutionList, setInstitutionList] = useState([]);
  const [campusList, setCampusList] = useState([]);
  const [facultyList, setFacultyList] = useState([]);
  const [programmeList, setProgrammeList] = useState([]);
  const [studyFormErrors, setStudyFormErrors] = useState(false);

  const [scholarshipSubtypes, setScholarshipSubtypes] = useState([]);
  const [selectedScholarshiptypes, setSelectedScholarshiptypes] = useState([]);

  const [curr_Qualification, setCurr_Qualification] = useState([]);
  const [selectedCurr_qualification, setSelectedCurr_qualification] = useState(
    []
  );

  const [scope_of_studies, setScope_of_studies] = useState([]);
  const [selectedScopeOfStudies, setSelectedScopeOfStudies] = useState([]);

  const [currStudyYear, setCurrStudyYear] = useState([]);
  const [selectcurrStudyYear, setSelectCurrStudyYear] = useState([]);

  const [grades, setGrade] = useState([]);
  const [selectedGrade, setSelectedGrade] = useState([]);
  const [disabledSatus, setDisableStatus] = useState(true);
  const [englishScore, setEnglishScore] = useState();
  const [campusDisable, setCampusDisable] = useState(false);
  const [facultyDisable, setFacultyDisable] = useState(false);
  const [programmeDisable, setProgrammeDisable] = useState(false);
  const [scholarDisable, setScholarDisable] = useState(false);

  const [min, setMin] = useState();
  const [max, setMax] = useState();
  const [thisCgpaMin, setThisCgpaMin] = useState(0.1);
  const [thisCgpaMax, setThisCgpaMax] = useState(10.0);
  const [thisIELTSMin, setThisIELTSMin] = useState(0.1);
  const [thisIELTSMax, setThisIELTSMax] = useState(10.0);
  const {
    control,
    handleSubmit,
    setValue,
    watch,
    resetField,
    setError,

    clearErrors,
    formState: { errors, values },
  } = useForm({
    defaultValues: { ...formValues },
    resolver: yupResolver(value === "studying" ? studySchema : internSchema),
    context: { campusDisable, facultyDisable, programmeDisable },
  });
  const formVal = watch();
  const [options1, setOptions1] = useState({});
  const [yearStudy, setYearStudy] = useState({});

  const [countries, setCountry] = useState([]);
  const [countryListExcludeDefault, setNMcountry] = useState([]);
  const [selectedCountry, setSelectedCountry] = useState({});

  const [selectedUniversity, setSelectedUniversity] = useState([]);
  const [selectedCampus, setSelectedCampus] = useState({});
  const [selectedFaculty, setSelectedFaculty] = useState({});
  const [selectedStudyProgramme, setSelectedStudyProgramme] = useState({});
  const [score, setScore] = useState();

  useEffect(() => {
    if (!window.allow_user_continue) navigate("/self-intro");
  }, [window.allow_user_continue]);

  const onErrorSubmit = () => {
    setStudyFormErrors(true);
  };

  const onSubmit = async () => {

     
    setLoading(true);
    const { data } = await onBoardStepTwoForm({
      ...formVal,
      talent_status: formValues.talent_status,
    });
    if (data && data.status === 1) {
   
      dispatch(updateValues({ ...formValues, ...formVal }));
      setLoading(false);
      setFormErrors("");
      setStudyFormErrors(false);
      window.allow_user_continue = true;
      navigate("/uploadpic");
    } else {
      setLoading(false);
      setFormErrors(data && data.message);
    }
  };
  const fetchUniversity = async () => {
    const { data, status } = await getUniversity();
    if (status === 200) {
      if (data.status === 1) {
        const institutionData = data.data;
        const institution = institutionData.map((x) => {
          return { ...x, label: x.Name, value: x.Id, data: x };
        });

        setInstitutionList(institution);
        if (formVal.insti_name) {
          let selInstitue = institution.filter(
            (x) => x.value == formVal.insti_name
          );
          // let selInstitue = institution.filter(x => x.value == mynextTalent.defaultUniversityId);

          if (formVal.insti_location_status === 0) {  // study abroad!
            if (typeof formVal.insti_name === "undefined") {
              formVal.insti_name = formVal.university
              formVal.insti_name_abbr = formVal.university;
            }

          } else { //Study locally in Malaysia
           
            if (selInstitue.length > 0) {
              selInstitue = selInstitue[0];
            }
          }
          setSelectedUniversity(selInstitue);
          fetchCampus(selInstitue.value);
        }
      }
    }
  };
  const fetchCampus = async (universityId) => {
    const { data, status } = await getCampus(universityId);
    if (status === 200) {
      if (data.status === 1) {
        const campusData = data.data;
        const campus = campusData.map((x) => {
          return { ...x, label: x.Name, value: x.Id, data: x };
        });
        if (campus.length == 0) {
          setCampusDisable(true);
          setFacultyDisable(true);
          setSelectedCampus(null);
          setSelectedFaculty(null);
          setSelectedStudyProgramme(null);
          setValue("campus", "");
          setValue("campus_abbr", "");
          setValue("faculty", "");
          setValue("faculty_abbr", "");
          setValue("study_program", "");
          setValue("study_program_abbr", "");
        } else {
          setCampusDisable(false);
          if (formVal.campus) {
            let selCampus = campus.filter((x) => x.value == formVal.campus);
            // let selCampus = campus.filter(x => x.value == mynextTalent.defaultCampusId);
            if (selCampus.length > 0) {
              selCampus = selCampus[0];
              setValue("campus", selCampus.value);
            }

            setSelectedCampus(selCampus);
            fetchFaculty(selCampus.value);
          }
        }
        setCampusList(campus);


      }
    }
  };

  const fetchFaculty = async (campusId) => {
    const { data, status } = await getFaculty(campusId);
    if (status === 200) {
      if (data.status === 1) {
        const facultyData = data.data;
        const faculty = facultyData.map((x) => {
          return { ...x, label: x.Name, value: x.Id, data: x };
        });
        if (faculty.length == 0) {
          setFacultyDisable(true);
          setValue("faculty", "");
          setValue("faculty_abbr", "");
        } else {
          setFacultyDisable(false);
          if (formVal.faculty) {
            let selFaculty = faculty.filter((x) => x.value == formVal.faculty);
            // let selFaculty = faculty.filter(x => x.value == mynextTalent.defaultFacultyId);
            if (selFaculty.length > 0) {
              selFaculty = selFaculty[0];
              setValue("faculty", selFaculty.value);
            }
            setSelectedFaculty(selFaculty);
            fetchStudyProgram(selFaculty.value);
          }
        }
        setFacultyList(faculty);
        

      }
    }
  };
  const fetchStudyProgram = async (facultyId) => {
    const { data, status } = await getStudyPrograms(facultyId);
    if (status === 200) {
      if (data.status === 1) {
        const programmeList = data.data;
        const programme = programmeList.map((x) => {
          return { ...x, label: x.Name, value: x.Id };
        });

        if (programme.length == 0) {
          setProgrammeDisable(true);
          setValue("study_program", "");
          setValue("study_program_abbr", "");
        } else {
          setProgrammeDisable(false);
          if (formVal.study_program) {
            let selStudyProgram = programme.filter(
              (x) => x.value == formVal.study_program
            );
            // let selStudyProgram = programme.filter(x => x.value == mynextTalent.defaultStudyProgramme);
            if (selStudyProgram.length > 0) {
              selStudyProgram = selStudyProgram[0];
              setValue("study_program", selStudyProgram.value);
            }
            setSelectedStudyProgramme(selStudyProgram);
          }
        }

        setProgrammeList(programme);

      }
    }
  };
  const fetchCountry = async () => {
    //const { data, status } = await getCountries();
    const { data, status } = await getAllCountries();
    if (status === 200) {
      const location = data.data;
      const country = location.map((x) => {
        return { ...x, label: x.Name, value: x.Id };
      });
      setCountry(country);

      const countryListExcludeDefault = country.filter(
        (x) => x.value != mynextTalent.defaultCountryId
      );

      setNMcountry(countryListExcludeDefault);

      // Edit mode
      if (formVal.insti_country) {
        let selCountry = country.filter(
          (x) => x.value == formVal.insti_country
        );
        // let selCountry = country.filter(x => x.value == mynextTalent.defaultCountryId);
        if (selCountry.length > 0) {
          selCountry = selCountry[0];
          setValue("insti_country", selCountry.value);
        }
        setSelectedCountry(selCountry);
      }
    }
  };

  const radioChange = async (value, name) => {
    setValue(name, value);
    let Key_term = value;
    switch (name) {
      case "english_tests":
        setValue("english_score", "");
        setEnglishScore("");
        if (value === "TOEFL") {
          setMin(0);
          setMax(30);
        } else if (value === "IELTS") {
          setMin(0);
          setMax(9);
        } else {
          setMin(null);
          setMax(null);
        }
        if (value === "I have not taken any tests") {
          setValue("englishscore", "I have not taken any tests");
          setValue("english_score", "I have not taken any tests")
        }
        break;
      case "grade_status":
        setValue("grade", "");
        if (value === "Grades") {
          setGradeInput(true);
        } else {
          setGradeInput(false);
        }
        if (value === "No Current Grade") {
          setValue("grade_abbr", "No Current Grade");
          setValue("grade", "No Current Grade")
        }
        break;
      case "insti_location_status":
        setValue("insti_location_status", value);

        setValue("insti_country", "");
        setValue("university", "");

        setValue("insti_name", "");
        setValue("campus", "");
        setValue("study_program", "");
        setValue("faculty", "");
        setValue("instiName", "");

        setValue("campus_abbr", "");
        setValue("insti_name_abbr", "");
        setValue("faculty_abbr", "");
        setValue("study_program_abbr", "");
        // fetchUniversity();
        // setInstitutionList([]);
        // setCampusList([]);
        // setFacultyList([]);
        // setProgrammeList([]);

        setSelectedUniversity(null);
        setSelectedCampus(null);
        setSelectedFaculty(null);
        setSelectedStudyProgramme(null);

        setSelectedCountry(null);
       

        break;
      default:
        break;
    }

    if (name === "scholar_status" && value === 1) {
      setScholarDisable(true);
      setValue("scholar_data", "");
    } else if (name === "scholar_status" && value === 0) {
      setScholarDisable(false);
    }
  };

  const dropdownChange = (name, val) => {
    setValue(name, val.value);

    switch (name) {
      case "scholar_data":
        setDisableStatus(false);
        setSelectedScholarshiptypes(val);
        dispatch(
          updateValues({
            ...formValues,
            ...formVal,
            [name]: val.value,
          })
        );
        break;
      case "curr_qualification":
        setDisableStatus(false);
        setSelectedCurr_qualification(val);
        setValue("curr_qualification_abbr", val.label);
        dispatch(
          updateValues({
            ...formValues,
            ...formVal,
            [name]: val.value,
          })
        );
        break;
      case "scope":
        setDisableStatus(false);
        setSelectedScopeOfStudies(val);
        setValue("scope_abbr", val.label);
        dispatch(
          updateValues({
            ...formValues,
            ...formVal,
            [name]: val.value,
          })
        );
        break;

      case "curr_study_year":
        setDisableStatus(false);
        setSelectCurrStudyYear(val);
        setValue("curr_study_year_abbr", val.label);

        dispatch(
          updateValues({
            ...formValues,
            ...formVal,
            [name]: val.value,
          })
        );
        break;
      case "grade":
        setDisableStatus(false);
        setSelectedGrade(val);
        setValue("grade_abbr", val.label);
        dispatch(
          updateValues({
            ...formValues,
            ...formVal,
            [name]: val.value,
          })
        );
        break;
      case "insti_name":
        setCampusDisable(true);
        setFacultyDisable(true);
        setValue("campus", "");
        setValue("campus_abbr", "");
        setValue("faculty", "");
        setValue("study_program", "");
        setValue("insti_name_abbr", val.label);
        setSelectedUniversity(val);
        setSelectedCampus(null);
        setSelectedFaculty(null);
        setSelectedStudyProgramme(null);
        formVal.campus = "";
        formVal.faculty = "";
        formVal.study_program = "";
        fetchCampus(val.value);
        break;
      case "campus":
        setFacultyDisable(true);
        setValue("faculty", "");
        setValue("faculty_abbr", "");
        setValue("study_program", "");
        setValue("campus_abbr", val.label);
        setSelectedCampus(val);
        setSelectedFaculty(null);
        setSelectedStudyProgramme(null);
        formVal.faculty = "";
        formVal.study_program = "";
        fetchFaculty(val.value);

        break;
      case "faculty":
        setValue("faculty_abbr", val.label);
        setValue("study_program", "");
        setValue("study_program_abbr", "");
        setSelectedFaculty(val);
        setSelectedStudyProgramme(null);
        formVal.study_program = "";
        fetchStudyProgram(val.value);
        break;
      case "study_program":
        setValue("study_program_abbr", val.label);
        setSelectedStudyProgramme(val);
        break;
      case "english_score":
        setEnglishScore(val.value);
        break;
      case "insti_country":
        setSelectedCountry(val);
        setValue("insti_country_abbr", val.label);
        break;
      default:
    }
  };

  const clearDate = (e, name) => {
    e.preventDefault();
    setValue(name, "");
  };

  const getOptions = async (name) => {
    // setValue(name, val.value);
    let key_term = name;
    const { data, status } = await getOption({ search_key: "" }, key_term);
    if (data.data.length > 0) {
      let newData = data.data.map((x) => {
        return {
          label: x,
          value: x,
        };
      });
      let obj = { ...options1 };
      obj[name] = newData;
      setOptions1(obj);
    } else {
      return null;
    }
  };

  const onNavigate = (path) => {
    navigate(path);
  };

  const onSave = async () => {
    setLoading(true);
    const req = removeKeys({ ...formVal });
    const { data } = await onBoardFormSubmission({
      ...req,
      step: formValues.profile_picture ? 4 : 3,
      // step: 3
    });
    if (data && data.status === 1) {

      //---------
      if (formVal.insti_location_status === 0) {
        //study abroad
        if (formVal.insti_name) {
          formVal.insti_name = ''
          formVal.insti_name_abbr = formVal.university
        }
      } else if (formVal.insti_location_status === 1) {
        //study Locally
        if (formVal.university) {
          formVal.university = ""
        }
      }


      dispatch(updateValues({ ...formValues, ...formVal }));
      // dispatch(updateValues({ ...formVal, ...formValues }));
   
      //----------  
      navigate("/dashboard");
      setLoading(false);
      setFormErrors("");
    } else {
      setLoading(false);
      setFormErrors(data && data.message);
    }
  };

  const dateChange = (name, val) => {
    setValue(name, formatDate(val));
  };

  // fetching scholarship_subtypes
  const fetchScholarshipSubtypes = async () => {
    const { data, status } = await getScholarshipSubTypes();
    if (status === 200) {
      const Scholarshiptypes = data.data;
      const Scholarship = Scholarshiptypes.map((x) => {
        return { ...x, label: x.Title, value: x.Id };
      });
      setScholarshipSubtypes(Scholarship);

      // Edit mode
      if (formVal.scholar_data) {
        let selScholarshipSubtypes = Scholarship.filter(
          (x) => x.value == formVal.scholar_data
        );
        if (selScholarshipSubtypes.length > 0) {
          selScholarshipSubtypes = selScholarshipSubtypes[0];
          setValue("scholar_data", selScholarshipSubtypes.value);
        }
        setSelectedScholarshiptypes(selScholarshipSubtypes);
      }
    }
  };

  // fetching curr_qualification data's
  const fetchCurrQualification = async () => {
    const { data, status } = await getAcademicQualifications();
    if (status === 200) {
      const qualifications = data.data;
      const curr_qualifications = qualifications.map((x) => {
        return { ...x, label: x.Name, value: x.Id };
      });
      setCurr_Qualification(curr_qualifications);

      // Edit mode
      if (formVal.curr_qualification) {
        let selCurrqualification = curr_qualifications.filter(
          (x) => x.value == formVal.curr_qualification
        );
        if (selCurrqualification.length > 0) {
          selCurrqualification = selCurrqualification[0];
          setValue("curr_qualification", selCurrqualification.value);
        }
        setSelectedCurr_qualification(selCurrqualification);
      }
    }
  };

  // fetching scholarshipTypes
  const fetchScopeOfStudies = async () => {
    const { data, status } = await getScopeOfStudies();
    if (status === 200) {
      const Scopes = data.data;
      const Scope_Of_Studies = Scopes.map((x) => {
        return { ...x, label: x.Name, value: x.Id };
      });
      setScope_of_studies(Scope_Of_Studies);

      // Edit mode

      if (formVal.scope) {
        let selScope = Scope_Of_Studies.filter((x) => x.value == formVal.scope);
        if (selScope.length > 0) {
          selScope = selScope[0];
          setValue("scope", selScope.value);
        }
        setSelectedScopeOfStudies(selScope);
      }
    }
  };
  // fetching year_of_studies
  const fetchYearOfStudies = async () => {
    const { data, status } = await getYearOfStudies();
    if (status === 200) {
      const yearOfStudies = data.data;
      const Year_Of_Studies = yearOfStudies.map((x) => {
        return { ...x, label: x.Name, value: x.Id };
      });
      setCurrStudyYear(Year_Of_Studies);

      // Edit mode
      if (formVal.curr_study_year) {
        let selCurrstudyyear = Year_Of_Studies.filter(
          (x) => x.value == formVal.curr_study_year
        );
        if (selCurrstudyyear.length > 0) {
          selCurrstudyyear = selCurrstudyyear[0];
          setValue("curr_study_year", selCurrstudyyear.value);
        }
        setSelectCurrStudyYear(selCurrstudyyear);
      }
    }
  };

  const fetchGrade = async () => {
    const { data, status } = await getGrades();
    if (status === 200) {
      const gradesList = data.data;
      const grades = gradesList.map((x) => {
        return { ...x, label: x.Title, value: x.Id };
      });
      setGrade(grades);

      // Edit mode
      if (formVal.grade) {
        let selGrade = grades.filter((x) => x.value == formVal.grade);
        if (selGrade.length > 0) {
          selGrade = selGrade[0];
          setValue("grade", selGrade.value);
        }
        setSelectedGrade(selGrade);
      }
    }
  };

  useEffect(() => {
    fetchUniversity();
    fetchCountry();
    fetchCurrQualification();
    fetchScopeOfStudies();
    fetchYearOfStudies();
    fetchGrade();
    fetchScholarshipSubtypes();
  }, []);

  const handleChange = (e) => {
    e.stopPropagation();
    e.preventDefault();
    const value = e.target.value.replace(/[e+-]/gi, "");
    //setScore(value);
  };
  const onInputChange = (university, e) => {
    const { value } = e.target;

    if (value === "") {
      setValue(university, value);
    }
  };

  const setEnglishValue = (val) => {
    setValue("english_score", val);
    setValue("englishscore", val);
  };
  const setGradeValue = (val) => {
    setValue("grade", val);
    setValue("grade_abbr", val);
  };

  const { t } = useTranslation();

  return (
    <UserCreationLayout sidebarImg={internshipImg}>
      <IntroSection
        name={t(studyingStaticContent.title)}
        description={t(studyingStaticContent.description)}
      />
      <PaginationDivider step={2} />
      <FormWrapper>
        {/* scholarship fields starts */}
        <Row>
          <FormGroup>
            <LabelText>{t(studyingStaticContent.scholarshipTitle)}</LabelText>
            <Row>
              <Col lg="12" md="12" style={{ padding: "0px" }}>
                <ScholarshipWrapper>
                  <Controller
                    name="scholar_status"
                    control={control}
                    render={({ field }) =>
                      studyingStaticContent.scholarshipTypes.map(
                        (identity, index) => (
                          <RadioInput
                            key={identity.label + index}
                            {...field}
                            checked={identity.value === formVal.scholar_status}
                            onChange={() =>
                              radioChange(identity.value, "scholar_status")
                            }
                            label={t(identity.label)}
                            margin="20px"
                            name="scholar_status"
                          />
                        )
                      )
                    }
                  />
                </ScholarshipWrapper>
              </Col>
            </Row>
            <Row>
              <Col lg="12" style={{ padding: "0px" }}>
                <Controller
                  name="scholar_data"
                  control={control}
                  render={({ field }) => (
                    <Dropdown
                      options={scholarshipSubtypes}
                      placeholder={t("If Scholarship/Loan please select")}
                      change={(val) => dropdownChange("scholar_data", val)}
                      {...field}
                      val={
                        formVal.scholar_status === 1
                          ? null
                          : selectedScholarshiptypes.value
                      }
                      label={selectedScholarshiptypes.label}
                      disable={formVal.scholar_status === 1 ? true : false}
                      refCallback={field?.ref}
                      ref={null}
                    />
                  )}
                />
                {errors.scholar_data && formVal.scholar_status === 0 && (
                  <ErrorField err={t(errors.scholar_data.message)} />
                )}
              </Col>
            </Row>
          </FormGroup>
        </Row>

        <Row>
          <Col>
            <FormGroup>
              <LabelText>{t(studyingStaticContent.curr_qualification)}</LabelText>
              <Controller
                name="curr_qualification"
                control={control}
                render={({ field }) => (
                  <Dropdown
                    search={true}
                    // options={selectState && selectState.curr_country}
                    options={curr_Qualification}
                    placeholder="Select"
                    change={(val) => dropdownChange("curr_qualification", val)}
                    //val={formVal.state}
                    val={selectedCurr_qualification.value}
                    label={selectedCurr_qualification.label}
                    //disable={formVal.curr_qualification ? false : true}
                    // onFocus={() => {getOptions('SL')}}
                    {...field}
                    refCallback={field?.ref}
                    ref={null}
                  />
                )}
              />
              {errors.curr_qualification && (
                <ErrorField err={t(errors.curr_qualification.message)} />
              )}
            </FormGroup>
          </Col>
        </Row>

        {/* scholarship fields ends */}

        <DividerLine />

        {/* institution starts */}
        <Row>
          <Col>
            <FormGroup>
              <LabelText>{t(studyingStaticContent.insti_location)}</LabelText>
              <InstitutionWrapper>
                <Controller
                  name="insti_location_status"
                  control={control}
                  render={({ field }) =>
                    studyingStaticContent.institutionTypes.map(
                      (type, index) => (
                        <WidthWrapper
                          {...field}
                          width={type.value === 1 ? "42%" : "58%"}
                        >
                          <RadioInput
                            checked={
                              type.value === formVal.insti_location_status
                            }
                            onChange={() =>
                              radioChange(type.value, "insti_location_status")
                            }

                            key={type.label + index}
                            label={t(type.label)}
                          />
                        </WidthWrapper>
                      )
                    )
                  }
                />
              </InstitutionWrapper>
              {errors.insti_location_status && (
                <ErrorField err={t(errors.insti_location_status.message)} />
              )}
            </FormGroup>
          </Col>
        </Row>

        {formVal.insti_location_status === 0 && (
          <>
            <Row>
              <Col>
                <FormGroup>
                  <LabelText>{t(studyingStaticContent.country)}</LabelText>
                  <Controller
                    name="insti_country"
                    control={control}
                    render={({ field }) => (
                      <Dropdown
                        search={true}
                        options={countryListExcludeDefault}
                        placeholder={t("Select country")}
                        //val={formVal.insti_country}
                        val={selectedCountry?.value}
                        label={selectedCountry?.label}
                        // onFocus={() => {
                        //   getOptions("CAQS");
                        // }}
                        change={(val) => dropdownChange("insti_country", val)}
                        {...field}
                        refCallback={field?.ref}
                        ref={null}
                      />
                    )}
                  />
                  {errors.insti_country && (
                    <ErrorField err={t(errors.insti_country.message)} />
                  )}
                </FormGroup>
              </Col>
            </Row>
            <Row>
              <Col>
                <FormGroup>
                  <LabelText>{t(studyingStaticContent.university)}</LabelText>
                  <Controller
                    name="university"
                    control={control}
                    render={({ field }) => (
                      <Input
                        placeholder={t("University")}
                        {...field}
                        value={formVal.university}
                      />
                    )}
                  />
                  {errors.university && (
                    <ErrorField err={t(errors.university.message)} />
                  )}
                </FormGroup>
              </Col>
            </Row>
          </>
        )}

        {formVal.insti_location_status === 1 && (
          // formVal.insti_location_status === "" ? (
          <>
            <Row>
              <Col>
                <FormGroup>
                  <LabelText>{t(studyingStaticContent.instiName)}</LabelText>
                  <Controller
                    name="insti_name"
                    control={control}
                    render={({ field }) => (
                      <Dropdown
                        search={true}
                        options={institutionList}
                        placeholder={t("Select the institute")}
                        //val={formVal.insti_name}
                        val={selectedUniversity?.value}
                        label={selectedUniversity?.label}
                        change={(val) => dropdownChange("insti_name", val)}
                        {...field}
                        refCallback={field?.ref}
                        ref={null}
                      />
                    )}
                  />
                  {errors.insti_name && (
                    <ErrorField err={t(errors.insti_name.message)} />
                  )}
                </FormGroup>
              </Col>
            </Row>
            <Row>
              <Col>
                <FormGroup>
                  <LabelText>{t(studyingStaticContent.campus)}</LabelText>
                  <Controller
                    name="campus"
                    control={control}
                    render={({ field }) => (
                      <Dropdown
                        search={true}
                        options={campusList}
                        placeholder={t("Select the campus")}
                        //val={formVal.campus}
                        val={selectedCampus?.value}
                        label={selectedCampus?.label}
                        //disable={formVal.insti_name ? false : true}
                        disable={campusDisable}
                        change={(val) => dropdownChange("campus", val)}
                        {...field}
                        refCallback={field?.ref}
                        ref={null}
                      />
                    )}
                  />
                  {errors.campus && <ErrorField err={t(errors.campus.message)} />}
                </FormGroup>
              </Col>
            </Row>
            <Row>
              <Col>
                <FormGroup>
                  <LabelText>{t(studyingStaticContent.faculty)}</LabelText>
                  <Controller
                    name="faculty"
                    control={control}
                    render={({ field }) => (
                      <Dropdown
                        search={true}
                        options={facultyList}
                        placeholder={t("Select the faculty")}
                        val={
                          campusDisable || facultyDisable
                            ? ""
                            : selectedFaculty?.value
                        }
                        label={selectedFaculty?.label}
                        disable={campusDisable || facultyDisable}
                        change={(val) => dropdownChange("faculty", val)}
                        {...field}
                        refCallback={field?.ref}
                        ref={null}
                      />
                    )}
                  />
                  {errors.faculty && (
                    <ErrorField err={t(errors.faculty.message)} />
                  )}
                </FormGroup>
              </Col>
            </Row>
            <Row>
              <Col>
                <FormGroup>
                  <LabelText>{t(studyingStaticContent.study)}</LabelText>
                  <Controller
                    name="study_program"
                    control={control}
                    render={({ field }) => (
                      <Dropdown
                        search={true}
                        options={programmeList}
                        placeholder={t("Select the study programme")}
                        val={
                          campusDisable || facultyDisable || programmeDisable
                            ? ""
                            : selectedStudyProgramme?.value
                        }
                        disable={
                          campusDisable || facultyDisable || programmeDisable
                        }
                        label={selectedStudyProgramme?.label}
                        change={(val) => dropdownChange("study_program", val)}
                        {...field}
                        refCallback={field?.ref}
                        ref={null}
                      />
                    )}
                  />
                  {formValues.study_program_abbr !== "N/A"
                    ? errors.study_program && (
                      <ErrorField err={t(errors.study_program.message)} />
                    )
                    : ""}
                </FormGroup>
              </Col>
            </Row>
          </>
          // ) : (
          //   ""
        )}
        {/* institution ends  */}

        <DividerLine />

        {/* scope of education starts */}
        <Row>
          <Col>
            <FormGroup>
              <LabelText>{t(studyingStaticContent.scope)}</LabelText>
              <Controller
                name="scope"
                control={control}
                render={({ field }) => (
                  <Dropdown
                    search={true}
                    options={scope_of_studies}
                    placeholder={t("Please select")}
                    change={(val) => dropdownChange("scope", val)}
                    val={selectedScopeOfStudies.value}
                    label={selectedScopeOfStudies.label}
                    disabled={scope_of_studies.length ? true : false}
                    {...field}
                    refCallback={field?.ref}
                    ref={null}
                  />
                )}
              />
              {errors.scope && <ErrorField err={t(errors.scope.message)} />}
            </FormGroup>
          </Col>
        </Row>
        {/* <DatesWrapper> */}
        <Row>
          <Col lg="6" sm="12" md="6" xl="4">
            {/* <FormGroup> */}
            <FormGroup>
              <LabelText>{t(studyingStaticContent.enrollmentDate)}</LabelText>
              <DatesWrapper>
                <Controller
                  name="univ_enroll_date"
                  control={control}
                  render={({ field }) => (
                    <>
                      <label>
                        <MuiPickersUtilsProvider utils={DateFnsUtils}>
                          <ThemeProvider theme={materialTheme}>
                            <DatePicker
                              autoOk
                              disableFuture
                              variant="inline"
                              format="dd/MM/yyyy"
                              placeholder={t("dd/mm/yyyy")}
                              views={["year", "month", "date"]}
                              value={
                                formVal.univ_enroll_date
                                  ? new Date(formVal.univ_enroll_date)
                                  : null
                              }
                              onChange={(val) =>
                                dateChange("univ_enroll_date", val)
                              }
                              fullWidth
                            />
                          </ThemeProvider>
                        </MuiPickersUtilsProvider>
                        <CalenderIconWrapper>
                          <CalenderIcon />
                          {formVal.univ_enroll_date && (
                            <IconButton
                              onClick={(e) => clearDate(e, "univ_enroll_date")}
                            >
                              <img src={CancelIcon} alt="" />
                            </IconButton>
                          )}
                        </CalenderIconWrapper>
                      </label>
                    </>
                  )}
                />
              </DatesWrapper>
              {errors.univ_enroll_date && (
                <ErrorField err={t(errors.univ_enroll_date.message)} />
              )}
            </FormGroup>
          </Col>
          <Col lg="6" sm="12" md="6" xl="4">
            <FormGroup>
              <LabelText>{t(studyingStaticContent.graduationDate)}</LabelText>
              <DatesWrapper>
                <Controller
                  name="exp_graduate_date"
                  control={control}
                  render={({ field }) => (
                    <>
                      <label>
                        <MuiPickersUtilsProvider utils={DateFnsUtils}>
                          <ThemeProvider theme={materialTheme}>
                            <DatePicker
                              autoOk
                              variant="inline"
                              format="dd/MM/yyyy"
                              placeholder={t("dd/mm/yyyy")}
                              views={["year", "month", "date"]}
                              value={
                                formVal.exp_graduate_date
                                  ? new Date(formVal.exp_graduate_date)
                                  : null
                              }
                              minDate={new Date(
                                formVal.univ_enroll_date
                              ).setDate(
                                new Date(formVal.univ_enroll_date).getDate()
                              )}
                              onChange={(val) =>
                                dateChange("exp_graduate_date", val)
                              }
                              fullWidth
                            />
                          </ThemeProvider>
                        </MuiPickersUtilsProvider>
                        <CalenderIconWrapper>
                          <CalenderIcon />
                          {formVal.exp_graduate_date && (
                            <IconButton
                              onClick={(e) => clearDate(e, "exp_graduate_date")}
                            >
                              <img src={CancelIcon} alt="" />
                            </IconButton>
                          )}
                        </CalenderIconWrapper>
                      </label>
                    </>
                  )}
                />
              </DatesWrapper>
              {errors.exp_graduate_date && (
                <ErrorField err={t(errors.exp_graduate_date.message)} />
              )}
            </FormGroup>
          </Col>
        </Row>
        {/* </DatesWrapper> */}
        <Row>
          <Col xl="4" lg="6" md="6">
            <FormGroup>
              <LabelText>{t(studyingStaticContent.yearOfStudy)}</LabelText>
              <Controller
                name="curr_study_year"
                control={control}
                render={({ field }) => (
                  <Dropdown
                    search={true}
                    options={currStudyYear}
                    placeholder={t("Please select")}
                    //val={formVal.curr_study_year}
                    change={(val) => dropdownChange("curr_study_year", val)}
                    {...field}
                    val={selectcurrStudyYear?.value}
                    label={selectcurrStudyYear?.label}
                    disabled={formVal.scope ? false : true}
                    refCallback={field?.ref}
                    ref={null}
                  />
                )}
              />
              {errors.curr_study_year && (
                <ErrorField err={t(errors.curr_study_year.message)} />
              )}
            </FormGroup>
          </Col>
        </Row>

        {/* scope of education ends */}

        <DividerLine />

        {/* intern starts */}

        {value === "internship" && (
          <>
            <LabelText>{t(studyingStaticContent.internTitle)}</LabelText>
            <Row>
              <Col xl="4" lg="6" md="6" sm="6">
                <FormGroup>
                  {/* <Controller
                    name="intern_dur_year"
                    control={control}
                    render={({ field }) => (
                      <Input type="number" placeholder="Years" {...field} />
                    )}
                  /> */}
                  <LabelText>{t("Start Date*")}</LabelText>
                  <DatesWrapper>
                    <Controller
                      name="intern_dur_year"
                      control={control}
                      render={({ field }) => (
                        <>
                          <label>
                            <MuiPickersUtilsProvider utils={DateFnsUtils}>
                              <ThemeProvider theme={materialTheme}>
                                <DatePicker
                                  autoOk
                                  variant="inline"
                                  format="dd/MM/yyyy"
                                  placeholder={t("dd/mm/yyyy")}
                                  views={["year", "month", "date"]}
                                  value={
                                    !formVal.intern_dur_year
                                      ? null
                                      : formVal.intern_dur_year === "None"
                                        ? null
                                        : formVal.intern_dur_year === ""
                                          ? null
                                          : new Date(formVal.intern_dur_year)
                                  }
                                  onChange={(val) =>
                                    dateChange("intern_dur_year", val)
                                  }
                                  fullWidth
                                />
                              </ThemeProvider>
                            </MuiPickersUtilsProvider>
                            <CalenderIconWrapper>
                              <CalenderIcon />
                              {formVal.intern_dur_year && (
                                <IconButton
                                  onClick={(e) =>
                                    clearDate(e, "intern_dur_year")
                                  }
                                >
                                  <img src={CancelIcon} alt="" />
                                </IconButton>
                              )}
                            </CalenderIconWrapper>
                          </label>
                        </>
                      )}
                    />
                  </DatesWrapper>
                  {errors.intern_dur_year && (
                    <ErrorField err={t(errors.intern_dur_year.message)} />
                  )}
                </FormGroup>
              </Col>

              <Col xl="4" lg="6" md="6" sm="6">
                <FormGroup>
                  <LabelText>{t("End Date*")}</LabelText>
                  {/* <Controller
                    name="intern_dur_month"
                    control={control}
                    render={({ field }) => (
                      <Input type="number" placeholder="Months" {...field} />
                    )}
                  /> */}
                  <DatesWrapper>
                    <Controller
                      name="intern_dur_month"
                      control={control}
                      render={({ field }) => (
                        <>
                          <label>
                            <MuiPickersUtilsProvider utils={DateFnsUtils}>
                              <ThemeProvider theme={materialTheme}>
                                <DatePicker
                                  autoOk
                                  variant="inline"
                                  format="dd/MM/yyyy"
                                  placeholder={t("dd/mm/yyyy")}
                                  views={["year", "month", "date"]}
                                  value={
                                    !formVal.intern_dur_month
                                      ? null
                                      : formVal.intern_dur_month &&
                                        formVal.intern_dur_month === "None"
                                        ? null
                                        : formVal.intern_dur_month === ""
                                          ? null
                                          : new Date(formVal.intern_dur_month)
                                  }
                                  minDate={new Date(
                                    formVal.intern_dur_year
                                  ).setDate(
                                    new Date(formVal.intern_dur_year).getDate()
                                  )}
                                  onChange={(val) =>
                                    dateChange("intern_dur_month", val)
                                  }
                                  fullWidth
                                />
                              </ThemeProvider>
                            </MuiPickersUtilsProvider>
                            <CalenderIconWrapper>
                              <CalenderIcon />
                              {formVal.intern_dur_month && (
                                <IconButton
                                  onClick={(e) =>
                                    clearDate(e, "intern_dur_month")
                                  }
                                >
                                  <img src={CancelIcon} alt="" />
                                </IconButton>
                              )}
                            </CalenderIconWrapper>
                          </label>
                        </>
                      )}
                    />
                  </DatesWrapper>
                  {errors.intern_dur_month && (
                    <ErrorField err={t(errors.intern_dur_month.message)} />
                  )}
                </FormGroup>
              </Col>
            </Row>
            <Row>
              <Col>
                <FormGroup>
                  <LabelText>{t(studyingStaticContent.job)}</LabelText>
                  <Controller
                    name="intern_position"
                    control={control}
                    render={({ field }) => (
                      <Input
                        type="text"
                        placeholder={t("Position/Job title")}
                        {...field}
                      />
                    )}
                  />
                  {errors.intern_position && (
                    <ErrorField err={t(errors.intern_position.message)} />
                  )}
                </FormGroup>
              </Col>
            </Row>
            <Row>
              <Col>
                <FormGroup>
                  <LabelText>{t(studyingStaticContent.allowance)}</LabelText>
                  <Controller
                    name="intern_allowance"
                    control={control}
                    render={({ field }) => (
                      <Input
                        type="number"
                        placeholder={t("Salary (RM per month before tax)")}
                        onKeyPress={(event) => {
                          if (!/[0-9]/.test(event.key)) {
                            event.preventDefault();
                          }
                          if (event.target.value.length > 5) {
                            event.preventDefault();
                          }
                        }}
                        {...field}
                      // min={0}
                      // onChange={(e) => limitMaxAllowance(e)}
                      // max="99"
                      />
                    )}
                  />
                  {errors.intern_allowance && (
                    <ErrorField err={t(errors.intern_allowance.message)} />
                  )}
                </FormGroup>
              </Col>
            </Row>
            <DividerLine />
          </>
        )}

        {/* intern ends */}

        {/* grade starts */}
        <Row>
          <Col>
            <FormGroup>
              <LabelText>{t(studyingStaticContent.gradeTitle)}</LabelText>
              <ScholarshipWrapper>
                <Controller
                  name="grade_status"
                  control={control}
                  render={({ field }) =>
                    studyingStaticContent.grades.map((grade, index) => (
                      <RadioInput
                        key={grade + index}
                        {...field}
                        checked={grade === formVal.grade_status}
                        onChange={() => radioChange(grade, "grade_status")}
                        label={t(grade)}
                      />
                    ))
                  }
                />
              </ScholarshipWrapper>
            </FormGroup>
          </Col>
        </Row>

        <Row>
          <Col>
            <FormGroup>
              <Controller
                name="grade"
                control={control}
                render={({ field }) => (
                  <>
                    {gradeInput || formVal.grade_status == "Grades" ? (
                      <Dropdown
                        search={true}
                        options={grades}
                        placeholder={t("Please select a grade")}
                        change={(val) => dropdownChange("grade", val)}
                        val={selectedGrade?.value}
                        label={selectedGrade?.label}
                        disabled={formVal.grade ? false : true}
                        {...field}
                        refCallback={field?.ref}
                        ref={null}
                      />
                    ) : formVal.grade_status === "CGPA" ? (
                      <Input
                        placeholder={t("Please enter CGPA")}
                        type="number"
                        onKeyPress={(event) => {
                          if (!/[0-9.]/.test(event.key)) {
                            event.preventDefault();
                          }
                          if (event.target.value.length > 3) {
                            event.preventDefault();
                          }
                        }}
                        min={thisCgpaMin}
                        max={thisCgpaMax}
                        step={thisCgpaMin}
                        value={formVal.grade}
                        onChange={(e) => setGradeValue(e.target.value)}
                      />
                    ) : formVal.grade_status === "Others" ? (
                      <Input
                        placeholder={t("Please specify")}
                        type="text"
                        value={formVal.grade}
                        onChange={(e) => setGradeValue(e.target.value)}
                      />
                    ) : formVal.grade_status === "No Current Grade" ? (
                      <Input
                        placeholder="No current grade"
                        type="hidden"
                        value="No current grade"
                        onChange={(e) => setGradeValue(e.target.value)}
                      />
                    ) : (
                      <Input
                        placeholder="Please enter"
                        type="number"
                        onKeyPress={(event) => {
                          if (!/[0-9.]/.test(event.key)) {
                            event.preventDefault();
                          }
                          if (event.target.value.length > 3) {
                            event.preventDefault();
                          }
                        }}
                        min={thisCgpaMin}
                        max={thisCgpaMax}
                        step={thisCgpaMin}
                        value={formVal.grade}
                        onChange={(e) => setGradeValue(e.target.value)}
                      />
                    )

                    }
                  </>
                )}
              />
              {errors.grade && <ErrorField err={t(errors.grade.message)} />}
            </FormGroup>
          </Col>
        </Row>
        <Row>
          <Col>
            <FormGroup>
              <LabelText>{t(studyingStaticContent.englishTestTitle)}</LabelText>
              <Controller
                name="english_tests"
                control={control}
                render={({ field }) =>
                  studyingStaticContent.tests.map((test, index) => (
                    <EnglishTestWrapper>
                      <RadioInput
                        key={test + index}
                        {...field}
                        checked={test === formVal.english_tests}
                        onChange={() => radioChange(test, "english_tests")}
                        label={t(test)}
                      />
                    </EnglishTestWrapper>
                  ))
                }
              />
              {errors.english_tests && (
                <ErrorField err={t(errors.english_tests.message)} />
              )}
            </FormGroup>

            <FormGroup>
              <Controller
                name="english_score"
                control={control}
                render={({ field }) => (
                  <>
                    {formVal.english_tests === "CEFR" ||
                      formVal.english_tests === "MUET" ? (
                      <Dropdown
                        options={
                          formVal.english_tests === "CEFR"
                            ? options1
                              ? options1.CEFR
                              : null
                            : options1
                              ? options1.MUET
                              : null
                        }
                        placeholder={t("Select")}
                        onFocus={() => {
                          getOptions(formVal.english_tests);
                        }}
                        val={
                          formVal.english_score
                            ? formVal.english_score
                            : englishScore
                        }
                        label={
                          formVal.english_score
                            ? formVal.english_score
                            : englishScore
                        }
                        change={(val) => dropdownChange("english_score", val)}
                        {...field}
                        refCallback={field?.ref}
                        ref={null}
                      />

                    ) : formVal.english_tests === "IELTS" ? (

                      <Input
                        placeholder={t("Please enter IELTS score")}
                        type="number"
                        onKeyPress={(event) => {
                          if (!/[0-9.]/.test(event.key)) {
                            event.preventDefault();
                          }
                          if (event.target.value.length > 3) {
                            event.preventDefault();
                          }
                        }}
                        min={thisIELTSMin}
                        max={thisIELTSMax}
                        step={thisIELTSMin}
                        value={formVal.english_score}
                        onChange={(e) => setEnglishValue(e.target.value)}
                      />
                    ) : formVal.english_tests === "Others" ? (

                      <Input
                        placeholder={t("Please specify")}
                        type="text"
                        value={formVal.english_score}
                        onChange={(e) => setEnglishValue(e.target.value)}
                      />
                    ) : formVal.english_tests === "I have not taken any tests" ? (
                      <Input
                        placeholder="I have not taken any tests"
                        type="hidden"
                        value="I have not taken any tests"
                        onChange={(e) => setEnglishValue(e.target.value)}
                      />
                    ) : (
                      <Input
                        placeholder={t("Please enter score")}
                        {...field}
                        onKeyPress={(event) => {
                          if (!/[0-9]/.test(event.key)) {
                            event.preventDefault();
                          }
                        }}
                        min={min}
                        max={max}
                        type="number"
                      />
                    )}
                  </>
                )}
              />

              {errors.english_score && (
                <ErrorField err={t(errors.english_score.message)} />
              )}
            </FormGroup>
          </Col>
        </Row>

        {/* grade ends */}
        {formErrors && <ErrorField err={t(formErrors)} />}
        {loading ? (
          <Loader />
        ) : (
          <>
            <ButtonWrapper>
              <Button
                width="198px"
                onClick={handleSubmit(onSubmit, onErrorSubmit)}
                name={t("Continue")}
              />
              <Button
                width="198px"
                onClick={() => onNavigate("/aboutme")}
                secondary={true}
                name={t("Back")}
              />
            </ButtonWrapper>
            {formValues.profile_picture === "" ? (
              ""
            ) : (
              <SaveButton onClick={handleSubmit(onSave)}>
                {t("Save and Exit")}
              </SaveButton>
            )}
          </>
        )}
        {studyFormErrors && (
          <ErrorField
            err={t("Please make sure all the mandatory fields have been inserted")}
          />
        )}
      </FormWrapper>
    </UserCreationLayout>
  );
}

import React, { useEffect, useState, useRef } from "react";
import styled from "styled-components";
import MainLayout from "../../../layouts/mainLayout/mainLayout";
import { Colors } from "../../../utilities/colors";
import * as Mui from "@material-ui/core";
import CvCard from "../../../components/cvcards/cvcard";
import {
  getEducationDetails,
  getEmployeeDetails,
  getEventDetails,
  getInternshipDetails,
  getProfileDetails,
  getResumeData,
  getSummary,
  onBoardFormGetData,
} from "../../../services/apiCalls";
import TimelineItem from "@material-ui/lab/TimelineItem";
import TimelineSeparator from "@material-ui/lab/TimelineSeparator";
import TimelineConnector from "@material-ui/lab/TimelineConnector";
import TimelineContent from "@material-ui/lab/TimelineContent";
import TimelineDot from "@material-ui/lab/TimelineDot";
import { ImageWrapper } from "../../analyticalDashboardPage/commonStyles";
import PreviewTimeline from "./preview-timeline";
import { Col, Row, Dropdown } from "react-bootstrap";
import { toast } from "react-toastify";
import { PDFExport } from "@progress/kendo-react-pdf";
import "./my-cv-preview.css";
import { isValidArray, isValidObject } from "../../../utilities/validators";
import { connect, useDispatch, useSelector } from "react-redux";
import { useReactToPrint } from "react-to-print";
import { setResumeData } from "../../../Redux/Reducers/ProfileReducer/ProfileReducer";
import logo from "../../../assets/icons/svg/mynextLogo.svg";
import moment from "moment";
import { useTranslation } from "react-i18next";

const EducationDegree = styled.div`
  font-family: General Sans;
  font-style: normal;
  font-weight: 500;
  font-size: 16px;
  line-height: 22px;
  color: #d04c8d;
`;

const EducationCYear = styled.div`
  font-family: General Sans;
  font-style: italic;
  font-weight: normal;
  font-size: 16px;
  line-height: 22px;
  /* identical to box height */
  color: #525252;
`;

const StrengthWrapper = styled.div`
  display: flex;
  flex-wrap: wrap;
`;

const Elipsis = styled.div`
  height: 6px;
  width: 6px;
  background-color: #aaaaaa;
  border-radius: 50%;
  display: inline-block;
`;

const DividerLine = styled.div`
  height: 1px;
  background-color: #d9d9d9;
  margin: 8px 0;
`;
const InternDescription = styled.div`
  font-family: "General Sans";
  font-style: normal;
  font-weight: normal !important;
  font-size: 16px;
  line-height: 22px;
  margin-bottom: 3em;
  color: ${Colors.inputTextDark};
  margin-left: 30px;
`;
const useStyles = Mui.makeStyles((theme) => ({
  avatarStyle: {
    width: theme.spacing(20),
    height: theme.spacing(20),
    borderRadius: 100,
  },
}));

const previewDetails = {
  overview: {
    mob_number: "",
    email: "",
    insti_name: "",
    scope_of_study: "",
    city: "",
    curr_country: "",
    state: "",
    display_name: "",
    profile_pic: "",
    full_name: "",
    talent_status: "",
    university: "",
    insti_location_status: "",
    insti_country_status: "",
  },
  summary: "",
  employment: [],
  internship: [],
  educationDetails: [],
  eventDetails: [],
};

const MyCvPreview = (props) => {
  const { isMyCvDownLoad, downloadComplete, handleCvPrint } = props;
  const classes = useStyles();
  const resumePdfTemplateRef = useRef();
  const resumeData = useSelector((state) => state.profile.data.resume);
  const [resumeDetails, setResumeDetails] = useState(previewDetails);
  const dispatch = useDispatch();
  const talentData = useSelector((state) => state?.talent?.userData);
  const { t } = useTranslation();

  const strengths = [
    { name: "Visionary", color: "#8F5DA4" },
    { name: "Problem-solving", color: "#A45D90" },
    { name: "Building systems and organizations", color: "#D9649D" },
    { name: "High Standards and quality", color: "#5F55A5" },
    { name: "Decisive and proactive", color: "#5566A5" },
  ];
  const pdfExportComponent = React.useRef(null);

  useEffect(async () => {
    // Updated 12-07-2022 Code
    await fetchAllDetails().then(async () => {
      await fetchResumeData();
      await downloadFile();
    });

    //Old Code
    // if (
    //   resumeDetails?.overview?.scope_of_study !== "" &&
    //   isMyCvDownLoad &&
    //   count === 0
    // ) {
    //   count = count + 1;  
    // }
  }, [isMyCvDownLoad]);

  const monthDiff = (d1, d2) => {
    d2 = new Date(d2);
    d1 = new Date(d1);
    let months;
    months = (d2.getFullYear() - d1.getFullYear()) * 12;
    months -= d1.getMonth();
    months += d2.getMonth();
    return months <= 0 ? 0 : months;
  };

  const notification = (type, message) => {
    toast[type](message, {
      position: toast.POSITION.TOP_RIGHT,
    });
  };

  const sortData = (list) => {
    const result =
      list.length > 0 &&
      list.sort((a, b) => {
        return b.end_date - a.end_date;
      });
    return result;
  };

  const getData = (obj, section) => {
    if (obj && obj.status === 200 && obj.data.status === 1) {
      if (typeof obj.data.data === "object") {
        if (Object.keys(obj.data.data).length > 0) {
          return obj.data.data;
        }
      } else {
        return obj.data.data;
      }
    } else {
      notification("error", t("Unable to fetch the details"));
      return null;
    }
  };


  async function fetchResumeData() {
    const response = await getResumeData();
    if (
      isValidObject(response) &&
      response.hasOwnProperty("data") &&
      response.data !== undefined &&
      response.hasOwnProperty("status")
    ) {
      dispatch(setResumeData(response.data.data));
    }
  }



  const fetchAllDetails = async () => {
    let details = { ...resumeDetails };
    const [
      overview,
      summary,
      employment,
      internship,
      educationDetails,
      eventDetails,
    ] = await Promise.all([
      getProfileDetails(),
      getSummary(),
      getEmployeeDetails(),
      getInternshipDetails(),
      getEducationDetails(),
      getEventDetails(),
      onBoardFormGetData(),
    ]);
    if (overview) {
      const result = getData(overview, "Overview");

      if (result) {
        const {
          mob_number,
          email,
          scope,
          scope_abbr,
          insti_name,
          insti_name_abbr,
          curr_country,
          curr_country_abbr,
          state,
          state_abbr,
          city,
          city_abbr,
          profile_pic,
          full_name,
          talent_status,
          university,
          insti_country_status,
          insti_location_status,
        } = result;
        details.overview.mob_number = mob_number;
        details.overview.email = email;
        details.overview.scope_of_study = scope_abbr;
        details.overview.insti_name = insti_name_abbr;
        details.overview.curr_country = curr_country_abbr;
        details.overview.state = state_abbr;
        details.overview.city = city_abbr;
        details.overview.profile_pic = profile_pic;
        details.overview.full_name = full_name;
        details.overview.talent_status = talent_status;
        details.overview.university = university;
        details.overview.insti_country_status = insti_country_status;
        details.overview.insti_location_status = insti_location_status;
      }
    }
    if (getData(summary)) {
      const result = getData(summary, "Summary");
      if (result) {
        details.summary = result?.summary;
      }
    }
    if (employment) {
      let result = getData(employment, "Employment");
      if (result) {
        result = sortData(result);
        details.employment = result.map((m) => {
          return {
            role: m.role,
            organization: m.organization,
            location: m.location,
            duration: monthDiff(m.start_date, m.end_date),
          };
        });
      }
    }
    if (internship) {
      let result = getData(internship, "Internship");
      if (result) {
        result = sortData(result);
        details.internship = result.map((m) => {
          return {
            role: m.job_title,
            organization: m.organization,
            location: m.location,
            duration: monthDiff(m.start_date, m.end_date),
          };
        });
      }
    }
    if (educationDetails) {
      let result = getData(educationDetails, "Employment Details");
      if (result) {
        result = sortData(result);
        details.educationDetails = result.map((m) => {
          return {
            degree: m.degree,
            institution: m.institution,
            completion_year: m.completion_year,
            description: m.description,
          };
        });
      }
    }
    if (eventDetails) {
      let result = getData(eventDetails, "Event Details");
      if (result) {
        result = sortData(result);
        details.eventDetails = result.map((m) => {
          return {
            event_title: m.event_title,
            description: m.description,
            duration: monthDiff(m.start_date, m.end_date),
          };
        });
      }
    }
    setResumeDetails({ ...details });
  };

  const handlePrint = useReactToPrint({
    content: () => resumePdfTemplateRef.current,
  });

  useEffect(() => {
    if (resumeDetails?.overview?.scope_of_study && handleCvPrint) {
      handlePrint();
      downloadComplete(false);
    }
  }, [handleCvPrint, resumeDetails?.overview?.scope_of_study]);

  const downloadFile = () => {
    if (pdfExportComponent.current) {
      pdfExportComponent.current.save();
      isMyCvDownLoad && downloadComplete(true);
    }
  };

  const getDuration = (d1, d2) => {
    d2 = new Date(d2);
    d1 = new Date(d1);
    const date1 = moment(d1).format("DD/MM/YYYY");
    const date2 = moment(d2).format("DD/MM/YYYY");
    var startDate = new Date(
      date1.split("/")[2],
      date1.split("/")[1] - 1,
      date1.split("/")[0]
    );
    var endDate = new Date(
      date2.split("/")[2],
      date2.split("/")[1] - 1,
      date2.split("/")[0]
    );

    var diff = new Date(endDate - startDate);
    var diffResult = (diff.getFullYear() - 1970) * 12 + diff.getMonth();
    if (diffResult === 0) {
      return t("1 Month");
    } else {
      return `${diffResult}` + t(" Months");
    }
  };
  const getUniName = (talent_status, insti_country_status, insti_location_status, insti_name, university) => {
    if (talent_status === "studying" || talent_status === "internship") {
      if (insti_location_status === 1) { //study locally
        return insti_name;
      } else { //study abroad
        return university;
      }
    } else if (talent_status === "employed" || talent_status === "unemployed") {
      if (insti_country_status === 1) { //study locally
        return insti_name;
      } else { //study abroad
        return university;
      }
    }
  };
  const PageTemplate = (props) => {
    return (
      <footer
        className="font-color-light-grey font-family-general-sans-medium"
        style={{ fontSize: "14px" }}
      >
        <div style={{ position: 'absolute', bottom: '15px', left: '40px' }}>© mynext. {t("All Rights Reserved")}</div>
        <div style={{ position: 'absolute', bottom: '15px', right: '40px' }}>
          <span>{t("Published")}&nbsp;&nbsp;</span>
          <span>
            {new Date().getDate()}&nbsp;
            {new Date().toLocaleString("default", { month: "short" })}
            &nbsp;
            {new Date().getFullYear()}
          </span>
        </div>
      </footer>
    );
  };

  return (
    <MainLayout>
      <section className="min-height-80-percent font-family-general-sans">
        <div id="cv-loader">
          <div className="loader-container">
            <div className="common-loader">
              <div className="spinner">
                <div className="bounce1"></div>
                <div className="bounce2"></div>
                <div className="bounce3"></div>
              </div>
            </div>
          </div>
        </div>
        <PDFExport
          fileName={`${resumeData?.users?.full_name.replaceAll(/[!-\/:-@[-`{-~]/mg, '_')}-${new Date().toISOString().split("T")[0]
            }`}
          // forcePageBreak='-'
          scale={0.65}
          paperSize="A4"
          margin={"0.6cm"}
          pageNum={5}
          keepTogether={".dont-split"}
          title={`${resumeData?.users?.full_name}-Resume`}
          ref={pdfExportComponent}
          pageTemplate={PageTemplate}
        >
          <div
            ref={
              props.resumeresumePdfTemplateRef
                ? props.resumePdfTemplateRef
                : resumePdfTemplateRef
            }
            id="praveen"
            className="preview-resume-container cursor-pointer bg-white max-width-900px mx-auto"
          >
            {/* <PdfContainer createPdf={createPdf}> */}
            <div className="d-flex justify-content-end cursor-pointer resume-download-floating-button pt-2 me-3">
              <Dropdown className="dropdown__toggle resume-download-floating-button">
                <Dropdown.Toggle variant="">
                  <Elipsis />
                  &nbsp;
                  <Elipsis />
                  &nbsp;
                  <Elipsis />
                </Dropdown.Toggle>
                <Dropdown.Menu className=" font-family-general-sans-medium">
                  <Dropdown.Item
                    onClick={() => {
                      downloadFile();
                    }}
                  >
                    {t("Download")}
                  </Dropdown.Item>
                </Dropdown.Menu>
              </Dropdown>
            </div>
            <div className=" inherit-parent-width d-flex justify-content-end">
              <img
                className="pe-4 mt-2"
                width={"auto"}
                height="40"
                alt=""
                src={logo}
              />
            </div>
            <section className="pt-4 pe-4 ps-4">
              <div className="d-flex justify-content-center">
                <ImageWrapper style={{ marginTop: "50px" }}>
                  <Mui.Avatar
                    src={
                      talentData?.profile_picture
                        ? talentData?.profile_picture
                        : resumeData?.users.profile_pic
                    }
                    alt="Profile pic"
                    className={classes.avatarStyle}
                  />
                </ImageWrapper>
                <div className="m-auto w-75">
                  <div
                    className="preview-resume-title"
                    style={{ lineHeight: "32px", marginLeft: "50px" }}
                  >
                    {props.talent?.userData?.name}
                  </div>
                  <DividerLine />
                  <Row className="pb-2">
                    <Col md="5">
                      <div className="preview-resume-sub-title" style={{ marginLeft: "50px" }}>
                        {t("Scope of Study")}
                      </div>
                    </Col>
                    <Col md="">
                      <div className="preview-resume-text" style={{ marginLeft: "50px" }}>
                        <span className=" font-color-pink me-2">:</span>
                        {resumeDetails?.overview?.scope_of_study}
                      </div>
                    </Col>
                  </Row>
                  <Row className="pb-2">
                    <Col md="5">
                      <div className="preview-resume-sub-title" style={{ marginLeft: "50px" }}>
                        {t("Education Institution")}
                      </div>
                    </Col>
                    <Col>
                      <div className="preview-resume-text" style={{ marginLeft: "50px" }}>
                        <span className=" font-color-pink me-2">:</span>
                        {getUniName(resumeDetails?.overview.talent_status, resumeDetails?.overview?.insti_country_status, resumeDetails?.overview?.insti_location_status, resumeDetails?.overview?.insti_name, resumeDetails?.overview?.university)}
                      </div>
                    </Col>
                  </Row>
                  <Row className="pb-2">
                    <Col md="5">
                      <div className="preview-resume-sub-title" style={{ marginLeft: "50px" }}>{t("Address")}</div>
                    </Col>
                    <Col md="">
                      <div className="preview-resume-text" style={{ marginLeft: "50px" }}>
                        <span className=" font-color-pink me-2">:</span>
                        {resumeDetails?.overview?.city},&nbsp;{" "}
                        {resumeDetails?.overview?.curr_country}-{" "}
                        {props.profile?.data.resume?.users?.postalcode}
                      </div>
                    </Col>
                  </Row>
                  <Row className="pb-2">
                    <Col md="5">
                      <div className="preview-resume-sub-title" style={{ marginLeft: "50px" }}>
                        {t("Primary e-mail id")}
                      </div>
                    </Col>
                    <Col md="">
                      <div className="preview-resume-text" style={{ marginLeft: "50px" }}>
                        <span className=" font-color-pink me-2">:</span>
                        {resumeDetails?.overview?.email}
                      </div>
                    </Col>
                  </Row>
                  <Row className="pb-2">
                    <Col md="5">
                      <div className="preview-resume-sub-title" style={{ marginLeft: "50px" }}>
                        {t("Primary mobile no")}
                      </div>
                    </Col>
                    <Col md="">
                      <div className="preview-resume-text" style={{ marginLeft: "50px" }}>
                        <span className=" font-color-pink me-2">:</span>
                        {resumeDetails?.overview?.mob_number}
                      </div>
                    </Col>
                  </Row>
                </div>
              </div>
            </section>
            {/* Summary section */}
            {isValidObject(resumeData?.summary) && (
              <CvCard
                titleClassName="preview-resume-sub-title dont-split"
                title={t("Summary")}
                showLock={false}
                childClassName="preview-resume-text"
              >
                <InternDescription
                  dangerouslySetInnerHTML={{
                    __html: props.profile.data.resume?.summary?.summary.replace(
                      new RegExp("\n", "g"),
                      "<br>"
                    ),
                  }}
                />
              </CvCard>
            )}

            {/* Employment details section */}
            {isValidArray(resumeData?.employement_details) && (
              <CvCard
                titleClassName="preview-resume-sub-title dont-split"
                title={t("Employment Details")}
                showLock={false}
              >
                <PreviewTimeline data={resumeData?.employement_details} />
              </CvCard>
            )}

            {/* Intership section */}
            {isValidArray(resumeData?.internship_details) && (
              <CvCard
                titleClassName="preview-resume-sub-title"
                title={t("Internship Details")}
                showLock={false}
              >
                <PreviewTimeline data={resumeData?.internship_details} />
              </CvCard>
            )}

            {/* Education details section */}
            {isValidArray(resumeData?.education) && (
              <CvCard
                titleClassName="preview-resume-sub-title"
                title={t("Education Details")}
                showLock={false}
              >
                {resumeData?.education.map((m, i) => {
                  return (
                    <div>
                      <TimelineItem
                        key={i}
                        className="remove-timeline-before-padding"
                      >
                        <TimelineSeparator>
                          <TimelineDot
                            style={{ background: i === 0 ? "#D04C8D" : "" }}
                          />
                          {i < resumeData.length - 1 && <TimelineConnector />}
                        </TimelineSeparator>
                        <TimelineContent
                          className="pt-0 text-capitalize "
                          style={{
                            display: "flex",
                            width: "100%",
                            justifyContent: "space-between",
                          }}
                        >
                          <div className="">
                            <div className=" font-family-general-sans-medium font-color-dark-grey fw-500 pb-1">
                              {m.degree}
                            </div>
                            <div className="font-family-general-sans-medium font-color-pink fw-500 pb-1">
                              {m.institution}
                            </div>
                            <div className="font-family-general-sans-medium font-color-light-grey fw-500">
                              {t("Completion year")} : &nbsp;&nbsp;
                              {m.completion_year}
                            </div>
                            <div className="font-family-general-sans-regular font-color-light-grey">
                              {m.description}
                            </div>
                          </div>
                          {/* <p className='font-family-general-sans-regular font-color-light-grey'>
                              {monthDiff(m.start_date, m.end_date)}&nbsp;&nbsp;
                              {monthDiff(m.start_date, m.end_date) > 1 ? "Months" : "Month"}
                            </p> */}
                        </TimelineContent>
                      </TimelineItem>
                    </div>
                  );
                })}
              </CvCard>
            )}
            {/* Event details section */}
            {isValidArray(resumeData?.event_details) && (
              <CvCard
                titleClassName="preview-resume-sub-title"
                title={t("Events & Projects")}
                showLock={false}
              >
                {resumeData?.event_details.map((m, i) => {
                  return (
                    <>
                      <TimelineItem
                        key={i}
                        className="remove-timeline-before-padding"
                      >
                        <TimelineSeparator>
                          <TimelineDot
                            style={{ background: i === 0 ? "#D04C8D" : "" }}
                          />
                          {i < resumeData.length - 1 && <TimelineConnector />}
                        </TimelineSeparator>
                        <TimelineContent
                          className="pt-0 text-capitalize "
                          style={{
                            display: "flex",
                            width: "100%",
                            justifyContent: "space-between",
                          }}
                        >
                          <div className="w-75">
                            <div className=" font-family-general-sans-medium font-color-dark-grey fw-500 pb-1">
                              {m.event_title}
                            </div>
                            <div className="font-family-general-sans-medium font-color-light-grey fw-500">
                              {m.description}
                            </div>
                          </div>
                          <p className="font-family-general-sans-regular font-color-light-grey">
                            {/* {monthDiff(m.start_date, m.end_date)}&nbsp;&nbsp;
                            {monthDiff(m.start_date, m.end_date) > 1
                              ? "Months"
                              : "Month"} */}
                            {getDuration(m.start_date, m.end_date)}
                          </p>
                        </TimelineContent>
                      </TimelineItem>
                    </>
                  );
                })}
              </CvCard>
            )}

            {/* Skills section */}
            {isValidArray(resumeData?.skills) && (
              <CvCard
                titleClassName="preview-resume-sub-title"
                title={t("Skills")}
                showLock={false}
              >
                <StrengthWrapper>
                  {resumeData?.skills.map((m, i) => {
                    return (
                      <div style={{ textTransfrom: "capitalize" }}>
                        <StrengthChip name={m.title} color={m.color} key={i} />
                      </div>
                    );
                  })}
                </StrengthWrapper>
              </CvCard>
            )}

          </div>
        </PDFExport>
      </section>
    </MainLayout>
  );
};

function mapStateToProps(state) {
  return {
    talent: state.talent,
    profile: state.profile,
  };
}
const mapDispatchToProps = (dispatch) => {
  return {
    // dispatching plain actions
  };
};
export default connect(mapStateToProps, mapDispatchToProps)(MyCvPreview);
// const Foo = <StaticPdf />;

function StrengthChip(props) {
  // styling starts
  const CustomBtn = styled.button`
    width: ${props.width ? props.width : "fit-content"};
    font-family: "general-sans-medium";
    text-transform:"capitalize",
    font-style: normal;
    background: ${(props) => (props.color ? props.color : "#D9649D")};
    border-radius: 128px;
    outline: none;
    border: ${(props) => (props.secondary ? "1px solid #D04C8D" : "0px")};
    font-weight: 500;
    font-size: ${(props) => (props.fontSize ? props.fontSize : "16px")};
    line-height: ${(props) => (props.lineHeight ? props.lineHeight : "22px")};
    color: ${(props) => (props.secondary ? Colors.primaryText : Colors.light)};
    padding: 10px 20px;
    margin: ${(props) => (props.margin ? props.margin : "15px 15px 0 0")};
    min-width: ${(props) => (props.minWidth ? props.minWidth : "fit-content")};
    cursor: pointer;
    @media (max-width: 500px) {
      width: 100%;
    }
  `;
  // styling ends

  return (
    <CustomBtn secondary={props.secondary} margin={props.margin} {...props}>
      {props.name}
    </CustomBtn>
  );
}
